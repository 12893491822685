import { IMaintainer, IMaintainerCreateDTO, IMaintainerEditDTO } from '../store/Maintainers';
import { BaseService } from './base.service';

class MaintainerService extends BaseService {
    private static _angleService: MaintainerService;

    private constructor() {
        super();
    }

    public static get Instance(): MaintainerService {
        return this._angleService || (this._angleService = new this());
    }

    public async getMaintainersAsync(organizationID: number, caseID: number): Promise<IMaintainer[]> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/maintainers`);
        return data as IMaintainer[];
    }

    public async getMaintainerAsync(organizationID: number, caseID: number, id: number): Promise<IMaintainer> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/maintainers/${id}`);
        return data as IMaintainer;
    }

    public async createMaintainerAsync(organizationID: number, caseID: number, angle: IMaintainerCreateDTO): Promise<IMaintainer> {
        const { data } = await this.$http.put(`organizations/${organizationID}/cases/${caseID}/maintainers`, angle);
        return data;
    }

    public async editMaintainerAsync(organizationID: number, caseID: number, id: number, angle: IMaintainerEditDTO): Promise<IMaintainer> {
        const { data } = await this.$http.post(`organizations/${organizationID}/cases/${caseID}/maintainers/${id}`, angle);
        return data;
    }

    public async deleteMaintainerAsync(organizationID: number, caseID: number, id: number): Promise<number> {
        await this.$http.delete(`organizations/${organizationID}/cases/${caseID}/maintainers/${id}`);
        return id;
    }
}

export const MaintainerApi = MaintainerService.Instance;