import { DefaultEffects } from '@fluentui/react';
import { mergeStyleSets } from '@uifabric/styling';
import { INavLinkGroup } from 'office-ui-fabric-react';
import React from 'react';

const classNames = mergeStyleSets({
    container: {
        display: 'flex'
    },
    content: {
        marginLeft: 20,
        flexGrow: 1,
        flexShrink: 1,
        backgroundColor: '#FFF',
        width: 'calc(100vw - 60px)',
        padding: 20,
        boxShadow: DefaultEffects.elevation4
    }
});

type ContentWithoutSidemenuProps = {
    menu?: INavLinkGroup[]
};

class ContentWithoutSidemenu extends React.Component<ContentWithoutSidemenuProps> {
    render() {
        return (
            <div className={classNames.container}>
                <div className={classNames.content}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

// Wire up the React component to the Redux store
export default ContentWithoutSidemenu;