import { Callout } from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import { ColorPicker, DefaultButton } from 'office-ui-fabric-react';
import React, { createRef } from 'react';

type CompactColorPickerProps = {
    value: string;
    onChange: (value: string) => void;
};

type CompactColorPickerState = {
    editorOpen: boolean;
};
  
class CompactColorPicker extends React.Component<CompactColorPickerProps, CompactColorPickerState> {
    private buttonRef = createRef<HTMLDivElement>()
    
    constructor(props: CompactColorPickerProps) {
        super(props);

        this.state = {
            editorOpen: false,
        };
    }

    @boundMethod
    private _togglePickerOpen() {
        this.setState({
            editorOpen: !this.state.editorOpen
        });
    }

    render() {
        return (
            <>
                <div ref={this.buttonRef}>
                    <DefaultButton styles={{root: {backgroundColor: this.props.value}, rootHovered: {backgroundColor: this.props.value}}} onClick={this._togglePickerOpen} />
                </div>
                {this.state.editorOpen && (
                    <Callout
                    gapSpace={0}
                    target={this.buttonRef}
                    onDismiss={this._togglePickerOpen}
                    setInitialFocus>
                    <div>
                        <ColorPicker color={this.props.value} onChange={(e, val) => this.props.onChange(`rgba(${val.r}, ${val.g}, ${val.b}, ${(val.a !== undefined ? (val.a/100) : 1 )})`)} />
                    </div>
                    </Callout>
                )}
            </>
        );
    }
}

export default CompactColorPicker;