import { AppThunkAction } from '.';
import { AngleApi } from '../api';
import { ApiError } from '../types/ApiError';
import { createReducer, IActionType, IBaseAction } from './BaseStore';

export const ActionType: IActionType = {
    NAMESPACE: 'angles',
    REQUEST: 'angles/fetch',
    RECEIVE_FAIL: 'angles/fetchFail',
    RECEIVE_SUCCESS: 'angles/fetchSuccess',
    REQUEST_DETAILS: 'angles/fetchSingle',
    RECEIVE_DETAILS_FAIL: 'angles/fetchSingleFail',
    RECEIVE_DETAILS_SUCCESS: 'angles/fetchSingleSuccess',
    ADD: 'angles/add',
    ADD_FAIL: 'angles/add_fail',
    ADD_SUCCESS: 'angles/add_success',
    EDIT: 'angles/edit',
    EDIT_FAIL: 'angles/edit_fail',
    EDIT_SUCCESS: 'angles/edit_success',
    DELETE: 'angles/delete',
    DELETE_FAIL: 'angles/deleteFail',
    DELETE_SUCCESS: 'angles/deleteSuccess',
    RESET_STATE: 'angles/resetState'
};

export interface IAngle {
    id: number;
    name: string;
    renderingID?: number;
    overlayID?: number;
    northOffset?: number;
    nextAngleAnimationID?: number;
    prevoiusAngleAnimationID?: number;
    disableNextButton?: boolean;
    disablePreviousButton?: boolean;
}

export interface IAngleCreateDTO {
    name: string;
    renderingID?: number;
    overlayID?: number;
    northOffset?: number;
    nextAngleAnimationID?: number;
    prevoiusAngleAnimationID?: number;
    disableNextButton?: boolean;
    disablePreviousButton?: boolean;
}

export interface IAngleEditDTO {
    name: string;
    renderingID?: number;
    overlayID?: number;
    northOffset?: number;
    nextAngleAnimationID?: number;
    prevoiusAngleAnimationID?: number;
    disableNextButton?: boolean;
    disablePreviousButton?: boolean;
}

export const reducer = createReducer<IAngle>(ActionType);

export const actionCreators = {
    requestAngles: (organizationID: number, caseID: number, sectionID: number): AppThunkAction<IBaseAction<IAngle>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST });

        AngleApi.getAnglesAsync(organizationID, caseID, sectionID).then(data => {
            dispatch({ type: ActionType.RECEIVE_SUCCESS, items: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_FAIL, error: reason });
        });
    },

    requestAngle: (organizationID: number, caseID: number, sectionID: number, id: number): AppThunkAction<IBaseAction<IAngle>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST_DETAILS });

        AngleApi.getAngleAsync(organizationID, caseID, sectionID, id).then(data => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_SUCCESS, item: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_FAIL, error: reason });
        });
    },

    addAngle: (organizationID: number, caseID: number, sectionID: number, item: IAngleCreateDTO, callback: (c: IAngle) => void = () => { }): AppThunkAction<IBaseAction<IAngle>> => (dispatch, getState) => {
        dispatch({ type: ActionType.ADD });

        AngleApi.createAngleAsync(organizationID, caseID, sectionID, item).then(data => {
            dispatch({ type: ActionType.ADD_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.ADD_FAIL, error: reason });
        });
    },

    editAngle: (organizationID: number, caseID: number, sectionID: number, angle: IAngle, item: IAngleEditDTO, callback: (c: IAngle) => void = () => { }): AppThunkAction<IBaseAction<IAngle>> => (dispatch, getState) => {
        dispatch({ type: ActionType.EDIT });

        AngleApi.editAngleAsync(organizationID, caseID, sectionID, angle.id, item).then(data => {
            dispatch({ type: ActionType.EDIT_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.EDIT_FAIL, error: reason });
        });
    },

    deleteAngle: (organizationID: number, caseID: number, sectionID: number, angle: IAngle, callback: () => void = () => { }): AppThunkAction<IBaseAction<IAngle>> => (dispatch, getState) => {
        dispatch({ type: ActionType.DELETE });

        AngleApi.deleteAngleAsync(organizationID, caseID, sectionID, angle.id).then(data => {
            dispatch({ type: ActionType.DELETE_SUCCESS, item: angle });
            callback();
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.DELETE_FAIL, error: reason });
        });
    }
};