import { DefaultButton, Panel, PanelType, PrimaryButton, Stack, StackItem, TextField } from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { FieldValidation } from '../../components/controls';
import MediaPicker from '../../components/controls/MediaPicker';
import { ApplicationState } from '../../store';
import { actionCreators, IOverview, IOverviewEditDTO, reducer } from '../../store/Overviews';

type EditOverviewProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onEdited: () => void;
    onDismissed: () => void;
    overview?: IOverview;
    organizationID: number;
    caseID: number;
};

type EditOverviewState = {
    editOverview: IOverviewEditDTO;
};

class EditOverview extends React.Component<EditOverviewProps, EditOverviewState> {

    constructor(props: EditOverviewProps) {
        super(props);

        this.state = {
            editOverview: {
                name: '',
                overlayID: undefined,
                renderingID: undefined
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: EditOverviewProps) {
        if(nextProps.overview === undefined){
            this.setState({
                editOverview: {
                    name: '',
                    overlayID: undefined,
                    renderingID: undefined
                }
            });
        }else{

            if (this.props.overview === undefined || this.props.overview.id !== nextProps.overview.id) {
                this.setState({
                    editOverview: {
                        name: nextProps.overview.name,
                        overlayID: nextProps.overview.overlayID,
                        renderingID: nextProps.overview.renderingID
                    }
                });
            }
        }

    }

    public render(): React.ReactNode {
        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='Edit overview' onRenderFooterContent={() => <div>
                <PrimaryButton disabled={this.props.isEditing} onClick={this._edit} style={{ marginRight: '8px' }} text='Save' />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </div>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                        <TextField label='Name' value={this.state.editOverview.name} onChange={(e, value) => this.setState({ editOverview: { ...this.state.editOverview, name: value || '' } })} />
                        <FieldValidation error={this.props.addError} fieldname='Name' />
                    </StackItem>
        
                    <StackItem>
                        <MediaPicker 
                            label='Overlay' 
                            organizationID={this.props.organizationID} 
                            caseID={this.props.caseID} 
                            value={this.state.editOverview.overlayID ? [this.state.editOverview.overlayID] : []} 
                            onChange={(value) => this.setState({ editOverview: { ...this.state.editOverview, overlayID: value.length === 0 ? undefined : value[0] } })} />
                        <FieldValidation error={this.props.addError} fieldname='OverlayID' />
                    </StackItem>
        
                    <StackItem>
                        <MediaPicker 
                            label='Rendering' 
                            organizationID={this.props.organizationID} 
                            caseID={this.props.caseID} 
                            value={this.state.editOverview.renderingID ? [this.state.editOverview.renderingID] : []} 
                            onChange={(value) => this.setState({ editOverview: { ...this.state.editOverview, renderingID: value.length === 0 ? undefined : value[0] } })} />
                        <FieldValidation error={this.props.addError} fieldname='RenderingID' />
                    </StackItem>
                </Stack>
            </Panel>
        );
    }

    @boundMethod
    private _edit(): void {
        if (this.props.overview) {
            this.props.editOverview(this.props.organizationID, this.props.caseID, this.props.overview, this.state.editOverview, () => { this.props.onEdited(); });
        }
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.overviews, actionCreators)(EditOverview);