import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';

import { FieldValidation } from '../../components/controls';
import MediaPicker from '../../components/controls/MediaPicker';
import { ApplicationState } from '../../store';
import {
  actionCreators,
  ISection,
  ISectionEditDTO,
  reducer,
} from '../../store/Sections';

type EditSectionProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onEdited: () => void;
    onDismissed: () => void;
    section?: ISection;
    organizationID: number;
    caseID: number;
};

type EditSectionState = {
    editSection: ISectionEditDTO;
};

class EditSection extends React.Component<EditSectionProps, EditSectionState> {

    constructor(props: EditSectionProps) {
        super(props);

        this.state = {
            editSection: {
                name: '',
                animationID: undefined
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: EditSectionProps) {
        if(nextProps.section === undefined){
            this.setState({
                editSection: {
                    name: '',
                    animationID: undefined
                }
            });
        }else{

            if (this.props.section === undefined || this.props.section.id !== nextProps.section.id) {
                this.setState({
                    editSection: {
                        name: nextProps.section.name,
                        animationID: nextProps.section.animationID
                    }
                });
            }
        }

    }

    public render(): React.ReactNode {
        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='Edit section' onRenderFooterContent={() => <div>
                <PrimaryButton disabled={this.props.isEditing} onClick={this._edit} style={{ marginRight: '8px' }} text='Save' />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </div>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                        <TextField label='Name' value={this.state.editSection.name} onChange={(e, value) => this.setState({ editSection: { ...this.state.editSection, name: value || '' } })} />
                        <FieldValidation error={this.props.addError} fieldname='Name' />
                    </StackItem>

                    <StackItem>
                      <MediaPicker
                        label='Animation'
                        organizationID={this.props.organizationID}
                        caseID={this.props.caseID}
                        value={this.state.editSection.animationID ? [this.state.editSection.animationID] : []}
                        onChange={(value) => this.setState({ editSection: { ...this.state.editSection, animationID: value.length === 0 ? undefined : value[0] } })} />
                      <FieldValidation error={this.props.addError} fieldname='AnimationID' />
                    </StackItem>
                </Stack>
            </Panel>
        );
    }

    @boundMethod
    private _edit(): void {
        if (this.props.section) {
            this.props.editSection(this.props.organizationID, this.props.caseID, this.props.section, this.state.editSection, () => { this.props.onEdited(); });
        }
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.sections, actionCreators)(EditSection);