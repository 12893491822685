import { ICase, ICaseCreateDTO, ICaseEditDTO } from '../store/Cases';
import { BaseService } from './base.service';

class CasesService extends BaseService {
    private static _angleService: CasesService;

    private constructor() {
        super();
    }

    public static get Instance(): CasesService {
        return this._angleService || (this._angleService = new this());
    }

    public async getCasesAsync(organizationID: number): Promise<ICase[]> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases`);
        return data as ICase[];
    }

    public async getCaseAsync(organizationID: number, id: number): Promise<ICase> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${id}`);
        return data as ICase;
    }

    public async createCaseAsync(organizationID: number, angle: ICaseCreateDTO): Promise<ICase> {
        const { data } = await this.$http.put(`organizations/${organizationID}/cases`, angle);
        return data;
    }

    public async editCaseAsync(organizationID: number, id: number, angle: ICaseEditDTO): Promise<ICase> {
        const { data } = await this.$http.post(`organizations/${organizationID}/cases/${id}`, angle);
        return data;
    }

    public async deleteCaseAsync(organizationID: number, id: number): Promise<number> {
        await this.$http.delete(`organizations/${organizationID}/cases/${id}`);
        return id;
    }
}

export const CaseApi = CasesService.Instance;