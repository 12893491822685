import { boundMethod } from 'autobind-decorator';
import { CommandBar, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack, StackItem } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { DataTable, ErrorMessage } from '../../components/controls';
import { ApplicationState } from '../../store';
import * as CasesStore from '../../store/Cases';
import * as Auth from '../../store/Auth';
import EditCase from './EditCase';
import NewCase from './NewCase';

type CasesProps = {
    cases: ReturnType<typeof CasesStore.reducer>;
    auth: ReturnType<typeof Auth.reducer>;
 } & typeof CasesStore.actionCreators & RouteComponentProps<{ organizationID: string }>;

type CasesState = {
    showCreatePanel: boolean;
    selectedCase?: CasesStore.ICase;
    deleteDialogVisible: boolean;
    editDialogVisible: boolean;
};

class Cases extends React.Component<CasesProps, CasesState> {

    constructor(props: CasesProps) {
        super(props);

        this.state = {
            showCreatePanel: false,
            selectedCase: undefined,
            deleteDialogVisible: false,
            editDialogVisible: false
        };
    }

    public componentDidMount(): void {
        this.props.requestCases(parseInt(this.props.match.params.organizationID));
    }

    public render(): React.ReactNode {
        return (
            <>
                <NewCase organizationID={parseInt(this.props.match.params.organizationID)} isOpen={this.state.showCreatePanel} onDismissed={this._hideCreatePanel} onAdded={this._hideCreatePanel} />
                <EditCase organizationID={parseInt(this.props.match.params.organizationID)} case={this.state.selectedCase} isOpen={this.state.editDialogVisible} onDismissed={this._hideEditPanel} onEdited={this._hideEditPanel} />

                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                        <CommandBar
                            items={[
                                {
                                    key: 'Add',
                                    name: 'Create new case',
                                    iconProps: {
                                        iconName: 'Add'
                                    },
                                    onClick: () => this._showCreatePanel()
                                },
                            ]}
                        />

                        
                    </StackItem>

                    <StackItem>
                        <ErrorMessage error={this.props.cases.loadError} />

                        <DataTable<CasesStore.ICase> paginated items={this.props.cases.items} isLoading={this.props.cases.isLoading} onEdit={this._showEditPanel} onDelete={this._promptForDelete} coloumns={[
                            {
                                key: 'name',
                                name: 'Name',
                                fieldName: 'Name',
                                minWidth: 210,
                                maxWidth: 1000,
                                isResizable: true,
                                isSortable: true,
                                onRender: (item: CasesStore.ICase) => {
                                    if(this.props.auth.usertype === 'Maintainer'){
                                        return <NavLink to={`/organizations/${this.props.match.params.organizationID}/cases/${item.id}/units`}>{item.name}</NavLink>;
                                    }
                                    return <NavLink to={`/organizations/${this.props.match.params.organizationID}/cases/${item.id}`}>{item.name}</NavLink>;
                                }
                            }
                        ]} />
                    </StackItem>

                </Stack>

                <Dialog
                    hidden={!this.state.deleteDialogVisible}
                    onDismiss={this._hideDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete case',
                        subText: 'This will remove the case permantly'
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <ErrorMessage error={this.props.cases.deleteError} />
                    <DialogFooter>
                        <PrimaryButton onClick={this._delete} text='Delete' />
                        <DefaultButton onClick={this._hideDialog} text='Cancel' />
                    </DialogFooter>
                </Dialog>
            </>
        );
    }

    @boundMethod
    private _delete(): void {
        if(this.state.selectedCase){
            this.props.deleteCase(parseInt(this.props.match.params.organizationID), this.state.selectedCase, () => { this._hideDialog(); });
        }
    }

    @boundMethod
    private _promptForDelete(_case: CasesStore.ICase) {
        this.setState({
            selectedCase: _case,
            deleteDialogVisible: true
        });
    }

    @boundMethod
    private _hideDialog() {
        this.setState({
            deleteDialogVisible: false
        });
    }

    @boundMethod
    private _showCreatePanel() {
        this.setState({ showCreatePanel: true });
    }

    @boundMethod
    private _hideCreatePanel() {
        this.setState({
            showCreatePanel: false,
        });
    }

    @boundMethod
    private _showEditPanel(_case: CasesStore.ICase) {
        this.setState({
            editDialogVisible: true,
            selectedCase: _case
        });
    }

    @boundMethod
    private _hideEditPanel() {
        this.setState({
            editDialogVisible: false,
            selectedCase: undefined
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state, CasesStore.actionCreators)(Cases);