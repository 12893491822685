import { DefaultEffects } from '@fluentui/react';
import { CommandBarButton } from 'office-ui-fabric-react/lib/Button';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import { actionCreators, reducer } from '../store/Auth';
import logo from '../assets/VR-Co-logo-Blaa.svg';

type NavBarProps = ReturnType<typeof reducer> & typeof actionCreators;

const classNames = mergeStyleSets({
    header: {
        backgroundColor: '#ffffff',
        marginBottom: 10,
        verticalAlign: 'middle',
        display: 'flex',
        alignItems: 'center',
        padding: '5px 10px',
        boxShadow: DefaultEffects.elevation8
    },
    logo: {
        flexGrow: 0,
        paddingTop: '0.2em'
    },
    menu: {
        flexGrow: 2
    },
    account: {
        flexGrow: 0
    },
    link: {
        margin: 16,
        color: '#000'
    }
});

class NavBar extends React.Component<NavBarProps> {
    render() {

        return (
            <div className={classNames.header}>
                <div className={classNames.logo}>
                    <img src={logo} height={35} alt='logo' />
                </div>

                <div className={classNames.menu}>

                    
                    {this.props.usertype === 'SuperAdministrator' ? 
                    <>
                        <Link to={'/organizations'} className={classNames.link}>
                            Organizations
                        </Link>
                        <Link to={'/users'} className={classNames.link}>
                            Administrators
                        </Link>
                        <Link to={'/maintainers'} className={classNames.link}>
                            Maintainers
                        </Link>
                        <a href='/home/exceptions' rel='noopener noreferrer' target='_blank' className={classNames.link}>
                            Error log
                        </a>
                        <a href={`${process.env.REACT_APP_API_HOST}/docs`} target='_blank' rel='noopener noreferrer' className={classNames.link}>
                            Api docs
                        </a>
                    </> : 
                    (this.props.organizationID ? 
                        <Link to={`/maintainer`} className={classNames.link}>Cases</Link> :
                        <Link to={`/maintainer`} className={classNames.link}>Cases</Link>
                        
                    )}
                </div>

                <div className={classNames.account}>
                    <CommandBarButton
                        text={this.props.username}
                        split={true}
                        style={{ height: '35px' }}
                        menuProps={{
                            items: [
                                {
                                    key: 'logout',
                                    text: 'Log out',
                                    iconProps: { iconName: 'SignOut' },
                                    onClick: () => { this.props.logoutUserRequest(); }
                                }
                            ]
                        }}
                    />
                </div>
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.auth, // Selects which state properties are merged into the component's props
    actionCreators // Selects which action creators are merged into the component's props
)(NavBar as any);