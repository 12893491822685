import { boundMethod } from 'autobind-decorator';
import { DefaultButton, Panel, PanelType, PrimaryButton, Stack, StackItem, TextField } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { ErrorMessage, FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, IOrganizationCreateDTO, reducer } from '../../store/Organizations';

type NewOrganizationProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onAdded: () => void;
    onDismissed: () => void;
};

type NewOrganizationState = {
    newOrganization: IOrganizationCreateDTO;
};

class NewOrganization extends React.Component<NewOrganizationProps, NewOrganizationState> {

    constructor(props: NewOrganizationProps) {
        super(props);

        this.state = {
            newOrganization: {
                name: ''
            }
        };
    }

    public render(): React.ReactNode {
        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='New organization' onRenderFooterContent={() => <StackItem>
                <PrimaryButton disabled={this.props.isAdding} onClick={this._addOrganization} text='Create' style={{ marginRight: '8px' }} />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </StackItem>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <ErrorMessage error={this.props.addError} />

                    <StackItem>
                        <TextField label='Name' value={this.state.newOrganization.name} onChange={(e, value) => this.setState({ newOrganization: { ...this.state.newOrganization, name: value || '' } })} />
                        <FieldValidation error={this.props.addError} fieldname='Name' />
                    </StackItem>
                </Stack>
            </Panel>
        );
    }

    @boundMethod
    private _addOrganization(): void {
        this.props.addOrganization(this.state.newOrganization, () => { this.props.onAdded(); });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.organizations, actionCreators)(NewOrganization);