import { AppThunkAction } from '.';
import { OrganizationApi } from '../api';
import { ApiError } from '../types/ApiError';
import { createReducer, IActionType, IBaseAction } from './BaseStore';

export const ActionType: IActionType = {
    NAMESPACE: 'organizations',
    REQUEST: 'organizations/fetch',
    RECEIVE_FAIL: 'organizations/fetchFail',
    RECEIVE_SUCCESS: 'organizations/fetchSuccess',
    REQUEST_DETAILS: 'organizations/fetchSingle',
    RECEIVE_DETAILS_FAIL: 'organizations/fetchSingleFail',
    RECEIVE_DETAILS_SUCCESS: 'organizations/fetchSingleSuccess',
    ADD: 'organizations/add',
    ADD_FAIL: 'organizations/add_fail',
    ADD_SUCCESS: 'organizations/add_success',
    EDIT: 'organizations/edit',
    EDIT_FAIL: 'organizations/edit_fail',
    EDIT_SUCCESS: 'organizations/edit_success',
    DELETE: 'organizations/delete',
    DELETE_FAIL: 'organizations/deleteFail',
    DELETE_SUCCESS: 'organizations/deleteSuccess',
    RESET_STATE: 'organizations/resetState'
};

export interface IOrganization {
    id: number;
    name: string;
}

export interface IOrganizationCreateDTO {
    name: string;
}

export interface IOrganizationEditDTO {
    name: string;
}

export const reducer = createReducer<IOrganization>(ActionType);

export const actionCreators = {
    requestOrganizations: (): AppThunkAction<IBaseAction<IOrganization>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST });

        OrganizationApi.getOrganizationsAsync().then(data => {
            dispatch({ type: ActionType.RECEIVE_SUCCESS, items: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_FAIL, error: reason });
        });
    },

    requestOrganization: (id: number): AppThunkAction<IBaseAction<IOrganization>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST_DETAILS });

        OrganizationApi.getOrganizationAsync(id).then(data => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_SUCCESS, item: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_FAIL, error: reason });
        });
    },

    addOrganization: (item: IOrganizationCreateDTO, callback: (c: IOrganization) => void = () => { }): AppThunkAction<IBaseAction<IOrganization>> => (dispatch, getState) => {
        dispatch({ type: ActionType.ADD });

        OrganizationApi.createOrganizationAsync(item).then(data => {
            dispatch({ type: ActionType.ADD_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.ADD_FAIL, error: reason });
        });
    },

    editOrganization: (organization: IOrganization, item: IOrganizationEditDTO, callback: (c: IOrganization) => void = () => { }): AppThunkAction<IBaseAction<IOrganization>> => (dispatch, getState) => {
        dispatch({ type: ActionType.EDIT });

        OrganizationApi.editOrganizationAsync(organization.id, item).then(data => {
            dispatch({ type: ActionType.EDIT_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.EDIT_FAIL, error: reason });
        });
    },

    deleteOrganization: (organization: IOrganization, callback: () => void = () => { }): AppThunkAction<IBaseAction<IOrganization>> => (dispatch, getState) => {
        dispatch({ type: ActionType.DELETE });

        OrganizationApi.deleteOrganizationAsync(organization.id).then(data => {
            dispatch({ type: ActionType.DELETE_SUCCESS, item: organization });
            callback();
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.DELETE_FAIL, error: reason });
        });
    }
};