import { boundMethod } from 'autobind-decorator';
import { CommandBar, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack, StackItem } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ContentWithoutSideMenu from '../../components/ContentWithoutSideMenu';
import { DataTable, ErrorMessage } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, IOrganization, reducer } from '../../store/Organizations';
import EditOrganization from './EditOrganization';
import NewOrganization from './NewOrganization';

type OrganizationsProps = ReturnType<typeof reducer> & typeof actionCreators;

type OrganizationsState = {
    showCreatePanel: boolean;
    selectedOrganization?: IOrganization;
    deleteDialogVisible: boolean;
    editDialogVisible: boolean;
};

class Organizations extends React.Component<OrganizationsProps, OrganizationsState> {

    constructor(props: OrganizationsProps) {
        super(props);

        this.state = {
            showCreatePanel: false,
            selectedOrganization: undefined,
            deleteDialogVisible: false,
            editDialogVisible: false
        };
    }

    public componentDidMount(): void {
        this.props.requestOrganizations();
    }

    public render(): React.ReactNode {
        return (
            <ContentWithoutSideMenu>
                <NewOrganization isOpen={this.state.showCreatePanel} onDismissed={this._hideCreatePanel} onAdded={this._hideCreatePanel} />
                <EditOrganization organization={this.state.selectedOrganization} isOpen={this.state.editDialogVisible} onDismissed={this._hideEditPanel} onEdited={this._hideEditPanel} />

                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                        <CommandBar
                            items={[
                                {
                                    key: 'Add',
                                    name: 'Create new organization',
                                    iconProps: {
                                        iconName: 'Add'
                                    },
                                    onClick: () => this._showCreatePanel()
                                },
                            ]}
                        />

                        
                    </StackItem>

                    <StackItem>
                        <ErrorMessage error={this.props.loadError} />

                        <DataTable<IOrganization> paginated items={this.props.items} isLoading={this.props.isLoading} onEdit={this._showEditPanel} onDelete={this._promptForDelete} coloumns={[
                            {
                                key: 'name',
                                name: 'Name',
                                fieldName: 'Name',
                                minWidth: 210,
                                maxWidth: 1000,
                                isResizable: true,
                                isSortable: true,
                                onRender: (item: IOrganization) => {
                                    return <NavLink to={`/organizations/${item.id}`}>{item.name}</NavLink>;
                                }
                            }
                        ]} />
                    </StackItem>

                </Stack>

                <Dialog
                    hidden={!this.state.deleteDialogVisible}
                    onDismiss={this._hideDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete organization',
                        subText: 'This will remove the organization permantly'
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <ErrorMessage error={this.props.deleteError} />
                    <DialogFooter>
                        <PrimaryButton onClick={this._delete} text='Delete' />
                        <DefaultButton onClick={this._hideDialog} text='Cancel' />
                    </DialogFooter>
                </Dialog>
            </ContentWithoutSideMenu>
        );
    }

    @boundMethod
    private _delete(): void {
        if(this.state.selectedOrganization){
            this.props.deleteOrganization(this.state.selectedOrganization, () => { this._hideDialog(); });
        }
    }

    @boundMethod
    private _promptForDelete(organization: IOrganization) {
        this.setState({
            selectedOrganization: organization,
            deleteDialogVisible: true
        });
    }

    @boundMethod
    private _hideDialog() {
        this.setState({
            deleteDialogVisible: false
        });
    }

    @boundMethod
    private _showCreatePanel() {
        this.setState({ showCreatePanel: true });
    }

    @boundMethod
    private _hideCreatePanel() {
        this.setState({
            showCreatePanel: false,
        });
    }

    @boundMethod
    private _showEditPanel(organization: IOrganization) {
        this.setState({
            editDialogVisible: true,
            selectedOrganization: organization
        });
    }

    @boundMethod
    private _hideEditPanel() {
        this.setState({
            editDialogVisible: false,
            selectedOrganization: undefined
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.organizations, actionCreators)(Organizations);