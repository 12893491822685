import { ActionButton, Stack, StackItem } from 'office-ui-fabric-react';
import React from 'react';

type PaginationProps = {
    currentPage: number;
    totalPages: number;
    onChange: (page: number) => void;
};

type PaginationState = {

};

export class Pagination extends React.Component<PaginationProps, PaginationState> {
    render() {

        let startPage = Math.max(1, this.props.currentPage - 5);
        let endPage = Math.min(this.props.totalPages, this.props.currentPage + 5);

        let overflowLeft = startPage > 1;
        let overflowRight = endPage < this.props.totalPages;

        let buttons: JSX.Element[] = [];

        for (var i = startPage; i <= endPage; i++) {
            let page = i;
            buttons.push(<ActionButton key={page} styles={{ label: { fontWeight: (page === this.props.currentPage ? 800 : 200) } }} text={page.toString()} onClick={() => this.props.onChange(page)} />);
        }

        return (
            <Stack horizontal horizontalAlign='center'>
                <StackItem>
                    <ActionButton disabled={this.props.currentPage <= 1} iconProps={{ iconName: 'DoubleChevronLeft' }} onClick={() => this.props.onChange(1)} />
                    <ActionButton disabled={this.props.currentPage <= 1} iconProps={{ iconName: 'ChevronLeft' }} onClick={() => this.props.onChange(this.props.currentPage - 1)} />
                </StackItem>

                <StackItem grow>
                    <Stack horizontal horizontalAlign='center'>
                        {overflowLeft ? <ActionButton iconProps={{ iconName: 'More' }} /> : null}
                        {buttons}
                        {overflowRight ? <ActionButton iconProps={{ iconName: 'More' }} /> : null}
                    </Stack>
                </StackItem>

                <StackItem>
                    <ActionButton disabled={this.props.currentPage === this.props.totalPages} iconProps={{ iconName: 'ChevronRight' }} onClick={() => this.props.onChange(this.props.currentPage + 1)} />
                    <ActionButton disabled={this.props.currentPage === this.props.totalPages} iconProps={{ iconName: 'DoubleChevronRight' }} onClick={() => this.props.onChange(this.props.totalPages)} />
                </StackItem>
            </Stack>
        );
    }
}