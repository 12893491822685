import { DefaultEffects } from '@fluentui/react';
import { mergeStyleSets } from '@uifabric/styling';
import { INavLinkGroup, Nav } from 'office-ui-fabric-react';
import React from 'react';
import { NavLink } from 'react-router-dom';

const classNames = mergeStyleSets({
    container: {
        display: 'flex'
    },
    navbarTrack: {
        width: 300,
        flexGrow: 0,
        flexShrink: 0
    },
    navbar: {
        position: 'sticky',
        top: 0
    },
    content: {
        marginLeft: 20,
        flexGrow: 1,
        flexShrink: 1,
        backgroundColor: '#FFF',
        width: 'calc(100vw - 360px)',
        padding: 20,
        boxShadow: DefaultEffects.elevation4
    }
});

type ContentWithSidemenuProps = {
    menu?: INavLinkGroup[]
};

class ContentWithSidemenu extends React.Component<ContentWithSidemenuProps> {
    render() {
        return (
            <div className={classNames.container}>
                <div className={classNames.navbarTrack}>
                    <div className={classNames.navbar}>
                        {this.props.menu ?
                            <Nav
                                styles={{ compositeLink: { backgroundColor: 'none', padding: '8px 8px 8px 40px', selectors: { '&.is-selected': { borderLeft: '1px solid #0078d4', backgroundColor: 'rgba(243, 242, 241)' } } } }}
                                linkAs={(e) => { return <NavLink to={e.href!}>{e.children}</NavLink>; }}
                                expandButtonAriaLabel='Expand or collapse'
                                groups={this.props.menu}
                            /> : null}
                    </div>
                </div>
                <div className={classNames.content}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

// Wire up the React component to the Redux store
export default ContentWithSidemenu;