import { ISection, ISectionCreateDTO, ISectionEditDTO } from '../store/Sections';
import { BaseService } from './base.service';

class SectionService extends BaseService {
    private static _angleService: SectionService;

    private constructor() {
        super();
    }

    public static get Instance(): SectionService {
        return this._angleService || (this._angleService = new this());
    }

    public async getSectionsAsync(organizationID: number, caseID: number): Promise<ISection[]> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/sections`);
        return data as ISection[];
    }

    public async getSectionAsync(organizationID: number, caseID: number, id: number): Promise<ISection> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/sections/${id}`);
        return data as ISection;
    }

    public async createSectionAsync(organizationID: number, caseID: number, angle: ISectionCreateDTO): Promise<ISection> {
        const { data } = await this.$http.put(`organizations/${organizationID}/cases/${caseID}/sections`, angle);
        return data;
    }

    public async editSectionAsync(organizationID: number, caseID: number, id: number, angle: ISectionEditDTO): Promise<ISection> {
        const { data } = await this.$http.post(`organizations/${organizationID}/cases/${caseID}/sections/${id}`, angle);
        return data;
    }

    public async deleteSectionAsync(organizationID: number, caseID: number, id: number): Promise<number> {
        await this.$http.delete(`organizations/${organizationID}/cases/${caseID}/sections/${id}`);
        return id;
    }
}

export const SectionApi = SectionService.Instance;