import { IDropdownOption } from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import {
  DefaultButton,
  Dropdown,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';

import { ErrorMessage, FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, IFieldCreateDTO, reducer } from '../../store/Fields';

type NewFieldProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onAdded: () => void;
    onDismissed: () => void;
    organizationID: number;
    caseID: number;
};

type NewFieldState = {
    newField: IFieldCreateDTO;
};

class NewField extends React.Component<NewFieldProps, NewFieldState> {

    constructor(props: NewFieldProps) {
        super(props);

        this.state = {
            newField: {
                name: '',
                type: 'text',
                justification: 'right'
            }
        };
    }

    public render(): React.ReactNode {
        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='New field' onRenderFooterContent={() => <StackItem>
                <PrimaryButton disabled={this.props.isAdding} onClick={this._add} text='Create' style={{ marginRight: '8px' }} />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </StackItem>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <ErrorMessage error={this.props.addError} />

                    <StackItem>
                        <TextField label='Name' value={this.state.newField.name} onChange={(e, value) => this.setState({ newField: { ...this.state.newField, name: value || '' } })} />
                        <FieldValidation error={this.props.addError} fieldname='Name' />
                    </StackItem>
                    
                    <StackItem>
                        <Dropdown 
                            label='Type' 
                            selectedKey={this.state.newField.type} 
                            onChange={this._onTypeChange}
                            options={[
                                { key: 'text', text: 'Text' },
                                { key: 'number', text: 'Number' },
                                { key: 'area', text: 'Area' },
                                { key: 'net_area', text: 'Net Area' },
                                { key: 'currency', text: 'Currency' },
                                { key: 'rent', text: 'Rent' },
                                { key: 'media', text: 'Media' }
                            ]}/>
                        <FieldValidation error={this.props.addError} fieldname='Type' />
                    </StackItem>

                    <StackItem>
                        <Dropdown
                            label='Justification'
                            selectedKey={this.state.newField.justification}
                            onChange={this._onJustificationChange}
                            options={[
                                { key: 'right', text: 'Right' },
                                { key: 'left', text: 'Left' },
                                { key: 'center', text: 'Center' }
                            ]} />
                        <FieldValidation error={this.props.addError} fieldname='Justification' />
                    </StackItem>
                </Stack>
            </Panel>
        );
    }

    @boundMethod
    private _onTypeChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined): void {
        if(option){
            this.setState({ newField: { ...this.state.newField, type: option.key.toString() as 'text' | 'number' | 'area' | 'net_area' | 'currency' | 'rent' | 'media' } })
        }
    }

    @boundMethod
    private _onJustificationChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined): void {
        if (option) {
            this.setState({ newField: { ...this.state.newField, justification: option.key.toString() as 'left' | 'right' | 'center' } })
        }
    }

    @boundMethod
    private _add(): void {
        this.props.addField(this.props.organizationID, this.props.caseID, this.state.newField, () => { this.props.onAdded(); });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.fields, actionCreators)(NewField);