import React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { ApiError } from '../../types/ApiError';

type FieldValidationProps = {
    error?: ApiError;
    fieldname?: string;
};

const FieldValidation: React.FC<FieldValidationProps> = (props) => {
    let messages = props.error ? props.error.validationErrors : undefined;

    if (messages === undefined) {
        return null;
    }

    let fieldMessages: string[] = [];
    if (props.fieldname) {
        fieldMessages = messages[props.fieldname] || [];
    } else {
        fieldMessages = Object.keys(messages).reduce((r, e) => r = r.concat(messages ? messages[e] : []), [] as string[]);
    }

    fieldMessages.length = Math.min(fieldMessages.length, 10);

    return (
        fieldMessages.length > 0 ? <MessageBar messageBarType={MessageBarType.error}>{fieldMessages.map(e => <span key={e}>{e}<br /></span>)}</MessageBar> : null
    );
};

export default FieldValidation;