import React from 'react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { ApiError } from '../../types/ApiError';

type ErrorMessageProps = {
    error?: ApiError;
};

const ErrorMessage: React.FC<ErrorMessageProps> = (props) => {
    return (
        props.error && props.error.errors ? <MessageBar messageBarType={MessageBarType.error}>{props.error.errors.map(e => <span key={e}>{e}<br /></span>)}</MessageBar> : null
    );
};

export default ErrorMessage;