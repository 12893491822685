import { boundMethod } from 'autobind-decorator';
import { CommandBar, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack, StackItem } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { DataTable, ErrorMessage } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, IMaintainer, reducer } from '../../store/Maintainers';
import NewMaintainer from './NewMaintainer';
import EditMaintainer from './EditMaintainer';

type FieldsProps = ReturnType<typeof reducer> & typeof actionCreators & RouteComponentProps<{ organizationID: string; caseID: string }>;

type FieldsState = {
    showCreatePanel: boolean;
    selectedField?: IMaintainer;
    deleteDialogVisible: boolean;
    editDialogVisible: boolean;
};

class Maintainers extends React.Component<FieldsProps, FieldsState> {

    constructor(props: FieldsProps) {
        super(props);

        this.state = {
            showCreatePanel: false,
            selectedField: undefined,
            deleteDialogVisible: false,
            editDialogVisible: false
        };
    }

    public componentDidMount(): void {
        this.props.requestMaintainers(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID));
    }

    public render(): React.ReactNode {
        return (
            <>
                <NewMaintainer organizationID={parseInt(this.props.match.params.organizationID)} caseID={parseInt(this.props.match.params.caseID)} isOpen={this.state.showCreatePanel} onDismissed={this._hideCreatePanel} onAdded={this._hideCreatePanel} />
                <EditMaintainer organizationID={parseInt(this.props.match.params.organizationID)} caseID={parseInt(this.props.match.params.caseID)} maintainer={this.state.selectedField} isOpen={this.state.editDialogVisible} onDismissed={this._hideEditPanel} onEdited={this._hideEditPanel} />

                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                        <CommandBar
                            items={[
                                {
                                    key: 'Add',
                                    name: 'Add maintainer',
                                    iconProps: {
                                        iconName: 'Add'
                                    },
                                    onClick: () => this._showCreatePanel()
                                },
                            ]}
                        />

                        
                    </StackItem>

                    <StackItem>
                        <ErrorMessage error={this.props.loadError} />

                        <DataTable<IMaintainer> paginated items={this.props.items} isLoading={this.props.isLoading} onEdit={this._showEditPanel} onDelete={this._promptForDelete} coloumns={[
                            {
                                key: 'email',
                                name: 'Email',
                                fieldName: 'email',
                                minWidth: 210,
                                maxWidth: 1000,
                                isResizable: true,
                                isSortable: true,
                            },
                            {
                                key: 'access',
                                name: 'Access',
                                fieldName: 'access',
                                minWidth: 210,
                                maxWidth: 1000,
                                isResizable: true,
                                isSortable: true,
                            }
                        ]} />
                    </StackItem>

                </Stack>

                <Dialog
                    hidden={!this.state.deleteDialogVisible}
                    onDismiss={this._hideDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete maintainer',
                        subText: 'This will remove the maintainer permantly'
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <ErrorMessage error={this.props.deleteError} />
                    <DialogFooter>
                        <PrimaryButton onClick={this._delete} text='Delete' />
                        <DefaultButton onClick={this._hideDialog} text='Cancel' />
                    </DialogFooter>
                </Dialog>
            </>
        );
    }

    @boundMethod
    private _delete(): void {
        if(this.state.selectedField){
            this.props.deleteMaintainer(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID), this.state.selectedField, () => { this._hideDialog(); });
        }
    }

    @boundMethod
    private _promptForDelete(field: IMaintainer) {
        this.setState({
            selectedField: field,
            deleteDialogVisible: true
        });
    }

    @boundMethod
    private _hideDialog() {
        this.setState({
            deleteDialogVisible: false
        });
    }

    @boundMethod
    private _showCreatePanel() {
        this.setState({ showCreatePanel: true });
    }

    @boundMethod
    private _hideCreatePanel() {
        this.setState({
            showCreatePanel: false,
        });
    }

    @boundMethod
    private _showEditPanel(field: IMaintainer) {
        this.setState({
            editDialogVisible: true,
            selectedField: field
        });
    }

    @boundMethod
    private _hideEditPanel() {
        this.setState({
            editDialogVisible: false,
            selectedField: undefined
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.maintainers, actionCreators)(Maintainers);