//import { Fabric } from '@fluentui/react';
import { initializeIcons } from '@uifabric/icons';
import * as React from 'react';
import { Route } from 'react-router';
import { PrivateRoute } from './components/controls';
import Home from './components/Home';
import Layout from './components/Layout';
import './site.css';
import Login from './Views/Account/Login';
import ResetPassword from './Views/Account/ResetPassword';
import Organizations from './Views/Organizations/Organizations';
import Organization from './Views/Organizations/Organization';
import Users from './Views/Users/Users';
import Maintainers from './Views/Users/Maintainers';
import Maintainer from './Views/Maintainer';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { loadTheme, createTheme, ITheme } from '@fluentui/react';

initializeIcons();


const appTheme: ITheme = createTheme({
  palette: {
    themePrimary: '#00AAE7',
    
  }
});

loadTheme(appTheme);

export default () => (
        <Fabric>
            <Layout>
                <Route path={'/account/login'} component={Login} />
                <Route path={'/account/resetpassword'} component={ResetPassword} />


                <PrivateRoute exact path='/' component={Home} />
                <PrivateRoute path='/users' component={Users} />
                <PrivateRoute path='/maintainers' component={Maintainers} />
                <PrivateRoute path='/maintainer' component={Maintainer} />
                <PrivateRoute exact path='/organizations' component={Organizations} />
                <PrivateRoute path='/organizations/:organizationID' component={Organization} />
            </Layout>
        </Fabric>
);
