import { IMediaFile } from '../store/MediaFiles';
import { BaseService } from './base.service';

class MediaFileService extends BaseService {
    private static _service: MediaFileService;

    private constructor() {
        super();
    }

    public static get Instance(): MediaFileService {
        return this._service || (this._service = new this());
    }

    public async getMediaFilesAsync(organizationID: number, caseID: number): Promise<IMediaFile[]> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/media`);
        return data as IMediaFile[];
    }

    public async getMediaFileAsync(organizationID: number, caseID: number, id: number): Promise<IMediaFile> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/media/${id}`);
        return data as IMediaFile;
    }

    public async createMediaFileAsync(organizationID: number, caseID: number, file: File, progressCallback: (progress: number) => void = () => { }): Promise<IMediaFile> {

        const formData = new FormData();
        formData.append('file', file);

        const { data } = await this.$http.put(`organizations/${organizationID}/cases/${caseID}/media`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function (progressEvent: any) {
                const progress = progressEvent.loaded / progressEvent.total;
                progressCallback(progress);
            }
        });

        return data as IMediaFile;
    }

    public async updateMediaFileAsync(organizationID: number, caseID: number, mediafile: IMediaFile, file: File, progressCallback: (progress: number) => void = () => { }): Promise<IMediaFile> {

        const formData = new FormData();
        formData.append('file', file);

        const { data } = await this.$http.post(`organizations/${organizationID}/cases/${caseID}/media/${mediafile.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function (progressEvent: any) {
                const progress = progressEvent.loaded / progressEvent.total;
                progressCallback(progress);
            }
        });

        return data as IMediaFile;
    }

    public async deleteMediaFileAsync(organizationID: number, caseID: number, id: number): Promise<number> {
        await this.$http.delete(`organizations/${organizationID}/cases/${caseID}/media/${id}`);
        return id;
    }

    public async getMediaFileContentAsync(organizationID: number, caseID: number, id: number): Promise<Blob> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/media/${id}/content`);
        return data as Blob;
    }
}

export const MediaFileApi = MediaFileService.Instance;