import { IOverview, IOverviewCreateDTO, IOverviewEditDTO } from '../store/Overviews';
import { BaseService } from './base.service';

class OverviewService extends BaseService {
    private static _angleService: OverviewService;

    private constructor() {
        super();
    }

    public static get Instance(): OverviewService {
        return this._angleService || (this._angleService = new this());
    }

    public async getOverviewsAsync(organizationID: number, caseID: number): Promise<IOverview[]> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/overviews`);
        return data as IOverview[];
    }

    public async getOverviewAsync(organizationID: number, caseID: number, id: number): Promise<IOverview> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/overviews/${id}`);
        return data as IOverview;
    }

    public async createOverviewAsync(organizationID: number, caseID: number, angle: IOverviewCreateDTO): Promise<IOverview> {
        const { data } = await this.$http.put(`organizations/${organizationID}/cases/${caseID}/overviews`, angle);
        return data;
    }

    public async editOverviewAsync(organizationID: number, caseID: number, id: number, angle: IOverviewEditDTO): Promise<IOverview> {
        const { data } = await this.$http.post(`organizations/${organizationID}/cases/${caseID}/overviews/${id}`, angle);
        return data;
    }

    public async deleteOverviewAsync(organizationID: number, caseID: number, id: number): Promise<number> {
        await this.$http.delete(`organizations/${organizationID}/cases/${caseID}/overviews/${id}`);
        return id;
    }
}

export const OverviewApi = OverviewService.Instance;