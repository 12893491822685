import { AppThunkAction } from '.';
import { ApiTokenApi } from '../api';
import { ApiError } from '../types/ApiError';
import { createReducer, IActionType, IBaseAction } from './BaseStore';

export const ActionType: IActionType = {
    NAMESPACE: 'apitokens',
    REQUEST: 'apitokens/fetch',
    RECEIVE_FAIL: 'apitokens/fetchFail',
    RECEIVE_SUCCESS: 'apitokens/fetchSuccess',
    REQUEST_DETAILS: 'apitokens/fetchSingle',
    RECEIVE_DETAILS_FAIL: 'apitokens/fetchSingleFail',
    RECEIVE_DETAILS_SUCCESS: 'apitokens/fetchSingleSuccess',
    ADD: 'apitokens/add',
    ADD_FAIL: 'apitokens/add_fail',
    ADD_SUCCESS: 'apitokens/add_success',
    EDIT: 'apitokens/edit',
    EDIT_FAIL: 'apitokens/edit_fail',
    EDIT_SUCCESS: 'apitokens/edit_success',
    DELETE: 'apitokens/delete',
    DELETE_FAIL: 'apitokens/deleteFail',
    DELETE_SUCCESS: 'apitokens/deleteSuccess',
    RESET_STATE: 'apitokens/resetState'
};

export interface IApiToken {
    id: number;
    name: string;
    token: string;
    createdAt: string;
    accessType: "ReadOnly" | "ReadWrite";
}

export interface IApiTokenCreateDTO {
    name: string;
    accessType: "ReadOnly" | "ReadWrite";
}

export const reducer = createReducer<IApiToken>(ActionType);

export const actionCreators = {
    requestApiTokens: (organizationID: number): AppThunkAction<IBaseAction<IApiToken>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST });

        ApiTokenApi.getApiTokensAsync(organizationID).then(data => {
            dispatch({ type: ActionType.RECEIVE_SUCCESS, items: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_FAIL, error: reason });
        });
    },

    requestApiToken: (organizationID: number, caseID: number): AppThunkAction<IBaseAction<IApiToken>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST_DETAILS });

        ApiTokenApi.getApiTokenAsync(organizationID, caseID).then(data => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_SUCCESS, item: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_FAIL, error: reason });
        });
    },

    addApiToken: (organizationID: number, item: IApiTokenCreateDTO, callback: (c: IApiToken) => void = () => { }): AppThunkAction<IBaseAction<IApiToken>> => (dispatch, getState) => {
        dispatch({ type: ActionType.ADD });

        ApiTokenApi.createApiTokenAsync(organizationID, item).then(data => {
            dispatch({ type: ActionType.ADD_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.ADD_FAIL, error: reason });
        });
    }
};