import { ISelection } from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import {
  DetailsList,
  getTheme,
  IColumn,
  IconButton,
  IContextualMenuItem,
  mergeStyleSets,
  SelectionMode,
  Stack,
  StackItem,
  TextField,
} from 'office-ui-fabric-react';
import React from 'react';

import { IField } from '../../store/Fields';
import { Pagination } from './Pagination';

interface IDataTableColoumn {
  key: string;
  name: string;
  fieldName: string;
  minWidth: number;
  maxWidth: number;
  isResizable: boolean;
  isSortable: boolean;
  onRender?: (item?: any, index?: number, column?: IColumn) => any;
  isPadded?: boolean;
}

type DataTableProps<T> = {
  items: T[];
  selection?: ISelection;
  isLoading: boolean;
  coloumns: IDataTableColoumn[];
  fieldValues?: IField[];
  onDrop?: (item: T, startIndex: number, index: number) => void;
  onDelete?: (item: T) => void;
  onEdit?: (item: T) => void;
  onDownload?: (item: T) => void;
  onClone?: (item: T) => void;
  hideHeader?: boolean;
  hideFilter?: boolean;
  paginated?: boolean;
  pageSize?: number;
};

type DataTableState<T> = {
  items: T[];
  columns: IColumn[];
  filter?: string;
  fieldValues?: IField[];
  page: number;
};

const theme = getTheme();
const classNames = mergeStyleSets({
  actionsColoumn: {
    textAlign: 'right'
  },
  dragEnter: {
    outline: '1px solid #F00'
  },
  headerAndFooter: {
    borderTop: `1px solid ${theme.palette.neutralQuaternary}`,
    borderBottom: `1px solid ${theme.palette.neutralQuaternary}`,
    padding: 8,
    margin: '8px 0',
    background: theme.palette.neutralLighterAlt,
    position: 'relative',
    zIndex: 100
  },
  headerTitle: [
    theme.fonts.xLarge,
    {
      padding: '4px 0'
    }
  ],
  headerLinkSet: {
    margin: '4px -8px',
    height: 30
  },
  headerLink: {
    margin: '0 8px'
  },
  headerAction: {
    margin: '0 8px',
    float: 'right'
  }
});

class DataTable<T extends { [key: string]: any }> extends React.Component<DataTableProps<T>, DataTableState<T>> {
  private _defaultPageSize = 25;

  constructor(props: DataTableProps<T>) {
    super(props);

    const coloumns: IColumn[] = props.coloumns.map((v, i) => ({
      key: v.key,
      name: v.name,
      fieldName: v.fieldName,
      minWidth: v.minWidth,
      maxWidth: v.maxWidth,
      isRowHeader: i === 0,
      isResizable: v.isResizable,
      isSorted: false,
      isSortedDescending: false,
      onRender: v.onRender,
      isPadded: v.isPadded === undefined ? true : v.isPadded,
      onColumnClick: v.isSortable ? this._onColumnClick : undefined
    }));

    const actionsForItem = (item: T): IContextualMenuItem[] => {
      const actions: IContextualMenuItem[] = [];

      if (this.props.onEdit !== undefined) {
        actions.push({
          key: 'edit',
          text: 'Edit',
          title: 'Edit',
          iconProps: {
            iconName: 'EditCreate'
          },
          onClick: () => {
            if (this.props.onEdit) {
              this.props.onEdit(item);
            }
          }
        });
      }
      if (this.props.onDownload !== undefined) {
        actions.push({
          key: 'download',
          text: 'Download',
          title: 'Download',
          iconProps: {
            iconName: 'Download'
          },
          onClick: () => {
            if (this.props.onDownload) {
              this.props.onDownload(item);
            }
          }
        });
      }
      if (this.props.onClone !== undefined) {
        actions.push({
          key: 'clone',
          text: 'Clone',
          title: 'Clone',
          iconProps: {
            iconName: 'DependencyAdd'
          },
          onClick: () => {
            if (this.props.onClone) {
              this.props.onClone(item);
            }
          }
        });
      }
      if (this.props.onDelete !== undefined) {
        actions.push({
          key: 'delete',
          text: 'Delete',
          Title: 'Delete',
          iconProps: {
            iconName: 'Delete'
          },
          onClick: () => {
            if (this.props.onDelete) {
              this.props.onDelete(item);
            }
          }
        });
      }

      return actions;
    };

    if (this.props.onDelete || this.props.onEdit || this.props.onClone || this.props.onDownload) {
      coloumns.push({
        key: 'actions',
        name: '',
        fieldName: '',
        minWidth: 120,
        maxWidth: 120,
        isResizable: false,
        data: 'string',
        className: classNames.actionsColoumn,
        onRender: (item: T) => {
          return actionsForItem(item).map(a => <IconButton key={a.key} onClick={() => a.onClick!()} iconProps={a.iconProps} />);
        },
        isPadded: true
      });
    }

    this.state = {
      columns: coloumns,
      items: props.items,
      fieldValues: props.fieldValues,
      filter: undefined,
      page: 1
    };
  }

  render() {
    const pageSize = this.props.pageSize || this._defaultPageSize;
    const pageCount = Math.ceil(this.state.items.length / pageSize);

    let items = this.state.items;
    if (this.props.paginated) {
      items = this.state.items.slice((this.state.page - 1) * pageSize, this.state.page * pageSize);
    }

    return (
      <Stack>
        {this.props.hideFilter === true ? null : <StackItem align='end'>
          <TextField iconProps={{ iconName: 'Filter' }} value={this.state.filter} onChange={(i, val) => { this.setState({ items: this._orderItems(this._filterItems(this.props.items, val)), filter: val, page: 1 }); }} />
        </StackItem>}

        <DetailsList
          isHeaderVisible={!this.props.hideHeader}
          setKey='items'
          items={items}
          selectionMode={this.props.selection ? SelectionMode.multiple : SelectionMode.none}
          columns={this.state.columns}
          ariaLabelForGrid='Item details'
          selection={this.props.selection}
        />

        {((pageCount > 1) && this.props.paginated) ? <Pagination
          currentPage={this.state.page}
          totalPages={pageCount}
          onChange={(page) => { this.setState({ page: page }); }}
        /> : null}
      </Stack>
    );
  }

  public UNSAFE_componentWillReceiveProps(newProps: DataTableProps<T>) {
    if (newProps.items !== this.props.items) {
      const currColumn: IColumn | undefined = this.state.columns.find(c => c.isSorted === true);
      let items = newProps.items;

      if (currColumn) {
        items = this._orderItems(this._filterItems(items, this.state.filter));
      }

      let page = this.state.page;
      if (newProps.paginated) {
        const pageSize = this.props.pageSize || this._defaultPageSize;
        if (items.length < (page - 1) * pageSize) {
          page = 1;
        }
      }

      this.setState({
        items: items,
        page: page
      });
    }
    }

    private isMatch(value: any, filterLower: string): boolean {
        if (typeof value === 'object' && value !== null) {
            // Recursively search within nested objects or arrays
            return Object.values(value).some(subValue => this.isMatch(subValue, filterLower));
        } else if (value !== null && value !== undefined) {
            // Convert non-null/undefined values to string and perform a case insensitive search
            return value.toString().toLowerCase().indexOf(filterLower) !== -1;
        }
        return false;
    }

    private _filterItems(items: T[], filter?: string): T[] {
        if (filter && items.length > 0) {
            const filterLower = filter.toLowerCase();
            return items.filter(item =>
                Object.values(item).some(value => this.isMatch(value, filterLower))
            );
        }
        return items;
    }


  private _orderItems(items: T[]): T[] {
    const currColumn = this.state.columns.find(currCol => currCol.isSorted === true);

    if (currColumn !== undefined && currColumn.fieldName !== undefined) {
      return this._copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
    }

    return items;
  }

  @boundMethod
  private _onColumnClick(ev: React.MouseEvent<HTMLElement>, column: IColumn): void {
    console.log(this.state);

    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    if (currColumn.fieldName !== undefined) {
      const newItems = this._copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
      this.setState({
        columns: newColumns,
        items: newItems
      });
    }
  }

  @boundMethod
  private _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    // Custom fields
    if (!items[0][key] && this.props.fieldValues) {
      const field = this.props.fieldValues.filter(e => e.name === key.toString().split('-')[1])[0];
      if (field.type === 'number' || field.type === 'currency' || field.type === 'rent') {
        // @ts-ignore
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? ++a.fieldValues[field.id] < ++b.fieldValues[field.id] : ++a.fieldValues[field.id] > ++b.fieldValues[field.id]) ? 1 : -1));
      } else {
        // @ts-ignore
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a.fieldValues[field.id] < b.fieldValues[field.id] : a.fieldValues[field.id] > b.fieldValues[field.id]) ? 1 : -1));
      }
    }
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }
}

export default DataTable;