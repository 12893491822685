import { Dialog, DialogFooter, Dropdown, IDropdownOption } from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import { DefaultButton, DialogContent, DialogType, Panel, PanelType, PrimaryButton, Stack, StackItem, TextField } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { ErrorMessage, FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, IApiTokenCreateDTO, reducer } from '../../store/ApiTokens';

type NewApiKeyProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onAdded: () => void;
    onDismissed: () => void;
    organizationID: number;
};

type NewApiKeyState = {
    newkey: IApiTokenCreateDTO;
    dialogVisible: boolean;
    token: string;
};

class NewApiKey extends React.Component<NewApiKeyProps, NewApiKeyState> {

    constructor(props: NewApiKeyProps) {
        super(props);

        this.state = {
            newkey: {
                accessType: 'ReadOnly',
                name: ''
            },
            dialogVisible: false,
            token: ''
        };
    }

    public render(): React.ReactNode {
        return (
            <>
                <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='New field' onRenderFooterContent={() => <StackItem>
                    <PrimaryButton disabled={this.props.isAdding} onClick={this._add} text='Create' style={{ marginRight: '8px' }} />
                    <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
                </StackItem>}>
                    <Stack tokens={{ childrenGap: 20 }}>
                        <ErrorMessage error={this.props.addError} />

                        <StackItem>
                            <TextField label='Name' value={this.state.newkey.name} onChange={(e, value) => this.setState({ newkey: { ...this.state.newkey, name: value || '' } })} />
                            <FieldValidation error={this.props.addError} fieldname='Name' />
                        </StackItem>
                        
                        <StackItem>
                            <Dropdown 
                                label='Access type' 
                                selectedKey={this.state.newkey.accessType} 
                                onChange={this._onTypeChange}
                                options={[
                                    { key: 'ReadOnly', text: 'Read only' },
                                    { key: 'ReadWrite', text: 'Read and write' }
                                ]}/>
                            <FieldValidation error={this.props.addError} fieldname='AccessType' />
                        </StackItem>
                    </Stack>
                </Panel>
                        
                <Dialog
                    hidden={!this.state.dialogVisible}
                    onDismiss={() => this.setState({dialogVisible: false})}
                    maxWidth={500}
                    dialogContentProps={{
                        type: DialogType.normal,
                        
                        title: 'Api key',
                        subText: 'For security reasons the key will only be shown here, and cannot be retrieved later'
                    }}
                >
                    <DialogContent>
                        Please write down the following key: <br/><br />
                        <code style={{lineBreak:'anywhere'}}>{this.state.token}</code>
                    </DialogContent>
                    <DialogFooter>
                        <PrimaryButton onClick={() => this.setState({dialogVisible: false})} text="Close" />
                    </DialogFooter>
                </Dialog>
            </>
        );
    }

    @boundMethod
    private _onTypeChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined): void {
        if(option){
            this.setState({ newkey: { ...this.state.newkey, accessType: option.key.toString() as 'ReadOnly' | 'ReadWrite' } })
        }
    }

    @boundMethod
    private _add(): void {
        this.props.addApiToken(this.props.organizationID, this.state.newkey, (e) => {
            this.setState({
                dialogVisible: true,
                token: e.token
            });

            this.props.onAdded(); 
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.apiTokens, actionCreators)(NewApiKey);