import axios, { AxiosError, AxiosInstance } from 'axios';
import { TokenApi } from '.';
import { ApiError } from '../types/ApiError';

/**
 * Service API base class - configures default settings/error handling for inheriting class
 */
export abstract class BaseService {
    protected readonly $http: AxiosInstance;

    protected constructor(requestTimeout = 50000) {
        this.$http = axios.create({
            timeout: requestTimeout,
            baseURL: `${process.env.REACT_APP_API_HOST}/v1/`
        });

        this.$http.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                const reason = this.handleAxiosError(error);
                return Promise.reject(reason);
            }
        );

        this.$http.interceptors.request.use(
            request => {
                const token = TokenApi.getToken();
                if (token) {
                    const AuthStr = `Bearer ${TokenApi.getToken()}`;
                    request.headers['Authorization'] = AuthStr;
                }
                return request;
            }
        );
    }

    protected handleAxiosError = (error: AxiosError): ApiError => {

        if (error.response) {
            if (error.response.status === 400) {
                return error.response.data as ApiError;
            }
            if (error.response.status === 401) {
                return {
                    validationErrors: {},
                    errors: ['Access denied'],
                    stackTrace: ''
                };
            }
            if (error.response.status === 404) {
                return error.response.data as ApiError;
            }
            if (error.response.status === 500) {
                return error.response.data as ApiError;
            }
        }

        return {
            validationErrors: {},
            errors: [''],
            stackTrace: ''
        };
    }
}