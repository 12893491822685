import Moment from 'moment';
import { getId, TooltipHost } from 'office-ui-fabric-react';
import React from 'react';

type TimeAgoProps = {
    refreshMillis?: number;
    time: string;
};

type TimeAgoState = {
    timeString: string;
};

export class TimeAgo extends React.Component<TimeAgoProps, TimeAgoState> {
    timer: NodeJS.Timeout | undefined;
    private _hostId: string = getId('tooltipHost');

    constructor(props: TimeAgoProps) {
        super(props);
        this.state = {
            timeString: Moment(this.props.time).utc(true).fromNow()
        };
    }

    UNSAFE_componentWillReceiveProps(props: TimeAgoProps) {
        if (props.time !== this.props.time) {
            this.setState({
                timeString: Moment(props.time).utc(true).fromNow()
            });
        }
    }

    componentDidMount() {
        const interval = this.props.refreshMillis || 5000;
        this.timer = setInterval(this.updateTimer.bind(this), interval);
    }

    updateTimer() {
        this.setState({
            timeString: Moment(this.props.time).utc(true).fromNow()
        });
    }

    render() {
        return (
            <TooltipHost id={this._hostId} content={Moment(this.props.time).utc(true).local().toLocaleString()}>
                <span>{this.state.timeString}</span>
            </TooltipHost>
            );
    }

    componentWillUnmount() {
        if(this.timer){
            clearInterval(this.timer);
        }
    }
}