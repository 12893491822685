import { boundMethod } from 'autobind-decorator';
import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, PrimaryButton } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { actionCreators, reducer } from '../../store/Fields';

type SortingPickerProps = ReturnType<typeof reducer> & typeof actionCreators & {
    organizationID: number;
    caseID: number;
    value: string;
    onChange: (value:string) => void
};

type SortingPickerState = {
    editorOpen: boolean;
    field: number| string;
    direction: 'asc' | 'desc';
};

class SortingPicker extends React.Component<SortingPickerProps, SortingPickerState> {

    constructor(props: SortingPickerProps) {
        super(props);

        let property = props.value.substring(0, props.value.indexOf(':'));

        this.state = {
            editorOpen: false,
            field: isNaN(parseInt(property)) ? property : parseInt(property),
            direction: props.value.substring(props.value.indexOf(':')+1) as 'asc' |  'desc'
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: SortingPickerProps) {
        if(nextProps.value !== this.props.value){
            let property = nextProps.value.substring(0, nextProps.value.indexOf(':'));
            this.setState({
                field: isNaN(parseInt(property)) ? property : parseInt(property),
                direction: nextProps.value.substring(nextProps.value.indexOf(':')+1) as 'asc' |  'desc'
            });
        }
    }

    public componentDidMount(): void {
        if(!this.props.isLoading){
            this.props.requestFields(this.props.organizationID, this.props.caseID);
        }
    }

    @boundMethod
    private _togglePickerOpen() {
        this.setState({
            editorOpen: !this.state.editorOpen
        });
    }

    public render(): React.ReactNode {
        let field = this.props.items.find(e => e.id === this.state.field);

        return (
            <>
                <DefaultButton text={`${field ? field.name : this.state.field} ${this.state.direction}`} onClick={() => this._togglePickerOpen()} />

                <Dialog
                    hidden={!this.state.editorOpen}
                    onDismiss={this._togglePickerOpen}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Pick fields'
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 600 } }
                    }}
                >
                <Dropdown options={[{key: 'id', text: 'ID'}, {key: 'status', text: 'Status'}, ...this.props.items.map(e => {return {key: e.id, text: e.name}})]} selectedKey={this.state.field} onChange={(e, option) => {
                    if(option){
                        this.setState({
                            field: option.key
                        });
                    }
                }} />
                <Dropdown options={[{key: 'asc', text: 'Ascending'}, {key: 'desc', text: 'Descending'}]} selectedKey={this.state.direction} onChange={(e, option) => {
                    if(option){
                        this.setState({
                            direction: option.key as 'asc' | 'desc'
                        });
                    }
                }} />

                    <DialogFooter>
                        <PrimaryButton onClick={this._save} text='OK' />
                    </DialogFooter>
                </Dialog>
            </>
        );
    }

    @boundMethod
    private _save(): void {
        this.props.onChange(`${this.state.field}:${this.state.direction}`);
        this.setState({
            editorOpen: false
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.fields, actionCreators)(SortingPicker);