import { IAngle, IAngleCreateDTO, IAngleEditDTO } from '../store/Angles';
import { BaseService } from './base.service';

class AngleService extends BaseService {
    private static _angleService: AngleService;

    private constructor() {
        super();
    }

    public static get Instance(): AngleService {
        return this._angleService || (this._angleService = new this());
    }

    public async getAnglesAsync(organizationID: number, caseID: number, sectionID: number): Promise<IAngle[]> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/sections/${sectionID}/angles`);
        return data as IAngle[];
    }

    public async getAngleAsync(organizationID: number, caseID: number, sectionID: number, id: number): Promise<IAngle> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/sections/${sectionID}/angles/${id}`);
        return data as IAngle;
    }

    public async createAngleAsync(organizationID: number, caseID: number, sectionID: number, angle: IAngleCreateDTO): Promise<IAngle> {
        const { data } = await this.$http.put(`organizations/${organizationID}/cases/${caseID}/sections/${sectionID}/angles`, angle);
        return data;
    }

    public async editAngleAsync(organizationID: number, caseID: number, sectionID: number, id: number, angle: IAngleEditDTO): Promise<IAngle> {
        const { data } = await this.$http.post(`organizations/${organizationID}/cases/${caseID}/sections/${sectionID}/angles/${id}`, angle);
        return data;
    }

    public async deleteAngleAsync(organizationID: number, caseID: number, sectionID: number, id: number): Promise<number> {
        await this.$http.delete(`organizations/${organizationID}/cases/${caseID}/sections/${sectionID}/angles/${id}`);
        return id;
    }
}

export const AngleApi = AngleService.Instance;