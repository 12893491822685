import { AppThunkAction } from '.';
import { UnitApi } from '../api';
import { ApiError } from '../types/ApiError';
import { createReducer, IActionType, IBaseAction } from './BaseStore';

export const ActionType: IActionType = {
    NAMESPACE: 'units',
    REQUEST: 'units/fetch',
    RECEIVE_FAIL: 'units/fetchFail',
    RECEIVE_SUCCESS: 'units/fetchSuccess',
    REQUEST_DETAILS: 'units/fetchSingle',
    RECEIVE_DETAILS_FAIL: 'units/fetchSingleFail',
    RECEIVE_DETAILS_SUCCESS: 'units/fetchSingleSuccess',
    ADD: 'units/add',
    ADD_FAIL: 'units/add_fail',
    ADD_SUCCESS: 'units/add_success',
    EDIT: 'units/edit',
    EDIT_FAIL: 'units/edit_fail',
    EDIT_SUCCESS: 'units/edit_success',
    DELETE: 'units/delete',
    DELETE_FAIL: 'units/deleteFail',
    DELETE_SUCCESS: 'units/deleteSuccess',
    RESET_STATE: 'units/resetState'
};

export interface IUnit {
    id: number;
    maskID?: string;
    state: 'ForSale' | 'Reserved' | 'Sold' | 'Vacant' | 'RentedOut' | 'ConditionallySold' | 'Deactivated';
    url: string;
    previewID?: number;
    images: number[];
    floorplans: number[];
    materialListID?: number;
    dataSheetID?: number;
    sections: number[];
    fieldValues: {[id:string]: string}
}

export interface IUnitCreateDTO {
    maskID?: string;
    state: 'ForSale' | 'Reserved' | 'Sold' | 'Vacant' | 'RentedOut' | 'ConditionallySold' | 'Deactivated';
    url: string;
    previewID?: number;
    images: number[];
    floorplans: number[];
    materialListID?: number;
    dataSheetID?: number;
    sections: number[];
    fieldValues: { [id: string]: string }
}

export interface IUnitEditDTO {
    maskID?: string;
    state: 'ForSale' | 'Reserved' | 'Sold' | 'Vacant' | 'RentedOut' | 'ConditionallySold' | 'Deactivated';
    url: string;
    previewID?: number;
    images: number[];
    floorplans: number[];
    materialListID?: number;
    dataSheetID?: number;
    sections: number[];
    fieldValues: { [id: string]: string }
}

export const reducer = createReducer<IUnit>(ActionType);

export const actionCreators = {
    requestUnits: (organizationID: number, caseID: number): AppThunkAction<IBaseAction<IUnit>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST });

        UnitApi.getUnitsAsync(organizationID, caseID).then(data => {
            dispatch({ type: ActionType.RECEIVE_SUCCESS, items: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_FAIL, error: reason });
        });
    },

    requestUnit: (organizationID: number, caseID: number, id: number): AppThunkAction<IBaseAction<IUnit>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST_DETAILS });

        UnitApi.getUnitAsync(organizationID, caseID, id).then(data => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_SUCCESS, item: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_FAIL, error: reason });
        });
    },

    addUnit: (organizationID: number, caseID: number, item: IUnitCreateDTO, callback: (c: IUnit) => void = () => { }, failCallback: (c: ApiError) => void = () => { }): AppThunkAction<IBaseAction<IUnit>> => (dispatch, getState) => {
        dispatch({ type: ActionType.ADD });

        UnitApi.createUnitAsync(organizationID, caseID, item).then(data => {
            dispatch({ type: ActionType.ADD_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.ADD_FAIL, error: reason });
            failCallback(reason);
        });
    },

    editUnit: (organizationID: number, caseID: number, unit: IUnit, item: IUnitEditDTO, callback: (c: IUnit) => void = () => { }, failCallback: (c: ApiError) => void = () => { }): AppThunkAction<IBaseAction<IUnit>> => (dispatch, getState) => {
        dispatch({ type: ActionType.EDIT });

        UnitApi.editUnitAsync(organizationID, caseID, unit.id, item).then(data => {
            dispatch({ type: ActionType.EDIT_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.EDIT_FAIL, error: reason });
            failCallback(reason);
        });
    },

    deleteUnit: (organizationID: number, caseID: number, unit: IUnit, callback: () => void = () => { }): AppThunkAction<IBaseAction<IUnit>> => (dispatch, getState) => {
        dispatch({ type: ActionType.DELETE });

        UnitApi.deleteUnitAsync(organizationID, caseID, unit.id).then(data => {
            dispatch({ type: ActionType.DELETE_SUCCESS, item: unit });
            callback();
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.DELETE_FAIL, error: reason });
        });
    }
};