import { boundMethod } from 'autobind-decorator';
import { CommandBar, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack, StackItem } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import ContentWithoutSideMenu from '../../components/ContentWithoutSideMenu';
import { DataTable, ErrorMessage } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, IUser, reducer } from '../../store/Users';
import EditUser from './EditUser';
import NewUser from './NewUser';

type UsersProps = ReturnType<typeof reducer> & typeof actionCreators & RouteComponentProps<{}>;

type UsersState = {
    showCreatePanel: boolean;
    selectedUser?: IUser;
    deleteDialogVisible: boolean;
    editDialogVisible: boolean;
};

class Users extends React.Component<UsersProps, UsersState> {

    constructor(props: UsersProps) {
        super(props);

        this.state = {
            showCreatePanel: false,
            selectedUser: undefined,
            deleteDialogVisible: false,
            editDialogVisible: false
        };
    }

    public componentDidMount(): void {
        this.props.requestUsers();
    }

    public render(): React.ReactNode {
        return (
            <ContentWithoutSideMenu>
                <NewUser isOpen={this.state.showCreatePanel} onDismissed={this._hideCreatePanel} onUserAdded={this._hideCreatePanel} />
                <EditUser user={this.state.selectedUser} isOpen={this.state.editDialogVisible} onDismissed={this._hideEditPanel} onUserEdited={this._hideEditPanel} />


                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                        <CommandBar
                            items={[
                                {
                                    key: 'Add',
                                    name: 'Create new user',
                                    iconProps: {
                                        iconName: 'Add'
                                    },
                                    onClick: () => this._showCreatePanel()
                                },
                            ]}
                        />
                    </StackItem>

                    <StackItem>
                        <ErrorMessage error={this.props.loadError} />

                        <DataTable<IUser> paginated items={this.props.items.filter(e => e.userType === 'SuperAdministrator')} isLoading={this.props.isLoading} onEdit={this._showEditPanel} onDelete={this._promptForDelete} coloumns={[
                            {
                                key: 'email',
                                name: 'Email',
                                fieldName: 'email',
                                minWidth: 150,
                                maxWidth: 150,
                                isResizable: true,
                                isSortable: true
                            }]} />
                    </StackItem>

                </Stack>

                <Dialog
                    hidden={!this.state.deleteDialogVisible}
                    onDismiss={this._hideDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete user',
                        subText: 'This will remove the user permanently, and they will no longer be able to log in'
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <ErrorMessage error={this.props.deleteError} />
                    <DialogFooter>
                        <PrimaryButton onClick={this._deleteUser} text='Delete' />
                        <DefaultButton onClick={this._hideDialog} text='Cancel' />
                    </DialogFooter>
                </Dialog>

            </ContentWithoutSideMenu>
        );
    }

    @boundMethod
    private _deleteUser(): void {
        if (this.state.selectedUser) {
            this.props.deleteUser(this.state.selectedUser, () => { this._hideDialog(); });
        }
    }

    @boundMethod
    private _promptForDelete(user: IUser) {
        this.setState({
            selectedUser: user,
            deleteDialogVisible: true
        });
    }

    @boundMethod
    private _hideDialog() {
        this.setState({
            deleteDialogVisible: false
        });
    }

    @boundMethod
    private _showCreatePanel() {
        this.setState({ showCreatePanel: true });
    }

    @boundMethod
    private _hideCreatePanel() {
        this.setState({
            showCreatePanel: false
        });
    }

    @boundMethod
    private _showEditPanel(user: IUser) {
        this.setState({
            editDialogVisible: true,
            selectedUser: user
        });
    }

    @boundMethod
    private _hideEditPanel() {
        this.setState({
            editDialogVisible: false,
            selectedUser: undefined
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.users, actionCreators)(Users);