import { Dropdown, IDropdownOption } from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import { DefaultButton, Panel, PanelType, PrimaryButton, Stack, StackItem, TextField } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { ErrorMessage, FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import { ICase } from '../../store/Cases';
import { actionCreators, reducer } from '../../store/OrganizationUsers';
import { IUserCreateDTO } from '../../store/Users';

type NewUserProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onUserAdded: () => void;
    onDismissed: () => void;
    organizationID: number;
    cases: ICase[];
};

type NewUserState = {
    newUser: IUserCreateDTO;
};

class NewUser extends React.Component<NewUserProps, NewUserState> {

    constructor(props: NewUserProps) {
        super(props);

        this.state = {
            newUser: {
                email: '',
                userType: 'Administrator',
                caseAccess: {},
                organizationID: this.props.organizationID
            }
        };
    }

    public render(): React.ReactNode {
        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='New user' onRenderFooterContent={() => <StackItem>
                <PrimaryButton disabled={this.props.isAdding} onClick={this._addUser} text='Create' style={{ marginRight: '8px' }} />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </StackItem>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <ErrorMessage error={this.props.addError} />

                    <StackItem>
                        <TextField label='Email' type='email' value={this.state.newUser.email} onChange={(e, value) => this.setState({ newUser: { ...this.state.newUser, email: value || '' } })} />
                        <FieldValidation error={this.props.addError} fieldname='email' />
                    </StackItem>
                    
                    <StackItem>
                        <Dropdown 
                            label='Type' 
                            selectedKey={this.state.newUser.userType} 
                            onChange={this._onTypeChange}
                            options={[
                                { key: 'Administrator', text: 'Administrator' },
                                { key: 'Editor', text: 'Editor' },
                                { key: 'Maintainer', text: 'Maintainer' }
                            ]}/>
                        <FieldValidation error={this.props.addError} fieldname='UserType' />
                    </StackItem>
                    
                    {(this.state.newUser.userType === 'Editor' || this.state.newUser.userType === 'Maintainer') ?
                        <>
                            {this.props.cases.map(e => <StackItem key={e.id}>
                                    <Dropdown label={e.name} 
                                        selectedKey={this.state.newUser.caseAccess[e.id] || ''}
                                        onChange={(_e, option) => this._onAccessChange(e, option!.key.toString())}
                                        options={[
                                            {key: '', text: 'No access'},
                                            {key: 'ReadOnly', text: 'Read only'},
                                            {key: 'ReadWrite', text: 'Read write'}
                                        ]} />
                                </StackItem>)}
                        <FieldValidation error={this.props.addError} fieldname='UserType' />
                    </> : null}
                </Stack>
            </Panel>
        );
    }

    @boundMethod
    private _onAccessChange(_case: ICase, key: string): void {
        if(key === ''){
            let access = {...this.state.newUser.caseAccess};
            delete access[_case.id];
            this.setState({ newUser: { ...this.state.newUser, caseAccess: access}});
        }else{
            this.setState({ newUser: { ...this.state.newUser, caseAccess: {...this.state.newUser.caseAccess, [_case.id]: key as  'ReadOnly' | 'ReadWrite'} }});
        }
    }

    @boundMethod
    private _onTypeChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined): void {
        if(option){
            this.setState({ newUser: { ...this.state.newUser, userType: option.key.toString() as 'Administrator' | 'Editor' | 'Maintainer' } })
        }
    }

    @boundMethod
    private _addUser(): void {
        this.props.addUser(this.props.organizationID, this.state.newUser, () => { this.props.onUserAdded(); });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.users, actionCreators)(NewUser);