import JWT from 'jwt-decode';

type token = {
    UserID: number;
    UserName: string;
    UserType: 'SuperAdministrator' | 'Administrator' | 'Editor' | 'API';
    OrganizationID?: number;
    CaseAccess?: string;
    aud: string;
    exp: number;
    iss: string;
};

class TokenService {
    private static _tokenService: TokenService;

    private _token = '';
    private _username = '';
    private _usertype: 'SuperAdministrator' | 'Administrator' | 'Editor' | 'API' = 'Editor';
    private _organizationID: number | undefined = undefined;
    private _caseAccess: { [id: number]: 'ReadOnly' | 'ReadWrite' } = {}

    private constructor() {

        if (typeof document !== 'undefined') {
            const token = this.getLocalStorage('token');

            if (token) {
                this.setToken(token, false);
            }
        }
    }

    public static get Instance(): TokenService {
        return this._tokenService || (this._tokenService = new this());
    }

    public setToken(token: string, persist: boolean): void {
        if (!token) {
            this._token = '';
            this._username = '';
            this._usertype = 'Editor';
            this._organizationID = undefined;
            this._caseAccess = {};

            if (persist) {
                this.deleteLocalStorage('token');
            }
            return;
        }

        const decoded = JWT(token) as token;

        if (decoded.exp > new Date().getTime() / 1000) {
            this._token = token;
            this._username = decoded.UserName;
            this._usertype = decoded.UserType;
            this._organizationID = decoded.OrganizationID;
            if (decoded.CaseAccess) {
                this._caseAccess = JSON.parse(decoded.CaseAccess);
            }

            if (persist) {
                this.setLocalStorage('token', token);
            }
        }
    }

    public getToken(): string {
        return this._token;
    }

    public getUsername(): string {
        return this._username;
    }

    public getUsertype(): 'SuperAdministrator' | 'Administrator' | 'Editor' | 'API' {
        return this._usertype;
    }

    public getOrganizationID(): number | undefined {
        return this._organizationID;
    }

    public getCaseAccess(): { [id: number]: 'ReadOnly' | 'ReadWrite' } {
        return this._caseAccess;
    }

    private setLocalStorage(name: string, value: string) {
        if (typeof localStorage !== 'undefined') {
            localStorage.setItem(name, value);
        }
    }

    private getLocalStorage(name: string): string | null {
        if (typeof localStorage !== 'undefined') {
            const value = localStorage.getItem(name);
            return value;
        }
        return null;
    }

    private deleteLocalStorage(name: string) {
        if (typeof localStorage !== 'undefined') {
            localStorage.removeItem(name);
        }
    }
}

export const TokenApi = TokenService.Instance;