import * as Auth from './Auth';
import * as Angles from './Angles';
import * as ApiTokens from './ApiTokens';
import * as Cases from './Cases';
import * as Fields from './Fields';
import * as MediaFiles from './MediaFiles';
import * as Organizations from './Organizations';
import * as OrganizationUsers from './OrganizationUsers';
import * as Sections from './Sections';
import * as Units from './Units';
import * as Users from './Users';
import * as Overviews from './Overviews';
import * as CaseDesign from './CaseDesign';
import * as Maintainers from './Maintainers';
import { IBaseState } from './BaseStore';

// The top-level state object
export interface ApplicationState {
    auth: Auth.IAuthState;
    angles: IBaseState<Angles.IAngle>;
    apiTokens: IBaseState<ApiTokens.IApiToken>;
    cases: IBaseState<Cases.ICase>;
    fields: IBaseState<Fields.IField>;
    mediaFiles: IBaseState<MediaFiles.IMediaFile>;
    organizations: IBaseState<Organizations.IOrganization>;
    organizationUsers: IBaseState<Users.IUser>;
    sections: IBaseState<Sections.ISection>;
    units: IBaseState<Units.IUnit>;
    users: IBaseState<Users.IUser>;
    overviews: IBaseState<Overviews.IOverview>;
    caseDesign: CaseDesign.State;
    maintainers: IBaseState<Maintainers.IMaintainer>;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    auth: Auth.reducer,
    angles: Angles.reducer,
    apiTokens: ApiTokens.reducer,
    cases: Cases.reducer,
    fields: Fields.reducer,
    mediaFiles: MediaFiles.reducer,
    organizations: Organizations.reducer,
    organizationUsers: OrganizationUsers.reducer,
    sections: Sections.reducer,
    units: Units.reducer,
    users: Users.reducer,
    overviews: Overviews.reducer,
    caseDesign: CaseDesign.reducer,
    maintainers: Maintainers.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): any;
}

// Gets the underlying unique types for all actionCreator objects for which it is applied to - used in reducer to help infer dispatched action type
export type FunctionReturnTypes<T> = { [K in keyof T]: T[K] extends (...args: any[]) => any ? ReturnType<T[K]> : never }[keyof T];
