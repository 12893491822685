import { boundMethod } from 'autobind-decorator';
import { CommandBar, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack, StackItem } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { DataTable, ErrorMessage } from '../../components/controls';
import { ApplicationState } from '../../store';
import * as Cases from '../../store/Cases';
import * as Users from '../../store/OrganizationUsers';
import { IUser } from '../../store/Users';
import NewOrganizationUser from './NewOrganizationUser';
import EditOrganizationUser from './EditOrganizationUser';

type UsersProps = {
    organizationUsers: ReturnType<typeof Users.reducer>; 
    cases: ReturnType<typeof Cases.reducer>; 
} & typeof Users.actionCreators & typeof Cases.actionCreators & RouteComponentProps<{ organizationID: string }>;

type UsersState = {
    showCreatePanel: boolean;
    selectedUser?: IUser;
    deleteDialogVisible: boolean;
    editDialogVisible: boolean;
};

class OrganizationUsers extends React.Component<UsersProps, UsersState> {

    constructor(props: UsersProps) {
        super(props);

        this.state = {
            showCreatePanel: false,
            selectedUser: undefined,
            deleteDialogVisible: false,
            editDialogVisible: false
        };
    }

    public componentDidMount(): void {
        this.props.requestUsers(parseInt(this.props.match.params.organizationID));
        this.props.requestCases(parseInt(this.props.match.params.organizationID));
    }

    public render(): React.ReactNode {
        return (
            <>
                <NewOrganizationUser cases={this.props.cases.items} organizationID={parseInt(this.props.match.params.organizationID)} isOpen={this.state.showCreatePanel} onDismissed={this._hideCreatePanel} onUserAdded={this._hideCreatePanel} />
                <EditOrganizationUser user={this.state.selectedUser} cases={this.props.cases.items} organizationID={parseInt(this.props.match.params.organizationID)} isOpen={this.state.editDialogVisible} onDismissed={this._hideEditPanel} onSaved={this._hideEditPanel} />


                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>

                        <CommandBar
                            items={[
                                {
                                    key: 'Add',
                                    name: 'Create new user',
                                    iconProps: {
                                        iconName: 'Add'
                                    },
                                    onClick: () => this._showCreatePanel()
                                },
                            ]}
                        />


                    </StackItem>

                    <StackItem>
                        <ErrorMessage error={this.props.organizationUsers.loadError} />

                        <DataTable<IUser> paginated items={this.props.organizationUsers.items} isLoading={this.props.organizationUsers.isLoading} onEdit={this._showEditPanel} onDelete={this._promptForDelete} coloumns={[
                            {
                                key: 'email',
                                name: 'Email',
                                fieldName: 'email',
                                minWidth: 150,
                                maxWidth: 150,
                                isResizable: true,
                                isSortable: true
                            },{
                                key: 'type',
                                name: 'User type',
                                fieldName: 'userType',
                                minWidth: 150,
                                maxWidth: 150,
                                isResizable: true,
                                isSortable: true
                            },{
                                key: 'access',
                                name: 'Access',
                                fieldName: '',
                                minWidth: 150,
                                maxWidth: 150,
                                isResizable: true,
                                isSortable: true,
                                onRender: (item: IUser) => {
                                    if(item.userType === 'Editor' || item.userType === 'Maintainer'){
                                        let keys = Object.keys(item.caseAccess).map(e => parseInt(e));

                                        return <span>{keys.map(e => {
                                            let _case = this.props.cases.items.find(x => x.id === e);
                                            if(_case){
                                                return <span key={e}>{_case.name}: {item.caseAccess[e]}<br /></span>
                                            }
                                            return null;
                                        })}</span>
                                    }
                                    return null;
                                }
                            }]} />
                    </StackItem>

                </Stack>

                <Dialog
                    hidden={!this.state.deleteDialogVisible}
                    onDismiss={this._hideDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete user',
                        subText: 'This will remove the user permanently, and they will no longer be able to log in'
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <ErrorMessage error={this.props.organizationUsers.deleteError} />
                    <DialogFooter>
                        <PrimaryButton onClick={this._deleteUser} text='Delete' />
                        <DefaultButton onClick={this._hideDialog} text='Cancel' />
                    </DialogFooter>
                </Dialog>

            </>
        );
    }

    @boundMethod
    private _deleteUser(): void {
        if (this.state.selectedUser) {
            this.props.deleteUser(parseInt(this.props.match.params.organizationID), this.state.selectedUser, () => { this._hideDialog(); });
        }
    }

    @boundMethod
    private _promptForDelete(user: IUser) {
        this.setState({
            selectedUser: user,
            deleteDialogVisible: true
        });
    }

    @boundMethod
    private _hideDialog() {
        this.setState({
            deleteDialogVisible: false
        });
    }

    @boundMethod
    private _showCreatePanel() {
        this.setState({ showCreatePanel: true });
    }

    @boundMethod
    private _hideCreatePanel() {
        this.setState({
            showCreatePanel: false
        });
    }

    @boundMethod
    private _showEditPanel(user: IUser) {
        this.setState({
            editDialogVisible: true,
            selectedUser: user
        });
    }

    @boundMethod
    private _hideEditPanel() {
        this.setState({
            editDialogVisible: false,
            selectedUser: undefined
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state, {...Users.actionCreators, ...Cases.actionCreators})(OrganizationUsers);