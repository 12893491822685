import {
  Checkbox,
  DefaultButton,
  Dropdown,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  StackItem,
} from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import { parse, ParseResult } from 'papaparse';
import React from 'react';
import { connect } from 'react-redux';

import { ApplicationState } from '../../store';
import { IField } from '../../store/Fields';
import { IMediaFile } from '../../store/MediaFiles';
import { ISection } from '../../store/Sections';
import {
  actionCreators,
  IUnit,
  IUnitCreateDTO,
  IUnitEditDTO,
  reducer,
} from '../../store/Units';
import { ApiError } from '../../types/ApiError';

type EditUnitProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onSaved: () => void;
    onDismissed: () => void;
    fields: IField[];
    units: IUnit[];
    sections: ISection[];
    mediaFiles: IMediaFile[];
    organizationID: number;
    caseID: number;
};

type Field = {
    id: string;
    target: string;
    error: string | undefined;
}

type EditUnitState = {
    csvFile: ParseResult<{[id: string]: string}> | undefined;
    fields: Field[];
    identifierField: string;
    error: string | undefined;
    progress: number | undefined;
    progresslabel: string | undefined;
    errors: ApiError[];
    canceled: boolean;
};

class EditUnit extends React.Component<EditUnitProps, EditUnitState> {

    constructor(props: EditUnitProps) {
        super(props);

        this.state = {
            csvFile: undefined,
            fields: [],
            identifierField: '',
            error: undefined,
            progress: undefined,
            progresslabel: undefined,
            errors: [],
            canceled: false
        };
    }

    public render(): React.ReactNode {
        if(this.props.fields === undefined){
            return null;
        }

        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='Import csv' onRenderFooterContent={() => <StackItem>
                <PrimaryButton disabled={this.state.csvFile === undefined} onClick={() => this.import()} text='Import' style={{ marginRight: '8px' }} />
                <DefaultButton onClick={this.cancel}>Cancel</DefaultButton>
            </StackItem>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                        <input type='file' onChange={(e) => {
                            if (e.target.files) {
                                const file = e.target.files[0];

                                if (file !== undefined) {
                                    this._readFile(file);
                                }
                            }
                        }} />
                    </StackItem>
                    
                    {this.renderErrors()}
                    
                    {this.state.progress === undefined ? null : <ProgressIndicator percentComplete={this.state.progress} label={this.state.progresslabel} />}
                    {this.state.progress === undefined ? this.renderFields() : null}

                </Stack>
            </Panel>
        );
    }

    @boundMethod
    private cancel(): void {
        this.setState({
            canceled: true,
            errors: [],
            csvFile: undefined,
            progress: undefined,
            progresslabel: undefined
        });

        this.props.onDismissed();
    }

    @boundMethod
    private import(): void {
        this.setState({
            error: undefined,
            fields: this.state.fields.map(e => {return {...e, error: undefined}}),
            progress: undefined,
            progresslabel: undefined,
            canceled: false,
            errors: []
        });

        if(this.validateFields() && this.state.csvFile !== undefined){
            let identifierField = this.state.fields.find(e => e.id === this.state.identifierField)!;
            let finishedItems: number = 0;

            let callback = () => {
                finishedItems++;

                if(!this.state.canceled){
                    this.setState({
                        progress: (finishedItems / this.state.csvFile!.data.length),
                        progresslabel: `${finishedItems} / ${this.props.units.length} units updated`
                    });
                }

                updateNext();
            };

            let failCallback = (e: ApiError) => {
                finishedItems++;
                console.error(e);

                this.setState(state => ({
                    progress: (finishedItems / state.csvFile!.data.length),
                    progresslabel: `${finishedItems} / ${this.props.units.length} units updated`,
                    errors: [...state.errors, e]
                }));

                updateNext();
            };

            const updateNext = () => {
                if (this.state.canceled) {
                    return;
                }
                if (finishedItems === this.state.csvFile!.data.length) {
                    this.setState({
                        progress: undefined,
                        progresslabel: undefined
                    });
                    this.props.onSaved();
                } else {
                    let data = this.state.csvFile!.data[finishedItems];
                    let key = data[identifierField.id];

                    let unit = this.props.units.find(e => e.fieldValues[identifierField.target] === key);

                    if (unit !== undefined) {
                        let unitDTO: IUnitEditDTO = {
                            maskID: unit.maskID, 
                            fieldValues: { ...unit.fieldValues },
                            floorplans: unit.floorplans,
                            images: unit.images,
                            sections: unit.sections,
                            state: unit.state,
                            url: unit.url,
                            dataSheetID: unit.dataSheetID,
                            materialListID: unit.materialListID,
                            previewID: unit.previewID
                        }

                        for (let j = 0; j < this.state.fields.length; j++) {
                            const field = this.state.fields[j];
                            if (field.target !== '') {
                                let value = data[field.id];

                                if (value !== undefined) {
                                    if (field.target === 'state') {
                                        unitDTO.state = data[field.id] as "ForSale" | "Reserved" | "Sold" | "Vacant" | "RentedOut" | "ConditionallySold" | "Deactivated";
                                    } else if (field.target === 'maskid') {
                                        if(value === ''){
                                            unitDTO.maskID = undefined;
                                        }else{
                                            unitDTO.maskID = data[field.id];
                                        }
                                    } else if (field.target === 'url') {
                                        unitDTO.url = data[field.id];
                                    } else if (field.target === 'sections') {
                                        if(value === ''){
                                            unitDTO.sections = [];
                                        }else{
                                            unitDTO.sections = data[field.id].split(',').map(e => this.parseSection(e));
                                        }
                                    } else if (field.target === 'images') {
                                        if(value === ''){
                                            unitDTO.images = [];
                                        }else{
                                            unitDTO.images = data[field.id].split(',').map(e => this.parseMedia(e));
                                        }
                                    } else if (field.target === 'floorplans') {
                                        if(value === ''){
                                            unitDTO.floorplans = [];
                                        }else{
                                            unitDTO.floorplans = data[field.id].split(',').map(e => this.parseMedia(e));
                                        }
                                    } else if (field.target === 'preview') {
                                        if(value === ''){
                                            unitDTO.previewID = undefined;
                                        }else{
                                            unitDTO.previewID = this.parseMedia(data[field.id]);
                                        }
                                    } else if (field.target === 'materiallist') {
                                        if(value === ''){
                                            unitDTO.materialListID = undefined;
                                        }else{
                                            unitDTO.materialListID = this.parseMedia(data[field.id]);
                                        }
                                    } else if (field.target === 'datasheet') {
                                        if(value === ''){
                                            unitDTO.dataSheetID = undefined;
                                        }else{
                                            unitDTO.dataSheetID = this.parseMedia(data[field.id]);
                                        }
                                    } else {
                                        let fieldData = this.props.fields.find(e => e.id === this.parseMedia(field.target));

                                        if (fieldData !== undefined && (fieldData.type === 'currency' || fieldData.type === 'number' || fieldData.type === 'rent' )) {
                                            value = value.split(',').join('');
                                        }

                                        unitDTO.fieldValues[field.target] = value;
                                    }
                                }
                            }
                        }

                        this.props.editUnit(this.props.organizationID, this.props.caseID, unit, unitDTO, callback, failCallback);
                    } else {
                        let unitDTO: IUnitCreateDTO = {
                            fieldValues: {},
                            floorplans: [],
                            images: [],
                            sections: [],
                            state: 'ForSale',
                            url: '',
                            dataSheetID: undefined,
                            materialListID: undefined,
                            previewID: undefined
                        }

                        for (let j = 0; j < this.state.fields.length; j++) {
                            const field = this.state.fields[j];
                            if (field.target !== '') {
                                let value = data[field.id];

                                if (value !== undefined) {
                                    if (field.target === 'state') {
                                        unitDTO.state = data[field.id] as "ForSale" | "Reserved" | "Sold" | "Vacant" | "RentedOut" | "ConditionallySold" | "Deactivated";
                                    } else if (field.target === 'maskid') {
                                      if(value === ''){
                                          unitDTO.maskID = undefined;
                                      }else{
                                          unitDTO.maskID = data[field.id];
                                        }
                                    } else if (field.target === 'url') {
                                        unitDTO.url = data[field.id];
                                    } else if (field.target === 'sections') {
                                        if(value === ''){
                                            unitDTO.sections = [];
                                        }else{
                                            unitDTO.sections = data[field.id].split(',').map(e => this.parseSection(e));
                                        }
                                    } else if (field.target === 'images') {
                                        if(value === ''){
                                            unitDTO.images = [];
                                        }else{
                                            unitDTO.images = data[field.id].split(',').map(e => this.parseMedia(e));
                                        }
                                    } else if (field.target === 'floorplans') {
                                        if(value === ''){
                                            unitDTO.floorplans = [];
                                        }else{
                                            unitDTO.floorplans = data[field.id].split(',').map(e => this.parseMedia(e));
                                        }
                                    } else if (field.target === 'preview') {
                                        if(value === ''){
                                            unitDTO.previewID = undefined;
                                        }else{
                                            unitDTO.previewID = this.parseMedia(data[field.id]);
                                        }
                                    } else if (field.target === 'materiallist') {
                                        if(value === ''){
                                            unitDTO.materialListID = undefined;
                                        }else{
                                            unitDTO.materialListID = this.parseMedia(data[field.id]);
                                        }
                                    } else if (field.target === 'datasheet') {
                                        if(value === ''){
                                            unitDTO.dataSheetID = undefined;
                                        }else{
                                            unitDTO.dataSheetID = this.parseMedia(data[field.id]);
                                        }
                                    } else {
                                        let fieldData = this.props.fields.find(e => e.id === parseInt(field.target));

                                        if (fieldData !== undefined && (fieldData.type === 'currency' || fieldData.type === 'number' || fieldData.type === 'rent')) {
                                            value = value.split(',').join('');
                                        }

                                        unitDTO.fieldValues[field.target] = value;
                                    }
                                }
                            }
                        }

                        this.props.addUnit(this.props.organizationID, this.props.caseID, unitDTO, callback, failCallback);
                    }
                }
            }

            updateNext();
        }
    }

    private parseSection(section: string): number{
        if(isNaN(Number(section))){
            var _section = this.props.sections.find(e => e.name.toLowerCase() === section.toLowerCase());
            if(_section){
                return _section.id;
            }else{
                return 0;
            }
        }
        return parseInt(section);
    }

    private parseMedia(media: string): number{
        if(isNaN(Number(media))){
            var _media = this.props.mediaFiles.find(e => e.filename.toLowerCase() === media.toLowerCase());
            if(_media){
                return _media.id;
            }else{
                return 0;
            }
        }
        return parseInt(media);
    }

    private validateFields(): boolean {

        if(this.state.csvFile === undefined || this.state.csvFile.data.length === 0){
            this.setState({
                error: 'No data found in csv file'
            });
            console.error('No data found in csv file');
            return false;
        }

        let identifierField = this.state.fields.find(e => e.id === this.state.identifierField);
        if(identifierField === undefined){
            this.setState({
                error: 'One field must be marked as identifier'
            });
            console.error('One field must be marked as identifier');
            return false;
        }
        



        for (let i = 0; i < this.state.fields.length; i++) {
            const field = this.state.fields[i];
            
            if(field.target === 'state'){
                let invalidIndex = this.state.csvFile.data.findIndex(
                  e => e[field.id] !== 'ForSale' && 
                  e[field.id] !== 'Reserved' && 
                  e[field.id] !==  'Sold' && 
                  e[field.id] !== 'Vacant' && 
                  e[field.id] !== 'Deactivated' && 
                  e[field.id] !== 'ConditionallySold' && 
                  e[field.id] !== 'RentedOut'
                );
                if(invalidIndex !== -1){
                    field.error = `Invalid state (${this.state.csvFile.data[invalidIndex][field.id]}) in line ${invalidIndex + 1}`;
                    console.error(field.error);
                }
            }
        }

        if(this.state.fields.some(e => e.error !== undefined)){
            this.setState({
                fields: this.state.fields
            });
            return false;
        }

        return true;
    }

    private renderErrors(): React.ReactNode {
        let errors: string[] = [];

        if(this.state.error !== undefined){
            errors.push(this.state.error);
        }

        this.state.fields.forEach(field => {
            if(field.error !== undefined){
                errors.push(field.error);
            }
        });

        for (let i = 0; i < Math.min(this.state.errors.length, 10); i++) {
            const error = this.state.errors[i];

            if (error.errors) {
                for (let j = 0; j < error.errors.length; j++) {
                    errors.push(error.errors[j]);
                }
            }

            if (error.validationErrors) {
                const keys = Object.keys(error.validationErrors);
                for (let j = 0; j < keys.length; j++) {
                    errors.push(error.validationErrors[keys[j]][0]);
                }
            }
        }

        if(errors.length === 0){
            return null;
        }

        return <MessageBar messageBarType={MessageBarType.error}>{errors.map(e => <span key={e}>{e}<br /></span>)}</MessageBar>;
    }

    private renderFields(): React.ReactNode {
        if(this.state.csvFile === undefined){
            return null;
        }

        let targets: IDropdownOption[] = [
            { key: 'maskid', text: 'Mask ID' },
            { key: 'state', text: 'State' },
            { key: 'url', text: 'Url' },
            { key: 'sections', text: 'Sections' },
            { key: 'images', text: 'Images' },
            { key: 'floorplans', text: 'Floorplans' },
            { key: 'preview', text: 'Preview' },
            { key: 'materiallist', text: 'Material list' },
            { key: 'datasheet', text: 'Data sheet' },
            ...this.props.fields.map(e => { return { key: e.id.toString(), text: e.name } })]

        return this.state.fields.map(field => <StackItem key={field.id}>
            <Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign='end'>
                <StackItem grow={2}>
                    <Dropdown label={field.id} selectedKey={field.target} options={targets} onChange={(e, val) => {if(val){ this._setFieldTarget(field.id, val.key.toString()); }}} />
                </StackItem>
                <StackItem>
                    <Checkbox checked={this.state.identifierField === field.id} onChange={(e, checked) => {if(checked){this._setIdentifierField(field.id)}}} label='Identifier' />
                </StackItem>
            </Stack>
        </StackItem>);
    }

    @boundMethod
    private _setFieldTarget(fieldID: string, target: string){
        this.setState({
            fields: this.state.fields.map(e => e.id === fieldID ? {id: e.id, target: target, error: e.error} : e)
        });
    }

    @boundMethod
    private _setIdentifierField(fieldID: string){
        this.setState({
            identifierField: fieldID
        });
    }

    @boundMethod
    private _readFile(file: File): void {
        parse<{[id: string]: string}>(file, {
            header: true,
            delimitersToGuess: [';', ','],
            skipEmptyLines: true,
            encoding: 'utf-8',
            complete: (result) => {
                if(result.meta.fields){
                    this.setState({
                        csvFile: result,
                        fields: result.meta.fields.map(e => {return {id: e, target: '', error: undefined}}),
                        identifierField: ''
                    });
                }
            }
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.units, actionCreators)(EditUnit);