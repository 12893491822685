import { AppThunkAction } from '.';
import { CaseApi } from '../api';
import { ApiError } from '../types/ApiError';
import { createReducer, IActionType, IBaseAction } from './BaseStore';

export const ActionType: IActionType = {
    NAMESPACE: 'cases',
    REQUEST: 'cases/fetch',
    RECEIVE_FAIL: 'cases/fetchFail',
    RECEIVE_SUCCESS: 'cases/fetchSuccess',
    REQUEST_DETAILS: 'cases/fetchSingle',
    RECEIVE_DETAILS_FAIL: 'cases/fetchSingleFail',
    RECEIVE_DETAILS_SUCCESS: 'cases/fetchSingleSuccess',
    ADD: 'cases/add',
    ADD_FAIL: 'cases/add_fail',
    ADD_SUCCESS: 'cases/add_success',
    EDIT: 'cases/edit',
    EDIT_FAIL: 'cases/edit_fail',
    EDIT_SUCCESS: 'cases/edit_success',
    DELETE: 'cases/delete',
    DELETE_FAIL: 'cases/deleteFail',
    DELETE_SUCCESS: 'cases/deleteSuccess',
    RESET_STATE: 'cases/resetState'
};

export interface ICase {
    id: number,
    name: string;
    logoID?: number;
    createdAt: string;
    googleAnalyticsID?: string;
    organizationID: number;
    designID: number;
    unitFooterDetails?: string;
    fixedMobileMenu?: boolean;
    sectionMenuItemAmount? : number;
    viewerHeight? : number;
}

export interface ICaseCreateDTO {
    name: string;
    logoID?: number;
    googleAnalyticsID?: string;
    unitFooterDetails?: string;
}

export interface ICaseEditDTO {
    name: string;
    logoID?: number;
    googleAnalyticsID?: string;
    designID?: number;
    unitFooterDetails?: string;
    fixedMobileMenu?: boolean;
    sectionMenuItemAmount? : number;
    viewerHeight? : number;
}

export const reducer = createReducer<ICase>(ActionType);

export const actionCreators = {
    requestCases: (organizationID: number): AppThunkAction<IBaseAction<ICase>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST });

        CaseApi.getCasesAsync(organizationID).then(data => {
            dispatch({ type: ActionType.RECEIVE_SUCCESS, items: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_FAIL, error: reason });
        });
    },

    requestCase: (organizationID: number, id: number): AppThunkAction<IBaseAction<ICase>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST_DETAILS });

        CaseApi.getCaseAsync(organizationID, id).then(data => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_SUCCESS, item: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_FAIL, error: reason });
        });
    },

    addCase: (organizationID: number, item: ICaseCreateDTO, callback: (c: ICase) => void = () => { }): AppThunkAction<IBaseAction<ICase>> => (dispatch, getState) => {
        dispatch({ type: ActionType.ADD });

        CaseApi.createCaseAsync(organizationID, item).then(data => {
            dispatch({ type: ActionType.ADD_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.ADD_FAIL, error: reason });
        });
    },

    editCase: (organizationID: number, angle: ICase, item: ICaseEditDTO, callback: (c: ICase) => void = () => { }): AppThunkAction<IBaseAction<ICase>> => (dispatch, getState) => {
        dispatch({ type: ActionType.EDIT });

        CaseApi.editCaseAsync(organizationID, angle.id, item).then(data => {
            dispatch({ type: ActionType.EDIT_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.EDIT_FAIL, error: reason });
        });
    },

    deleteCase: (organizationID: number, angle: ICase, callback: () => void = () => { }): AppThunkAction<IBaseAction<ICase>> => (dispatch, getState) => {
        dispatch({ type: ActionType.DELETE });

        CaseApi.deleteCaseAsync(organizationID, angle.id).then(data => {
            dispatch({ type: ActionType.DELETE_SUCCESS, item: angle });
            callback();
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.DELETE_FAIL, error: reason });
        });
    }
};