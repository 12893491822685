import { ICaseDesign, ICaseDesignEditDTO } from '../store/CaseDesign';
import { BaseService } from './base.service';

class CseDesignService extends BaseService {
    private static _angleService: CseDesignService;

    private constructor() {
        super();
    }

    public static get Instance(): CseDesignService {
        return this._angleService || (this._angleService = new this());
    }
    public async getCaseDesignAsync(organizationID: number, caseID: number): Promise<ICaseDesign> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/design`);
        return data as ICaseDesign;
    }

    public async editCaseDesignAsync(organizationID: number, caseID: number, angle: ICaseDesignEditDTO): Promise<ICaseDesign> {
        const { data } = await this.$http.post(`organizations/${organizationID}/cases/${caseID}/design`, angle);
        return data;
    }
}

export const CaseDesignApi = CseDesignService.Instance;