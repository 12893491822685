import {
  DetailsList,
  FontIcon,
  IColumn,
  mergeStyles,
  SelectionMode,
  TextField,
} from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import filesize from 'filesize';
import {
  DefaultButton,
  Image,
  ImageFit,
  ISelection,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Selection,
  Stack,
  StackItem,
} from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';

import ArrayEquals from '../../helpers/ArrayEquals';
import { ApplicationState } from '../../store';
import { actionCreators, IMediaFile, reducer } from '../../store/MediaFiles';
import MediaThumbnail from './MediaThumbnail';
import { TimeAgo } from './TimeAgo';

type MediaPickerProps = ReturnType<typeof reducer> & typeof actionCreators & {
  value: number[];
  organizationID: number;
  caseID: number;
  onChange: (value: number[]) => void;
  label?: string;
  multiple?: boolean;
};

type MediaPickerState = {
  editorOpen: boolean;
  newFiles: FileList | undefined;
  items: IMediaFile[];
  filter: string | undefined;
  value: number[];
};

const iconClass = mergeStyles({
  fontSize: 30,
  height: 50,
  width: 50
});


class MediaPicker extends React.Component<MediaPickerProps, MediaPickerState> {

  private _selection: ISelection;

  constructor(props: MediaPickerProps) {
    super(props);

    this.state = {
      editorOpen: false,
      newFiles: undefined,
      items: [],
      filter: '',
      value: []
    };

    this._selection = new Selection({
      getKey: (e: any) => e.id.toString(),
      selectionMode: this.props.multiple ? SelectionMode.multiple : SelectionMode.single,
      onSelectionChanged: () => {
        if (this._selection.getItems().length > 0) {
          this.setState({
            // @ts-ignore
            value: this._selection.getSelection().map(e => e.id)
          });
        }
      },
    });
  }

  @boundMethod
  private _openPicker() {
    this.setState({
      editorOpen: true
    });

    if (this.props.items.length === 0) {
      this.props.requestMediaFiles(this.props.organizationID, this.props.caseID);
    } else {
      this._selection.setItems(this.props.items as any[], false);
      this._selection.setAllSelected(false);
      this.props.value.map(e => this._selection.setKeySelected(e.toString(), true, false));
      this.setState({
        items: this.props.items,
        value: this.props.value
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: MediaPickerProps) {
    if (nextProps.items.length !== this.props.items.length) {
      this._selection.setItems(nextProps.items as any[], false);
      this._selection.setAllSelected(false);
      nextProps.value.map(e => this._selection.setKeySelected(e.toString(), true, false));
      this.setState({
        items: this.props.items,
        value: this.props.value
      });
    }

    if (!ArrayEquals(nextProps.value, this.props.value)) {
      this._selection.setAllSelected(false);
      nextProps.value.map(e => this._selection.setKeySelected(e.toString(), true, false));
    }
  }

  _filterItems(filter: string | undefined): IMediaFile[] {
    let result = this.props.items;
    if (filter)
      result = result.filter(e => e.filename.toLocaleLowerCase().includes(filter));
    this._selection.setItems(result as any[], false);
    this._selection.setAllSelected(false);
    this.state.value.map(e => this._selection.setKeySelected(e.toString(), true, false));
    return result;
  }

  render() {
    return (
      <>
        <Label>{this.props.label}</Label>
        <Stack horizontal verticalAlign='end' tokens={{ childrenGap: 10 }}>
          <StackItem>
            {this.props.value.map(e => <MediaThumbnail key={e} organizationID={this.props.organizationID} caseID={this.props.caseID} mediaID={e} />)}
          </StackItem>
          <StackItem>
            <DefaultButton iconProps={{ iconName: 'ImageSearch' }} onClick={this._openPicker} />
          </StackItem>
        </Stack>

        <Panel isOpen={this.state.editorOpen} onDismiss={() => { this.setState({ editorOpen: false }); }} type={PanelType.medium} headerText='Pick media' onRenderFooterContent={() => <div>
          <PrimaryButton onClick={this._select} text='Select' />
          <DefaultButton onClick={this._closePicker}>Cancel</DefaultButton>
        </div>}>
          <Stack tokens={{ childrenGap: 20 }}>

            <Stack horizontal verticalAlign='end' tokens={{ childrenGap: 10 }}>
              <StackItem>
                <input type='file' multiple onChange={(e) => {
                  if (e.target.files) {
                    this.setState({ newFiles: e.target.files });
                  }
                }} />
              </StackItem>
              <StackItem>
                <DefaultButton iconProps={{ iconName: 'Upload' }} onClick={this._addMediaFile} />
              </StackItem>

              <StackItem align='end'>
                <TextField iconProps={{ iconName: 'Filter' }} value={this.state.filter} onChange={(i, val) => {
                  this.setState({
                    items: this._filterItems(val),
                    filter: val
                  });
                }} />
              </StackItem>
            </Stack>


            <DetailsList
              compact
              items={this.state.items}
              selection={this._selection}
              selectionPreservedOnEmptyClick={true}
              setKey='id'
              columns={[{
                key: 'thumbnail',
                minWidth: 60,
                name: 'Thumbnail',
                onRender: (item: IMediaFile) => {
                  if (item.hasThumbnail) {
                    return <img alt={item.filename} width={50} src={`${process.env.REACT_APP_API_HOST}/v1/organizations/${this.props.organizationID}/cases/${this.props.caseID}/media/${item.id}/thumbnail`} />
                  }

                  if (item.mimeType.startsWith('image')) {
                    return <FontIcon iconName="FileImage" className={iconClass} />
                  }
                  if (item.mimeType.startsWith('video')) {
                    return <FontIcon iconName="MyMoviesTV" className={iconClass} />
                  }
                  if (item.mimeType.indexOf('pdf') !== -1) {
                    return <FontIcon iconName="PDF" className={iconClass} />
                  }

                  return <FontIcon iconName="PDF" className={iconClass} />
                }
              }, {
                key: 'filename',
                minWidth: 250,
                name: 'Filename'
              }, {
                key: 'filesize',
                minWidth: 100,
                name: 'Size'
              }, {
                key: 'date',
                minWidth: 100,
                name: 'Uploaded'
              }]}
              onRenderItemColumn={this._renderItemColumn}
            />
          </Stack>
        </Panel>
      </>
    );
  }

  @boundMethod
  private _renderItemColumn(item: IMediaFile, index: number | undefined, column: IColumn | undefined) {
    if (column === undefined) {
      return null;
    }

    switch (column.key) {
      case 'thumbnail':
        return <Image src={`${process.env.REACT_APP_API_HOST}/v1/organizations/${this.props.organizationID}/cases/${this.props.caseID}/media/${item.id}/thumbnail`} width={50} height={50} imageFit={ImageFit.cover} />;

      case 'filename':
        return <span title={item.filename}>{item.filename}</span>;

      case 'filesize':
        return <span>
          {filesize(item.fileSize)}<br />
          {item.width === undefined ? null : `(${item.width} x ${item.height})`}
        </span>;

      case 'date':
        return <TimeAgo time={item.createdAt} />;

      default:
        return <span></span>;
    }
  }

  @boundMethod
  private _addMediaFile(): void {
    if (this.state.newFiles) {
      for (let i = 0; i < this.state.newFiles.length; i++) {
        this.props.createMediaFile(this.props.organizationID, this.props.caseID, this.state.newFiles[i]);
      }
    }
  }

  @boundMethod
  private _select() {
    let selection = this._selection.getSelection() as IMediaFile[];
    this.onValueChange(selection.map(e => e.id));
    this.setState({
      editorOpen: false
    });
  }

  @boundMethod
  private _closePicker() {
    this.setState({
      editorOpen: false
    });
  }

  @boundMethod
  private onValueChange(value: number[]): void {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }
}

export default connect((state: ApplicationState) => state.mediaFiles, actionCreators)(MediaPicker);