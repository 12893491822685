import { mergeStyleSets } from '@uifabric/styling';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import Footer from './Footer';
import NavBar from './NavBar';

const classNames = mergeStyleSets({
    container: {
        margin: 'auto',
        padding: '0 20px'
    }
});

class AppLayout extends React.Component<{ isAuthenticated: boolean; }> {
    render() {
        return (
            this.props.isAuthenticated ?
                <React.Fragment>
                    <NavBar />
                    <div className={classNames.container}>
                        {this.props.children}
                    </div>
                    <Footer />
                </React.Fragment> :
                <React.Fragment>
                    {this.props.children}
                </React.Fragment>
        );
    }
}

// Map only necessary IApplicationState to NavBar props
const mapStateToProps = (state: ApplicationState) => {
    return {
        isAuthenticated: state.auth!.isAuthenticated
    };
};

// Wire up the React component to the Redux store
export default connect(mapStateToProps)(AppLayout);