import { FontIcon, Image, ImageFit, mergeStyles, Spinner, SpinnerSize } from '@fluentui/react';
import React, { useState } from 'react';
import { MediaFileApi } from '../../api/MediaFiles.service';
import { IMediaFile } from '../../store/MediaFiles';

type MediaThumbnailProps = {
    organizationID: number;
    caseID: number;
    mediaID: number;
};

const iconClass = mergeStyles({
    fontSize: 30,
    height: 50,
    width: 50,
    textAlign: 'center'
  });

const MediaThumbnail: React.FC<MediaThumbnailProps> = (props) => {
    const [data, setData] = useState(undefined as IMediaFile |  undefined);

    React.useEffect(() => {
        setData(undefined);
        MediaFileApi.getMediaFileAsync(props.organizationID, props.caseID, props.mediaID).then(data => {
            setData(data);
        });
    }, [props.organizationID, props.caseID, props.mediaID]);

    if(data === undefined){
        return <Spinner size={SpinnerSize.large} />;
    }

    if(data.hasThumbnail){
        return <Image title={data.filename} alt={data.filename} styles={{root: {display: 'inline-block'}}} src={`${process.env.REACT_APP_API_HOST}/v1/organizations/${props.organizationID}/cases/${props.caseID}/media/${props.mediaID}/thumbnail`} width={50} height={50} imageFit={ImageFit.cover} />
    }

    if(data.mimeType.startsWith('image')){
        return <FontIcon title={data.filename} iconName="FileImage" className={iconClass} />
    }
    if(data.mimeType.startsWith('video')){
        return <FontIcon title={data.filename} iconName="MyMoviesTV" className={iconClass} />
    }
    if(data.mimeType.indexOf('pdf') !== -1){
        return <FontIcon title={data.filename} iconName="PDF" className={iconClass} />
    }
    
    return <FontIcon title={data.filename} iconName="PDF" className={iconClass} />
};

export default MediaThumbnail;