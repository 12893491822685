import { ISelection, SelectionMode } from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import FileSaver from 'file-saver';
import {
  CommandBar,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Selection,
  Stack,
  StackItem,
} from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { DataTable, ErrorMessage } from '../../components/controls';
import { ApplicationState } from '../../store';
import * as Fields from '../../store/Fields';
import * as MediaFiles from '../../store/MediaFiles';
import * as Sections from '../../store/Sections';
import * as Units from '../../store/Units';
import CSVImporter from './CSVImporter';
import EditUnit from './EditUnit';
import NewUnit from './NewUnit';

type UnitsProps = {
    units: ReturnType<typeof Units.reducer>; 
    fields: ReturnType<typeof Fields.reducer>; 
    sections: ReturnType<typeof Sections.reducer>;
    mediaFiles: ReturnType<typeof MediaFiles.reducer>;
} & typeof Units.actionCreators & typeof Fields.actionCreators & typeof Sections.actionCreators & typeof MediaFiles.actionCreators & RouteComponentProps<{ organizationID: string; caseID: string }>;

type UnitsState = {
    showCreatePanel: boolean;
    selectedUnit?: Units.IUnit;
    deleteDialogVisible: boolean;
    deleteSelectionDialogVisible: boolean;
    editDialogVisible: boolean;
    csvImporterVisible: boolean;
    itemsSelected: number;
};

class UnitsOverview extends React.Component<UnitsProps, UnitsState> {
    private _selection: ISelection;

    constructor(props: UnitsProps) {
        super(props);

        this._selection = new Selection({
            getKey: (e:any) => e.id.toString(),
            selectionMode: SelectionMode.multiple,
            onSelectionChanged: () => {
                this.setState({
                    itemsSelected: this._selection.count
                });
            },
        });

        this.state = {
            showCreatePanel: false,
            selectedUnit: undefined,
            deleteDialogVisible: false,
            editDialogVisible: false,
            csvImporterVisible: false,
            itemsSelected: 0,
            deleteSelectionDialogVisible: false
        };
    }

    public componentDidMount(): void {
        this.props.requestUnits(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID));
        this.props.requestFields(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID));
        this.props.requestSections(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID));
        this.props.requestMediaFiles(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID));
    }

    public render(): React.ReactNode {
        if(this.props.fields.isLoading){
            return null;
        }

        return (
            <>
                <NewUnit 
                    organizationID={parseInt(this.props.match.params.organizationID)} 
                    caseID={parseInt(this.props.match.params.caseID)} 
                    isOpen={this.state.showCreatePanel} 
                    onDismissed={this._hideCreatePanel} 
                    onAdded={this._hideCreatePanel}
                    fields={this.props.fields.items}
                    sections={this.props.sections.items} />
                    
                <EditUnit 
                    organizationID={parseInt(this.props.match.params.organizationID)} 
                    caseID={parseInt(this.props.match.params.caseID)} 
                    isOpen={this.state.editDialogVisible} 
                    onDismissed={this._hideEditPanel} 
                    onSaved={this._hideEditPanel}
                    fields={this.props.fields.items}
                    sections={this.props.sections.items}
                    unit={this.state.selectedUnit} />

                <CSVImporter 
                    organizationID={parseInt(this.props.match.params.organizationID)} 
                    caseID={parseInt(this.props.match.params.caseID)} 
                    onSaved={() => this.setState({csvImporterVisible: false})} 
                    onDismissed={() => this.setState({csvImporterVisible: false})} 
                    units={this.props.units.items} 
                    fields={this.props.fields.items}
                    sections={this.props.sections.items}
                    mediaFiles={this.props.mediaFiles.items}
                    isOpen={this.state.csvImporterVisible} />
                
                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                        <CommandBar
                            items={[
                                {
                                    key: 'Add',
                                    name: 'Create new unit',
                                    iconProps: {
                                        iconName: 'Add'
                                    },
                                    onClick: () => this._showCreatePanel()
                                },
                                {
                                    key: 'import',
                                    name: 'Import CSV',
                                    iconProps: {
                                        iconName: 'Table'
                                    },
                                    onClick: () => this.setState({csvImporterVisible: true})
                                },
                                {
                                    key: 'export',
                                    name: 'Export CSV',
                                    iconProps: {
                                        iconName: 'Table'
                                    },
                                    onClick: () => this._exportCSV()
                                },
                                {
                                    key: 'delete',
                                    name: 'Delete selection',
                                    disabled: this.state.itemsSelected === 0,
                                    iconProps: {
                                        iconName: 'Delete'
                                    },
                                    onClick: () => this._promptForDeleteSelection()
                                }
                            ]}
                        />

                        
                    </StackItem>

                    <StackItem>
                        <ErrorMessage error={this.props.units.loadError} />

                        <DataTable<Units.IUnit> paginated fieldValues={this.props.fields.items} selection={this._selection} items={this.props.units.items} isLoading={this.props.units.isLoading} onEdit={this._showEditPanel} onDelete={this._promptForDelete} coloumns={[
                            {
                                key: 'id',
                                name: 'ID',
                                fieldName: 'id',
                                minWidth: 25,
                                maxWidth: 100,
                                isResizable: true,
                                isSortable: true,
                            },
                            {
                                key: 'maskID',
                                name: 'Mask ID',
                                fieldName: 'maskID',
                                minWidth: 25,
                                maxWidth: 100,
                                isResizable: true,
                                isSortable: true,
                            },
                            {
                                key: 'state',
                                name: 'State',
                                fieldName: 'state',
                                minWidth: 50,
                                maxWidth: 200,
                                isResizable: true,
                                isSortable: true,
                            }
                        ].concat(this.props.fields.items.map(e => {return {
                            fieldName: `custom-${e.name}`,
                            isResizable: true,
                            isSortable: true,
                            key: e.id.toString(),
                            maxWidth: 200,
                            minWidth: 30,
                            name: e.name,
                            onRender: (item: Units.IUnit) => {
                                return item.fieldValues[e.id] || '';
                            }
                        }}))} />
                    </StackItem>

                </Stack>

                <Dialog
                    hidden={!this.state.deleteDialogVisible}
                    onDismiss={this._hideDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete unit',
                        subText: 'This will remove the unit permantly'
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <ErrorMessage error={this.props.units.deleteError} />
                    <DialogFooter>
                        <PrimaryButton onClick={this._delete} text='Delete' />
                        <DefaultButton onClick={this._hideDialog} text='Cancel' />
                    </DialogFooter>
                </Dialog>

                <Dialog
                    hidden={!this.state.deleteSelectionDialogVisible}
                    onDismiss={() => {this.setState({deleteSelectionDialogVisible: false})}}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete units',
                        subText: `This will remove the ${this.state.itemsSelected} selected units permantly`
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <ErrorMessage error={this.props.units.deleteError} />
                    <DialogFooter>
                        <PrimaryButton onClick={this._deleteSelection} text='Delete' />
                        <DefaultButton onClick={() => {this.setState({deleteSelectionDialogVisible: false})}} text='Cancel' />
                    </DialogFooter>
                </Dialog>
            </>
        );
    }

    @boundMethod
    private _promptForDeleteSelection() {
        this.setState({
            deleteSelectionDialogVisible: true
        });
    }

    @boundMethod
    private _deleteSelection(): void {
        let selection = this._selection.getSelection() as Units.IUnit[];

        for (let i = 0; i < selection.length; i++) {
            const element = selection[i];
            this.props.deleteUnit(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID),element, () => { this.setState({deleteSelectionDialogVisible: false}); });
        }
    }

    @boundMethod
    private _promptForDelete(unit: Units.IUnit) {
        this.setState({
            selectedUnit: unit,
            deleteDialogVisible: true
        });
    }

    @boundMethod
    private _delete(): void {
        if(this.state.selectedUnit){
            this.props.deleteUnit(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID), this.state.selectedUnit, () => { this._hideDialog(); });
        }
    }

    @boundMethod
    private _hideDialog() {
        this.setState({
            deleteDialogVisible: false
        });
    }

    @boundMethod
    private _showCreatePanel() {
        this.setState({ showCreatePanel: true });
    }

    @boundMethod
    private _hideCreatePanel() {
        this.setState({
            showCreatePanel: false,
        });
    }

    @boundMethod
    private _showEditPanel(unit: Units.IUnit) {
        this.setState({
            editDialogVisible: true,
            selectedUnit: unit
        });
    }

    @boundMethod
    private _hideEditPanel() {
        this.setState({
            editDialogVisible: false,
            selectedUnit: undefined
        });
    }

    private _exportCSV() {

        let content = "\"id\",\"maskID\",\"state\",\"dataSheet\",\"materialList\",\"preview\",\"images\",\"floorplans\",\"floorplans_pdf\"";

        for (var i = 0; i < this.props.fields.items.length; i++) {
            content += ",\"" + this.props.fields.items[i].name + "\"";
        }

        let getfile = (id: number | undefined) => {
            if (id === undefined) {
                return "";
            }

            var file = this.props.mediaFiles.items.find(e => e.id === id);

            if (file) {
                return file.filename;
            }

            return "";
        }

        let getfiles = (ids: number[], type: 'Image' | 'Pdf' | 'Video') => {

            var files = this.props.mediaFiles.items.filter(e => ids.indexOf(e.id) !== -1 && e.mediaType === type);

            return files.map(e => e.filename).join(", ");
        }


        for (var j = 0; j < this.props.units.items.length; j++) {
            const unit = this.props.units.items[j];
            content += "\r\n";

            content += "\"" + unit.id + "\"";
            content += ",\"" + (unit.maskID || '') + "\"";
            content += ",\"" + unit.state + "\"";
            content += ",\"" + getfile(unit.dataSheetID) + "\"";
            content += ",\"" + getfile(unit.materialListID) + "\"";
            content += ",\"" + getfile(unit.previewID) + "\"";
            content += ",\"" + getfiles(unit.images, 'Image') + "\"";
            content += ",\"" + getfiles(unit.floorplans, 'Image') + "\"";
            content += ",\"" + getfiles(unit.floorplans, 'Pdf') + "\"";


            for (var k = 0; k < this.props.fields.items.length; k++) {
                content += ",\"" + unit.fieldValues[this.props.fields.items[k].id] + "\"";
            }
        }

        var blob = new Blob(["\ufeff", content], {
            type: 'text/plain'
        });

        FileSaver.saveAs(blob, "units.csv")

    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state, {...Units.actionCreators, ...Fields.actionCreators, ...Sections.actionCreators, ...MediaFiles.actionCreators})(UnitsOverview);