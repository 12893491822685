import { IUser, IUserCreateDTO, IUserEditDTO } from '../store/Users';
import { BaseService } from './base.service';

class UserService extends BaseService {
    private static _angleService: UserService;

    private constructor() {
        super();
    }

    public static get Instance(): UserService {
        return this._angleService || (this._angleService = new this());
    }

    public async getUsersAsync(): Promise<IUser[]> {
        const { data } = await this.$http.get(`users`);
        return data as IUser[];
    }

    public async getUserAsync(id: number): Promise<IUser> {
        const { data } = await this.$http.get(`users/${id}`);
        return data as IUser;
    }

    public async createUserAsync(angle: IUserCreateDTO): Promise<IUser> {
        const { data } = await this.$http.put(`users`, angle);
        return data;
    }

    public async editUserAsync(id: number, angle: IUserEditDTO): Promise<IUser> {
        const { data } = await this.$http.post(`users/${id}`, angle);
        return data;
    }

    public async deleteUserAsync(id: number): Promise<number> {
        await this.$http.delete(`users/${id}`);
        return id;
    }
}

export const UserApi = UserService.Instance;