import { boundMethod } from 'autobind-decorator';
import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack, StackItem } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import ContentWithoutSideMenu from '../../components/ContentWithoutSideMenu';
import { DataTable, ErrorMessage } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, IUser, reducer } from '../../store/Users';

type UsersProps = ReturnType<typeof reducer> & typeof actionCreators & RouteComponentProps<{}>;

type UsersState = {
    selectedUser?: IUser;
    deleteDialogVisible: boolean;
};

class Maintainers extends React.Component<UsersProps, UsersState> {

    constructor(props: UsersProps) {
        super(props);

        this.state = {
            selectedUser: undefined,
            deleteDialogVisible: false
        };
    }

    public componentDidMount(): void {
        this.props.requestUsers();
    }

    public render(): React.ReactNode {
        return (
            <ContentWithoutSideMenu>
                <Stack tokens={{ childrenGap: 20 }}>

                    <StackItem>
                        <ErrorMessage error={this.props.loadError} />

                        <DataTable<IUser> paginated items={this.props.items.filter(e => e.userType === 'Maintainer')} isLoading={this.props.isLoading} onDelete={this._promptForDelete} coloumns={[
                            {
                                key: 'email',
                                name: 'Email',
                                fieldName: 'email',
                                minWidth: 150,
                                maxWidth: 150,
                                isResizable: true,
                                isSortable: true
                            }]} />
                    </StackItem>

                </Stack>

                <Dialog
                    hidden={!this.state.deleteDialogVisible}
                    onDismiss={this._hideDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete user',
                        subText: 'This will remove the user permanently, and they will no longer be able to log in'
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <ErrorMessage error={this.props.deleteError} />
                    <DialogFooter>
                        <PrimaryButton onClick={this._deleteUser} text='Delete' />
                        <DefaultButton onClick={this._hideDialog} text='Cancel' />
                    </DialogFooter>
                </Dialog>

            </ContentWithoutSideMenu>
        );
    }

    @boundMethod
    private _deleteUser(): void {
        if (this.state.selectedUser) {
            this.props.deleteUser(this.state.selectedUser, () => { this._hideDialog(); });
        }
    }

    @boundMethod
    private _promptForDelete(user: IUser) {
        this.setState({
            selectedUser: user,
            deleteDialogVisible: true
        });
    }

    @boundMethod
    private _hideDialog() {
        this.setState({
            deleteDialogVisible: false
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.users, actionCreators)(Maintainers);