import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import ContentWithSidemenu from '../../components/ContentWithSidemenu';
import { ApplicationState } from '../../store';
import * as Cases from '../../store/Cases';
import * as Auth from '../../store/Auth';
import MediaFiles from '../Mediafiles/MediaFiles';
import Fields from '../Fields/Fields';
import Overviews from '../Overviews/Overviews';
import Sections from '../Sections/Sections';
import Angles from '../Angles/Angles';
import Maintainers from '../Maintainers/Maintainers';
import Units from '../Units/Units';
import CaseDesign from '../CaseDesign/CaseDesign';
import Details from './Details';
import { INavLink } from '@fluentui/react';

type caseProps = {
    cases: ReturnType<typeof Cases.reducer>;
    auth: ReturnType<typeof Auth.reducer>;
} & typeof Cases.actionCreators & RouteComponentProps<{ organizationID: string, caseID: string }>;

type caseState = {

};

class Case extends React.Component<caseProps, caseState> {

    constructor(props: caseProps) {
        super(props);

        this.state = {

        };
    }

    public componentDidMount(): void {
        this.props.requestCase(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID));
    }

    UNSAFE_componentWillReceiveProps(nextProps: caseProps) {

        if (this.props.match.params.organizationID !== nextProps.match.params.organizationID || this.props.match.params.caseID !== nextProps.match.params.caseID) {
            this.props.requestCase(parseInt(nextProps.match.params.organizationID), parseInt(nextProps.match.params.caseID));
        }
    }

    public render(): React.ReactNode {

        let links:INavLink[] = [];

        links.push({
            name: 'Details',
            url: `/organizations/${this.props.match.params.organizationID}/cases/${this.props.match.params.caseID}`,
            key: 'home'
        });
        links.push({
            name: 'Units',
            url: `/organizations/${this.props.match.params.organizationID}/cases/${this.props.match.params.caseID}/units`,
            key: 'units',
        });
        
        if(this.props.auth.usertype === 'Administrator' || this.props.auth.usertype === 'SuperAdministrator' || this.props.auth.usertype === 'Editor'){
            links.push({
                name: 'Overviews',
                url: `/organizations/${this.props.match.params.organizationID}/cases/${this.props.match.params.caseID}/overviews`,
                key: 'overviews',
            });
            links.push({
                name: 'Sections',
                url: `/organizations/${this.props.match.params.organizationID}/cases/${this.props.match.params.caseID}/sections`,
                key: 'sections',
            });
            links.push({
                name: 'Fields',
                url: `/organizations/${this.props.match.params.organizationID}/cases/${this.props.match.params.caseID}/fields`,
                key: 'fields',
            });
            links.push({
                name: 'Media',
                url: `/organizations/${this.props.match.params.organizationID}/cases/${this.props.match.params.caseID}/media`,
                key: 'media',
            });
            links.push({
                name: 'Design',
                url: `/organizations/${this.props.match.params.organizationID}/cases/${this.props.match.params.caseID}/design`,
                key: 'design',
            });
            links.push({
                name: 'Maintainers',
                url: `/organizations/${this.props.match.params.organizationID}/cases/${this.props.match.params.caseID}/maintainers`,
                key: 'maintainers',
            });
        }

        return (
            <ContentWithSidemenu menu={[
                {
                    name: this.props.cases.item === undefined ? '' : this.props.cases.item.name,
                    links: links
                }
            ]}>

                <Switch>
                    <Route exact path={'/organizations/:organizationID/cases/:caseID'} component={Details} />
                    <Route path={'/organizations/:organizationID/cases/:caseID/media'} component={MediaFiles} />
                    <Route path={'/organizations/:organizationID/cases/:caseID/fields'} component={Fields} />
                    <Route path={'/organizations/:organizationID/cases/:caseID/overviews'} component={Overviews} />
                    <Route path={'/organizations/:organizationID/cases/:caseID/sections'} exact component={Sections} />
                    <Route path={'/organizations/:organizationID/cases/:caseID/sections/:sectionID'} component={Angles} />
                    <Route path={'/organizations/:organizationID/cases/:caseID/units'} component={Units} />
                    <Route path={'/organizations/:organizationID/cases/:caseID/design'} component={CaseDesign} />
                    <Route path={'/organizations/:organizationID/cases/:caseID/maintainers'} component={Maintainers} />
                </Switch>
                
            </ContentWithSidemenu>
        );
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state, Cases.actionCreators)(Case);