import { boundMethod } from 'autobind-decorator';
import { DefaultButton, Panel, PanelType, PrimaryButton, Stack, StackItem, TextField } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, IUser, IUserEditDTO, reducer } from '../../store/Users';

type EditUserProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onUserEdited: () => void;
    onDismissed: () => void;
    user?: IUser;
};

type EditUserState = {
    editUser: IUserEditDTO;
};

class EditUser extends React.Component<EditUserProps, EditUserState> {

    constructor(props: EditUserProps) {
        super(props);

        this.state = {
            editUser: {
                email: '',
                userType: 'SuperAdministrator',
                caseAccess: {}
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: EditUserProps) {
        if(nextProps.user === undefined){
            this.setState({
                editUser: {
                    email: '',
                    userType: 'SuperAdministrator',
                    caseAccess: {}
                }
            });
        }else{

            if (this.props.user === undefined || this.props.user.id !== nextProps.user.id) {
                this.setState({
                    editUser: {
                        email: nextProps.user.email,
                        userType: 'SuperAdministrator',
                        caseAccess: {}
                    }
                });
            }
        }

    }

    public render(): React.ReactNode {
        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='Edit user' onRenderFooterContent={() => <div>
                <PrimaryButton disabled={this.props.isEditing} onClick={this._editUser} style={{ marginRight: '8px' }} text='Save' />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </div>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                        <TextField label='Email' value={this.state.editUser.email} onChange={(e, value) => this.setState({ editUser: { ...this.state.editUser, email: value || '' } })} />
                        <FieldValidation error={this.props.addError} fieldname='email' />
                    </StackItem>
                </Stack>
            </Panel>
        );
    }

    @boundMethod
    private _editUser(): void {
        if (this.props.user) {
            this.props.editUser(this.props.user, this.state.editUser, () => { this.props.onUserEdited(); });
        }
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.users, actionCreators)(EditUser);