import {
  Checkbox,
  DefaultButton,
  Dropdown,
  mergeStyles,
  Pivot,
  PivotItem,
  SpinButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ErrorMessage, FieldValidation } from '../../components/controls';
import CompactColorPicker from '../../components/controls/CompactColorPicker';
import { ApplicationState } from '../../store';
import {
  actionCreators,
  ICaseDesignEditDTO,
  reducer,
} from '../../store/CaseDesign';
import {
  actionCreators as fieldsActionCreators,
  reducer as fieldsReducer,
} from '../../store/Fields';
import FieldsPicker from './FieldsPicker';
import FilterPicker from './FilterPicker';
import SortingPicker from './SortingPicker';

type CaseDesignProps = {
    caseDesign: ReturnType<typeof reducer>;
    fields: ReturnType<typeof fieldsReducer>;
} & typeof actionCreators & typeof fieldsActionCreators & RouteComponentProps<{ organizationID: string; caseID: string }>;

type CaseDesignState = {
    design: ICaseDesignEditDTO | undefined;
};

const overrideValueCellClass = mergeStyles({
    padding: '10px 20px',
    minWidth: 200,
    borderBottom: '1px solid #eee'
});

type attribute = {
    name: string;
    type: 'Bool' | 'Color' | 'Number' | 'String' | 'FontWeight' | 'Fields' | 'Filters' | 'Field' | 'Sorting',
    default: string;
    min?: number;
    max?: number;
}

const overviewAttributes: {[id:string]: attribute} = {
    'overviewHasListView': {name: 'List view', type: 'Bool', default: 'false'},
    'overviewHasMenu': {name: 'Menu', type: 'Bool', default: 'true'},
    'overviewHasRendering': {name: 'Rendering', type: 'Bool', default: 'true'},
    'overviewCombinePages': {name: 'Combine page', type: 'Bool', default: 'false'},
    'overviewMenuButtonColor': {name: 'Button color', type: 'Color', default: '#b5965f'},
    'overviewMenuButtonTextColor': {name: 'Button text color', type: 'Color', default: '#FFFFFF'},
    'overviewBackgroundcolor': {name: 'Background color', type: 'Color', default: '#404040'},
    'overviewTextcolor': {name: 'Text color', type: 'Color', default: '#FFFFFF'},
    'overviewHeaderbackgroundcolor': {name: 'Header background color', type: 'Color', default: '#FFFFFF'},
    'overviewHeaderButtoncolor': {name: 'Header button color', type: 'Color', default: '#b5965f'},
    'overviewHeaderHeight': {name: 'Header height', type: 'Number', default: '0', min: 0, max: 200},
    'overviewHeaderMenuOffset': {name: 'Header menu offset', type: 'Number', default: '0', min: 0, max: 200},
    'overviewHeaderFontSize': {name: 'Header font size', type: 'Number', default: '20', min: 1, max: 60},
    'overviewHeaderFontWeight': {name: 'Header font weight', type: 'FontWeight', default: 'normal'},
    'overviewFontSize': {name: 'font size', type: 'Number', default: '18', min: 1, max:60},
    'overviewFontWeight': {name: 'font weight', type: 'FontWeight', default: '300'}
}

const sectionAttributes: {[id:string]: attribute} = {
    'buildingIsFullwidth': {name: 'Full width', type: 'Bool', default: 'false'},
    'buildingHasRendering': {name: 'Show rendering', type: 'Bool', default: 'true'},
    'buildingCombinePages': {name: 'Combine page', type: 'Bool', default: 'false'},
    'buildingBackgroundcolor': {name: 'Background color', type: 'Color', default: '#404040'},
    'buildingHeaderbackgroundcolor': {name: 'Header background color', type: 'Color', default: '#FFFFFF'},
    'buildingHeaderButtoncolor': {name: 'Header Button color', type: 'Color', default: '#b5965f'},
    'buildingHeaderHeight': {name: 'Header height', type: 'Number', default: '100'},
    'buildingHeaderMenuOffset': {name: 'Header Menu Offset', type: 'Number', default: '75'},
    'buildingMenuButtonColor': {name: 'Menu Button Color', type: 'Color', default: '#b5965f'},
    'buildingMenuButtonTextColor': {name: 'Menu Button Text Color', type: 'Color', default: '#FFFFFF'},
    'buildingChangeAngleBesideMenu': {name: 'Change Angle Beside Menu', type: 'Bool', default: 'false'},
    'buildingChangeangleShadowSize': {name: 'Change angle Shadow Size', type: 'Number', default: '8'},
    'buildingChangeangleSize': {name: 'Change angle Size', type: 'Number', default: '200'},
    'buildingFontSize': {name: 'Font Size', type: 'Number', default: '14', min: 1, max: 60},
    'buildingFontWeight': {name: 'Font Weight', type: 'FontWeight', default: '400'},
    'buildingHeaderFontSize': {name: 'Header Font Size', type: 'Number', default: '20', min: 1, max: 60},
    'buildingHeaderFontWeight': {name: 'Header Font Weight', type: 'FontWeight', default: '400'},
    'buildingChangeangleFontSize': {name: 'Change angle Font Size', type: 'Number', default: '16', min: 1, max: 60},
    'buildingChangeangleFontWeight': {name: 'Change angle Font Weight', type: 'FontWeight', default: '300'},
    'buildingUnitZoneColor': {name: 'Unit Zone Color', type: 'Color', default: '#FFFFFF'},
}

const popupAttributes: {[id:string]: attribute} = {
    'popupShadowSize': {name: 'Shadow Size', type: 'Number', default: '40'},
    'popupShadowColor': {name: 'Shadow Color', type: 'Color', default: '#000'},
    'popupShadowOffsetX': {name: 'Shadow Offset X', type: 'Number', default: '0'},
    'popupShadowOffsetY': {name: 'Shadow Offset Y', type: 'Number', default: '0'},
    'popupBackgroundColor': {name: 'Background Color', type: 'Color', default: '#FFFFFF'},
    'popupTextColor': {name: 'Text Color', type: 'Color', default: '#404040'},
    'popupHeaderColor': {name: 'Header Color', type: 'Color', default: '#404040'},
    'popupCTAColor': {name: 'CTA Color', type: 'Color', default: '#b5965f'},
    'popupFontSize': {name: 'Font Size', type: 'Number', default: '16', min: 1, max: 60},
    'popupFontWeight': {name: 'Font Weight', type: 'FontWeight', default: '300'},
    'popupHeaderFontSize': {name: 'Header Font Size', type: 'Number', default: '40', min: 1, max: 60},
    'popupHeaderFontWeight': {name: 'Header Font Weight', type: 'FontWeight', default: '100'},
    'popupCTAFontSize': {name: 'CTA Font Size', type: 'Number', default: '16', min: 1, max: 60},
    'popupCTAFontWeight': {name: 'CTA Font Weight', type: 'FontWeight', default: '700'},
    'popupWidth': {name: 'Width', type: 'Number', default: '285', min: 250, max: 500},
    'popupFields': { name: 'Fields', type: 'Fields', default: '[]' },
    'popupTitleField': { name: 'Popup title field', type: 'Field', default: '' },
}

const listAttributes: {[id:string]: attribute} = {
    'listDropdownColor': {name: 'Dropdown Color', type: 'Color', default: '#b5965f'},
    'listDropdownTextColor': {name: 'Dropdown Text Color', type: 'Color', default: '#FFFFFF'},
    'listStatusColor': {name: 'Status Color', type: 'Color', default: '#b5965f'},
    'listStatusFontSize': {name: 'Header Font Size', type: 'Number', default: '20', min: 1, max: 60},
    'listStatusFontWeight': {name: 'Header Font Weight', type: 'FontWeight', default: 'normal'},
    'listDropdownFontSize': {name: 'Dropdown Font Size', type: 'Number', default: '16', min: 1, max: 60},
    'listDropdownFontWeight': {name: 'Dropdown Font Weight', type: 'FontWeight', default: 'normal'},
    'listHeaderTextColor': {name: 'Header Text Color', type: 'Color', default: '#FFFFFF'},
    'listHeaderFontSize': {name: 'Header font size', type: 'Number', default: '18', min: 1, max: 60},
    'listHeaderFontWeight': {name: 'Header font weight', type: 'FontWeight', default: '#FFFFFF'},
    'listHeaderBackgroundColor': {name: 'Header background Color', type: 'Color', default: '#FFFFFF'},
    'listTextColor': {name: 'Text Color', type: 'Color', default: '#FFFFFF'},
    'listFontSize': {name: 'Font Size', type: 'Number', default: '18', min: 1, max: 60},
    'listFontWeight': {name: 'Font Weight', type: 'FontWeight', default: 'normal'},
    'listItemEvenBackgroundColor': {name: 'Even item background Color', type: 'Color', default: '#000000'},
    'listItemOddBackgroundColor': {name: 'Odd item background Color', type: 'Color', default: '#000000'},
    'listItemHoverBackgroundColor': {name: 'Item hover background Color', type: 'Color', default: '#000000'},
    'listLineColor': {name: 'Line Color', type: 'Color', default: '#888'},
    'listFields': {name: 'Fields', type: 'Fields', default: '[]'},
    'listFilters': {name: 'Filters', type: 'Filters', default: '[]'},
    'listSorting': {name: 'Sorting', type: 'Sorting', default: 'id:asc'},
}

const detailsAttributes: {[id:string]: attribute} = {
    'detailsBackgroundcolor': {name: 'Background color', type: 'Color', default: '#404040'},
    'detailsHeaderbackgroundcolor': {name: 'Header background color', type: 'Color', default: '#FFFFFF'},
    'detailsTextColor': {name: 'Text Color', type: 'Color', default: '#404040'},
    'detailsLinkTextColor': {name: 'Link Text Color', type: 'Color', default: '#007bff'},
    'detailsStatusColor': {name: 'Status Color', type: 'Color', default: '#b5965f'},
    'detailsButtonColor': {name: 'Button Color', type: 'Color', default: '#b5965f'},
    'detailsFontSize': {name: 'Font Size', type: 'Number', default: '16'},
    'detailsFontWeight': {name: 'Font Weight', type: 'FontWeight', default: 'normal'},
    'detailsHeaderFontSize': {name: 'Header Font Size', type: 'Number', default: '60', min: 1, max: 60},
    'detailsHeaderFontWeight': {name: 'Header Font Weight', type: 'FontWeight', default: 'normal'},
    'detailsStatusFontSize': {name: 'Status Font Size', type: 'Number', default: '20', min: 1, max: 60},
    'detailsStatusFontWeight': {name: 'Status Font Weight', type: 'FontWeight', default: '400'},
    'detailsButtonFontSize': {name: 'Button Font Size', type: 'Number', default: '20', min: 1, max: 60},
    'detailsButtonFontWeight': {name: 'Button Font Weight', type: 'FontWeight', default: '400'},
    'detailsLogoX': {name: 'Logo X', type: 'Number', default: '145'},
    'detailsLogoY': {name: 'Logo Y', type: 'Number', default: '46'},
    'detailsCloseButtonX': {name: 'Close Button X', type: 'Number', default: '20'},
    'detailsCloseButtonY': {name: 'Close Button Y', type: 'Number', default: '20'},
    'detailsCenterLogo': {name: 'Center Logo', type: 'Bool', default: 'false'},
    'detailsLogoWidth': {name: 'Logo Width', type: 'Number', default: '270'},
    'detailsFields': { name: 'Fields', type: 'Fields', default: '[]' },
    'detailsTitleField': { name: 'Title field', type: 'Field', default: '' },
}

const generalAttributes: {[id:string]: attribute} = {
    'contentWidth': {name: 'content Width', type: 'Number', default: '1024'},
    'buttonCornerRadius': {name: 'button Corner Radius', type: 'Number', default: '0'},
    'statusForSaleColor': {name: 'For sale color', type: 'Color', default: '#6b8d60'},
    'statusReservedColor': {name: 'Reserved color', type: 'Color', default: '#b5965f'},
    'statusSoldColor': {name: 'Sold color', type: 'Color', default: '#7d131c'},
    'statusVacantColor': {name: 'Vacant color', type: 'Color', default: '#6b8d60'},
    'statusRentedOutColor': {name: 'Rented out color', type: 'Color', default: '#7d131c'},
    'statusConditionallySoldColor': { name: 'Conditionally sold color', type: 'Color', default: '#7d131c' },
    'statusPurchaseAgreementColor': { name: 'Purchase Agreement color', type: 'Color', default: '#5e78b5' },
    'statusCommonAreaColor': { name: 'Common area color', type: 'Color', default: '#42c6c4' },
}

const newDesignAttributes: {[id:string]: attribute} = {
    'primaryColorNewDesign': {name: 'Primary color', type: 'Color', default: '#b5965f'},
    'secondaryColorNewDesign': {name: 'Secondary color', type: 'Color', default: '#FFFFFF'},
    'backgroundColorNewDesign': {name: 'Background color', type: 'Color', default: '#2e2e2e'},
    'textColorNewDesign': {name: 'Text color', type: 'Color', default: '#FFFFFF'},
    'secondaryTextColorNewDesign': {name: 'Secondary text color', type: 'Color', default: '#FFFFFF'},
    'inputBackgroundColorNewDesign': {name: 'Input background color', type: 'Color', default: '#FFFFFF'},
    'inputTextColorNewDesign': {name: 'Input text color', type: 'Color', default: '#000000'},
    'disabledButtonColorNewDesign': {name: 'Disabled button color', type: 'Color', default: '#808080'},
    'headerContentHeightNewDesign': {name: 'Header content height', type: 'Number', default: '32'},
    'headerHeightNewDesign': { name: 'Header height new design', type: 'Number', default: '32' },
    'lightBoxBackgroundColorNewDesign': { name: 'Lightbox background color', type: 'Color', default: 'rgba(255, 255, 255, 0.8)' }
}

class CaseDesign extends React.Component<CaseDesignProps, CaseDesignState> {
    previewWindow: Window | null = null;

    constructor(props: CaseDesignProps) {
        super(props);

        this.state = {
            design: undefined
        };
    }

    public componentDidMount(): void {
        this.props.requestCaseDesign(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID));
        this.props.requestFields(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID));
    }

    private openPreviewWindow(){
        this.previewWindow = window.open(`${process.env.REACT_APP_VIEWER}/cases/${this.props.match.params.caseID}`, "preview");
        this.previewTheme();
    }

    private previewTheme(){
        if(this.previewWindow){
            this.previewWindow.postMessage({
                type: 'applyDesign',
                design: this.state.design
            }, `${process.env.REACT_APP_VIEWER}/cases/${this.props.match.params.caseID}`);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: CaseDesignProps) {
        if (this.state.design === undefined && nextProps.caseDesign.item !== undefined) {
            this.setState({
                design: {
                    defaults: Object.assign({}, nextProps.caseDesign.item.defaults),
                    desktopOverrides: Object.assign({}, nextProps.caseDesign.item.desktopOverrides),
                    largeDesktopBreakpoint: nextProps.caseDesign.item.largeDesktopBreakpoint,
                    largeDesktopOverrides: Object.assign({}, nextProps.caseDesign.item.largeDesktopOverrides),
                    mobileBreakpoint: nextProps.caseDesign.item.mobileBreakpoint,
                    mobileOverrides: Object.assign({}, nextProps.caseDesign.item.mobileOverrides),
                    tabletBreakpoint: nextProps.caseDesign.item.tabletBreakpoint,
                    tabletOverrides: Object.assign({}, nextProps.caseDesign.item.tabletOverrides),
                }
            });
        }
    }

    public render(): React.ReactNode {
        if (this.props.caseDesign.isLoading){

        }

        return (
            <>
                <DefaultButton text="Preview" iconProps={{iconName: 'NavigateExternalInline'}} onClick={() => this.openPreviewWindow()} />
                <Pivot>
                    <PivotItem headerText="Overview">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Default</th>
                                    <th>Mobile</th>
                                    <th>Tablet</th>
                                    <th>Desktop</th>
                                    <th>Large desktop</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(overviewAttributes).map(key => {
                                    return this.renderRow(key, overviewAttributes[key]);
                                })}
                            </tbody>
                        </table>
                    </PivotItem>
                    <PivotItem headerText="Section">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Default</th>
                                    <th>Mobile</th>
                                    <th>Tablet</th>
                                    <th>Desktop</th>
                                    <th>Large desktop</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(sectionAttributes).map(key => {
                                    return this.renderRow(key, sectionAttributes[key]);
                                })}
                            </tbody>
                        </table>
                    </PivotItem>
                    <PivotItem headerText="Popup">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Default</th>
                                    <th>Mobile</th>
                                    <th>Tablet</th>
                                    <th>Desktop</th>
                                    <th>Large desktop</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(popupAttributes).map(key => {
                                    return this.renderRow(key, popupAttributes[key]);
                                })}
                            </tbody>
                        </table>
                    </PivotItem>
                    <PivotItem headerText="List">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Default</th>
                                    <th>Mobile</th>
                                    <th>Tablet</th>
                                    <th>Desktop</th>
                                    <th>Large desktop</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(listAttributes).map(key => {
                                    return this.renderRow(key, listAttributes[key]);
                                })}
                            </tbody>
                        </table>
                    </PivotItem>
                    <PivotItem headerText="Details">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Default</th>
                                    <th>Mobile</th>
                                    <th>Tablet</th>
                                    <th>Desktop</th>
                                    <th>Large desktop</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(detailsAttributes).map(key => {
                                    return this.renderRow(key, detailsAttributes[key]);
                                })}
                            </tbody>
                        </table>
                    </PivotItem>
                    <PivotItem headerText="General">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Default</th>
                                    <th>Mobile</th>
                                    <th>Tablet</th>
                                    <th>Desktop</th>
                                    <th>Large desktop</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(generalAttributes).map(key => {
                                    return this.renderRow(key, generalAttributes[key]);
                                })}
                            </tbody>
                        </table>
                    </PivotItem>
                    <PivotItem headerText="Breakpoints">
                        <Stack tokens={{ childrenGap: 20 }}>
                            <StackItem>
                                <TextField label='Mobile' value={this.state.design ? this.state.design.mobileBreakpoint.toString() : ''} type='number' onChange={(e, val) => {
                                    if(this.state.design){
                                        this.setState({design: {...this.state.design, mobileBreakpoint: parseInt(val || '0')}});
                                    }
                                }} />
                            </StackItem>
                            <StackItem>
                                <TextField label='Tablet' value={this.state.design ? this.state.design.tabletBreakpoint.toString() : ''} type='number' onChange={(e, val) => {
                                    if(this.state.design){
                                        this.setState({design: {...this.state.design, tabletBreakpoint: parseInt(val || '0')}});
                                    }
                                }} />
                            </StackItem>
                            <StackItem>
                                <TextField label='Large desktop' value={this.state.design ? this.state.design.largeDesktopBreakpoint.toString() : ''} type='number' onChange={(e, val) => {
                                    if(this.state.design){
                                        this.setState({design: {...this.state.design, largeDesktopBreakpoint: parseInt(val || '0')}});
                                    }
                                }} />
                            </StackItem>
                        </Stack>
                    </PivotItem>
                    <PivotItem headerText="New Design">
                        <table>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Default</th>
                                    <th>Mobile</th>
                                    <th>Tablet</th>
                                    <th>Desktop</th>
                                    <th>Large desktop</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(newDesignAttributes).map(key => {
                                    return this.renderRow(key, newDesignAttributes[key]);
                                })}
                            </tbody>
                        </table>
                    </PivotItem>
                </Pivot>
                <p>
                    <ErrorMessage error={this.props.caseDesign.editError} />
                    <FieldValidation error={this.props.caseDesign.editError} />
                    <DefaultButton onClick={this._save}>Save</DefaultButton>
                </p>
            </>
        );
    }

    @boundMethod
    private _save(): void {
        if(this.state.design){
            this.props.editCaseDesign(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID), this.state.design);
        }
    }

    renderRow(attributeID: string, attribute: attribute) : React.ReactNode {
        return  <tr key={attributeID}>
            <td className={overrideValueCellClass}>{attribute.name}</td>
            <td className={overrideValueCellClass}>{this.renderDefaultField(attributeID, attribute)}</td>
            <td className={overrideValueCellClass}>{this.renderMobileFieldToggle(attributeID, attribute)}{this.renderMobileField(attributeID, attribute)}</td>
            <td className={overrideValueCellClass}>{this.renderTabletFieldToggle(attributeID, attribute)}{this.renderTabletField(attributeID, attribute)}</td>
            <td className={overrideValueCellClass}>{this.renderDesktopFieldToggle(attributeID, attribute)}{this.renderDesktopField(attributeID, attribute)}</td>
            <td className={overrideValueCellClass}>{this.renderLargeDesktopFieldToggle(attributeID, attribute)}{this.renderLargeDesktopField(attributeID, attribute)}</td>
        </tr>
    }

    @boundMethod
    renderField(attributeID: string, attribute: attribute, value: string | undefined, callback: (attributeID: string, value: string | undefined) => void) : React.ReactNode {
            if(attribute.type === 'Bool'){
                return <Checkbox label='Enabled' checked={value === 'true'} onChange={(e, checked) => callback(attributeID, checked ? 'true' : 'false')} />
            }
            if(attribute.type === 'Color'){
                return <CompactColorPicker value={value || '#fff'} onChange={(val) => callback(attributeID, val)} />
            }
            if(attribute.type === 'Number'){
                return <SpinButton min={attribute.min} max={attribute.max} value={value} 
                    onValidate={e => {callback(attributeID, e); return e}}
                    onIncrement={e => {let val = (parseInt(e) + 1).toString(); callback(attributeID, val); return val.toString()}}
                    onDecrement={e => {let val = (parseInt(e) - 1).toString(); callback(attributeID, val); return val.toString()}}  />
            }
            if(attribute.type === 'String'){
                return <TextField value={value} onChange={(e, val) => callback(attributeID, val)} />
            }
            if(attribute.type === 'FontWeight'){
                return <Dropdown options={[
                    {key: 'normal', text: 'normal'},
                    {key: 'bold', text: 'bold'},
                    {key: 'bolder', text: 'bolder'},
                    {key: 'lighter', text: 'lighter'},
                    {key: '100', text: '100'},
                    {key: '200', text: '200'},
                    {key: '300', text: '300'},
                    {key: '400', text: '400'},
                    {key: '500', text: '500'},
                    {key: '600', text: '600'},
                    {key: '700', text: '700'},
                    {key: '800', text: '800'},
                    {key: '900', text: '900'},
                    {key: 'initial', text: 'initial'},
                    {key: 'inherit', text: 'inherit'}
                ]} selectedKey={value} onChange={(e, val) => callback(attributeID, val ? val.key.toString() : undefined)}></Dropdown>
            }
            if (attribute.type === 'Field') {
                return <Dropdown options={[{ key: '', text: ' - none - ' }, ...this.props.fields.items.map(e => ({ key: e.id.toString(), text: e.name }))]} selectedKey={value} onChange={(e, val) => callback(attributeID, val ? val.key.toString() : undefined)}></Dropdown>
            }
            if(attribute.type === 'Fields'){
                return <FieldsPicker organizationID={parseInt(this.props.match.params.organizationID)} caseID={parseInt(this.props.match.params.caseID)} fields={value || '[]'} onChange={(val) => callback(attributeID, val)} />
            }
            if(attribute.type === 'Filters'){
                return <FilterPicker organizationID={parseInt(this.props.match.params.organizationID)} caseID={parseInt(this.props.match.params.caseID)} fields={value || '[]'} onChange={(val) => callback(attributeID, val)} />
            }
            if(attribute.type === 'Sorting'){
                return <SortingPicker organizationID={parseInt(this.props.match.params.organizationID)} caseID={parseInt(this.props.match.params.caseID)} value={value || 'id:asc'} onChange={(val) => callback(attributeID, val)} />
            }
        return null;
    }

    @boundMethod
    renderMobileFieldToggle(attributeID: string, attribute: attribute) : React.ReactNode {
        if(this.state.design){
            let enabled = this.state.design.mobileOverrides[attributeID] !== undefined;

            return <Checkbox label='override' checked={enabled} onChange={(e, checked) => {
                if(this.state.design){
                    if(checked){
                        let mobileOverrides = {...this.state.design.mobileOverrides, [attributeID]: attribute.default};
                        this.setState({design: {...this.state.design, mobileOverrides: mobileOverrides}}, this.previewTheme);
                    }else{
                        let mobileOverrides = {...this.state.design.mobileOverrides};
                        delete mobileOverrides[attributeID];
                        this.setState({design: {...this.state.design, mobileOverrides: mobileOverrides}}, this.previewTheme);
                    }
                }
            }} />;
        }
    }

    @boundMethod
    renderTabletFieldToggle(attributeID: string, attribute: attribute) : React.ReactNode {
        if(this.state.design){
            let enabled = this.state.design.tabletOverrides[attributeID] !== undefined;

            return <Checkbox label='override' checked={enabled} onChange={(e, checked) => {
                if(this.state.design){
                    if(checked){
                        let tabletOverrides = {...this.state.design.tabletOverrides, [attributeID]: attribute.default};
                        this.setState({design: {...this.state.design, tabletOverrides: tabletOverrides}}, this.previewTheme);
                    }else{
                        let tabletOverrides = {...this.state.design.tabletOverrides};
                        delete tabletOverrides[attributeID];
                        this.setState({design: {...this.state.design, tabletOverrides: tabletOverrides}}, this.previewTheme);
                    }
                }
            }} />;
        }
    }

    @boundMethod
    renderDesktopFieldToggle(attributeID: string, attribute: attribute) : React.ReactNode {
        if(this.state.design){
            let enabled = this.state.design.desktopOverrides[attributeID] !== undefined;

            return <Checkbox label='override' checked={enabled} onChange={(e, checked) => {
                if(this.state.design){
                    if(checked){
                        let desktopOverrides = {...this.state.design.desktopOverrides, [attributeID]: attribute.default};
                        this.setState({design: {...this.state.design, desktopOverrides: desktopOverrides}}, this.previewTheme);
                    }else{
                        let desktopOverrides = {...this.state.design.desktopOverrides};
                        delete desktopOverrides[attributeID];
                        this.setState({design: {...this.state.design, desktopOverrides: desktopOverrides}}, this.previewTheme);
                    }
                }
            }} />;
        }
    }

    @boundMethod
    renderLargeDesktopFieldToggle(attributeID: string, attribute: attribute) : React.ReactNode {
        if(this.state.design){
            let enabled = this.state.design.largeDesktopOverrides[attributeID] !== undefined;

            return <Checkbox label='override' checked={enabled} onChange={(e, checked) => {
                if(this.state.design){
                    if(checked){
                        let largeDesktopOverrides = {...this.state.design.largeDesktopOverrides, [attributeID]: attribute.default};
                        this.setState({design: {...this.state.design, largeDesktopOverrides: largeDesktopOverrides}}, this.previewTheme);
                    }else{
                        let largeDesktopOverrides = {...this.state.design.largeDesktopOverrides};
                        delete largeDesktopOverrides[attributeID];
                        this.setState({design: {...this.state.design, largeDesktopOverrides: largeDesktopOverrides}}, this.previewTheme);
                    }
                }
            }} />;
        }
    }

    @boundMethod
    renderDefaultField(attributeID: string, attribute: attribute) : React.ReactNode {
        if(this.state.design){
            return this.renderField(attributeID, attribute, this.state.design.defaults[attributeID], this.setDefaultField);
        }
        return null;
    }

    @boundMethod
    renderMobileField(attributeID: string, attribute: attribute) : React.ReactNode {
        if(this.state.design && this.state.design.mobileOverrides[attributeID] !== undefined){
            return this.renderField(attributeID, attribute, this.state.design.mobileOverrides[attributeID], this.setMobileField);
        }
    }

    @boundMethod
    renderTabletField(attributeID: string, attribute: attribute) : React.ReactNode {
        if(this.state.design && this.state.design.tabletOverrides[attributeID] !== undefined){
            return this.renderField(attributeID, attribute, this.state.design.tabletOverrides[attributeID], this.setTabletField);
        }
        return null;
    }

    @boundMethod
    renderDesktopField(attributeID: string, attribute: attribute) : React.ReactNode {
        if(this.state.design && this.state.design.desktopOverrides[attributeID] !== undefined){
            return this.renderField(attributeID, attribute, this.state.design.desktopOverrides[attributeID], this.setDesktopField);
        }
        return null;
    }

    @boundMethod
    renderLargeDesktopField(attributeID: string, attribute: attribute) : React.ReactNode {
        if(this.state.design && this.state.design.largeDesktopOverrides[attributeID] !== undefined){
            return this.renderField(attributeID, attribute, this.state.design.largeDesktopOverrides[attributeID], this.setLargeDesktopField);
        }
        return null;
    }

    @boundMethod
    setDefaultField(field: string, value: string | undefined){
        if(this.state.design && value !== undefined){
            let defaults = {...this.state.design.defaults, [field]: value};
            this.setState({design: {...this.state.design, defaults: defaults}}, this.previewTheme);
        }
    }

    @boundMethod
    setMobileField(field: string, value: string | undefined){
        if(this.state.design && value !== undefined){
            let mobileOverrides = {...this.state.design.mobileOverrides, [field]: value};
            this.setState({design: {...this.state.design, mobileOverrides: mobileOverrides}}, this.previewTheme);
        }
    }

    @boundMethod
    setTabletField(field: string, value: string | undefined){
        if(this.state.design && value !== undefined){
            let tabletOverrides = {...this.state.design.tabletOverrides, [field]: value};
            this.setState({design: {...this.state.design, tabletOverrides: tabletOverrides}}, this.previewTheme);
        }
    }

    @boundMethod
    setDesktopField(field: string, value: string | undefined){
        if(this.state.design && value !== undefined){
            let desktopOverrides = {...this.state.design.desktopOverrides, [field]: value};
            this.setState({design: {...this.state.design, desktopOverrides: desktopOverrides}}, this.previewTheme);
        }
    }

    @boundMethod
    setLargeDesktopField(field: string, value: string | undefined){
        if(this.state.design && value !== undefined){
            let largeDesktopOverrides = {...this.state.design.largeDesktopOverrides, [field]: value};
            this.setState({design: {...this.state.design, largeDesktopOverrides: largeDesktopOverrides}}, this.previewTheme);
        }
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state, { ...actionCreators, ...fieldsActionCreators })(CaseDesign);