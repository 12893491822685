import { FontIcon, ISelection, mergeStyles, MessageBar, MessageBarType, SelectionMode } from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import filesize from 'filesize';
import { CommandBar, DefaultButton, Dialog, DialogFooter, DialogType, Panel, PanelType, PrimaryButton, Selection, Stack, StackItem } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { DataTable, ErrorMessage, FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, IMediaFile, reducer } from '../../store/MediaFiles';

type PricezoneProps = ReturnType<typeof reducer> & typeof actionCreators & RouteComponentProps<{ organizationID: string; caseID: string }>;

type PricezonesState = {
    showCreatePanel: boolean;
    showEditPanel: boolean;
    showReplacePanel: boolean;
    selectedMediaFile?: IMediaFile;
    deleteDialogVisible: boolean;
    newFiles?: FileList;
    editFile?: File;
    itemsSelected: number;
    deleteSelectionDialogVisible: boolean;
    filesSkipped: string[];
};

const iconClass = mergeStyles({
    fontSize: 30,
    height: 50,
    width: 50
  });

class MediaFiles extends React.Component<PricezoneProps, PricezonesState> {
    private _selection: ISelection;

    constructor(props: PricezoneProps) {
        super(props);

        this._selection = new Selection({
            getKey: (e:any) => e.id.toString(),
            selectionMode: SelectionMode.multiple,
            onSelectionChanged: () => {
                this.setState({
                    itemsSelected: this._selection.count
                });
            },
        });

        this.state = {
            showCreatePanel: false,
            deleteDialogVisible: false,
            showEditPanel: false,
            itemsSelected: 0,
            deleteSelectionDialogVisible: false,
            showReplacePanel: false,
            filesSkipped: []
        };
    }

    public componentDidMount(): void {
        this.props.requestMediaFiles(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID));
    }

    public UNSAFE_componentWillReceiveProps(newProps: PricezoneProps) {
        if (newProps.match.params.organizationID !== this.props.match.params.organizationID || newProps.match.params.caseID !== this.props.match.params.caseID) {
            newProps.requestMediaFiles(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID));
        }
    }

    public render(): React.ReactNode {
        return (
            <>
                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>

                        <CommandBar
                            items={[
                                {
                                    key: 'Add',
                                    name: 'Create new media file',
                                    iconProps: {
                                        iconName: 'Add'
                                    },
                                    onClick: () => this._showCreatePanel()
                                },
                                {
                                    key: 'Replace',
                                    name: 'Replace media files',
                                    iconProps: {
                                        iconName: 'EditCreate'
                                    },
                                    onClick: () => this._showReplacePanel()
                                },
                                {
                                    key: 'delete',
                                    name: 'Delete selection',
                                    disabled: this.state.itemsSelected === 0,
                                    iconProps: {
                                        iconName: 'Delete'
                                    },
                                    onClick: () => this._promptForDeleteSelection()
                                }
                            ]}
                        />

                        <Panel isOpen={this.state.showCreatePanel} onDismiss={this._hideCreatePanel} type={PanelType.medium} headerText='New media file' onRenderFooterContent={() => <div>
                            <PrimaryButton disabled={this.props.isAdding} onClick={this._addMediaFile} text='Create' style={{ marginRight: '8px' }} />
                            <DefaultButton onClick={this._hideCreatePanel}>Cancel</DefaultButton>
                        </div>}>
                            <Stack tokens={{ childrenGap: 20 }}>
                                <ErrorMessage error={this.props.addError} />
                                <FieldValidation error={this.props.addError} />

                                <StackItem>
                                    <input type='file' multiple onChange={(e) => {
                                        if (e.target.files) {
                                            this.setState({ newFiles: e.target.files });
                                        }
                                    }} />
                                </StackItem>
                            </Stack>
                        </Panel>

                        <Panel isOpen={this.state.showReplacePanel} onDismiss={this._hideReplacePanel} type={PanelType.medium} headerText='Replace media files' onRenderFooterContent={() => <div>
                            <PrimaryButton disabled={this.props.isEditing} onClick={this._replaceMediaFile} text='Update' style={{ marginRight: '8px' }} />
                            <DefaultButton onClick={this._hideReplacePanel}>Cancel</DefaultButton>
                        </div>}>
                            <Stack tokens={{ childrenGap: 20 }}>
                                <ErrorMessage error={this.props.editError} />
                                <FieldValidation error={this.props.editError} />
                                {this.state.filesSkipped.length && <MessageBar messageBarType={MessageBarType.error}>Files skipped:<br />{this.state.filesSkipped.map(e => <span key={e}>{e}<br /></span>)}</MessageBar>}

                                <StackItem>
                                    <input type='file' multiple onChange={(e) => {
                                        if (e.target.files) {
                                            this.setState({ newFiles: e.target.files });
                                        }
                                    }} />
                                </StackItem>
                            </Stack>
                        </Panel>

                        <Panel isOpen={this.state.showEditPanel} onDismiss={this._hideEditPanel} type={PanelType.medium} headerText='Edit media file' onRenderFooterContent={() => <div>
                            <PrimaryButton disabled={this.props.isEditing} onClick={this._editMediaFile} text='Save' style={{ marginRight: '8px' }} />
                            <DefaultButton onClick={this._hideEditPanel}>Cancel</DefaultButton>
                        </div>}>
                            <Stack tokens={{ childrenGap: 20 }}>
                                <ErrorMessage error={this.props.editError} />
                                <FieldValidation error={this.props.editError} />

                                <StackItem>
                                    <input type='file' onChange={(e) => {
                                        if (e.target.files) {
                                            const file = e.target.files[0];

                                            if (file !== undefined) {
                                                this.setState({ editFile: file });
                                            }
                                        }
                                    }} />
                                </StackItem>
                            </Stack>
                        </Panel>
                    </StackItem>

                    <StackItem>
                        <ErrorMessage error={this.props.loadError} />

                        <DataTable<IMediaFile> paginated selection={this._selection} items={this.props.items} isLoading={this.props.isLoading} onDelete={this._promptForDelete} onEdit={this._showEditPanel} coloumns={[
                            {
                                key: 'thumbnail',
                                name: 'Thumbnail',
                                fieldName: 'Thumbnail',
                                minWidth: 150,
                                maxWidth: 150,
                                isResizable: true,
                                isSortable: true,
                                onRender: (item: IMediaFile) => {
                                    if(item.hasThumbnail){
                                        return <img alt={item.filename} width={50} src={`${process.env.REACT_APP_API_HOST}/v1/organizations/${this.props.match.params.organizationID}/cases/${this.props.match.params.caseID}/media/${item.id}/thumbnail`} />
                                    }

                                    if(item.mimeType.startsWith('image')){
                                        return <FontIcon iconName="FileImage" className={iconClass} />
                                    }
                                    if(item.mimeType.startsWith('video')){
                                        return <FontIcon iconName="MyMoviesTV" className={iconClass} />
                                    }
                                    if(item.mimeType.indexOf('pdf') !== -1){
                                        return <FontIcon iconName="PDF" className={iconClass} />
                                    }
                                    
                                    return <FontIcon iconName="PDF" className={iconClass} />
                                }
                            },
                            {
                                key: 'name',
                                name: 'Name',
                                fieldName: 'filename',
                                minWidth: 210,
                                maxWidth: 1000,
                                isResizable: true,
                                isSortable: true
                            },
                            {
                                key: 'size',
                                name: 'Size',
                                fieldName: 'fileSize',
                                minWidth: 150,
                                maxWidth: 150,
                                isResizable: true,
                                isSortable: true,
                                onRender: (item: IMediaFile) => filesize(item.fileSize)
                            },
                            {
                                key: 'dimensions',
                                name: 'Dimensions',
                                fieldName: 'Width',
                                minWidth: 150,
                                maxWidth: 150,
                                isResizable: true,
                                isSortable: true,
                                onRender: (item: IMediaFile) => {
                                    return item.width === null ? null : `${item.width} x ${item.height}`;
                                }
                            }
                        ]} />
                    </StackItem>

                </Stack>

                <Dialog
                    hidden={!this.state.deleteDialogVisible}
                    onDismiss={this._hideDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete mediafile',
                        subText: 'This will the media file permantly'
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <ErrorMessage error={this.props.deleteError} />
                    <DialogFooter>
                        <PrimaryButton onClick={this._deleteMediaFile} text='Delete' />
                        <DefaultButton onClick={this._hideDialog} text='Cancel' />
                    </DialogFooter>
                </Dialog>

                <Dialog
                    hidden={!this.state.deleteSelectionDialogVisible}
                    onDismiss={() => {this.setState({deleteSelectionDialogVisible: false})}}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete media files',
                        subText: `This will remove the ${this.state.itemsSelected} selected files permantly`
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <ErrorMessage error={this.props.deleteError} />
                    <DialogFooter>
                        <PrimaryButton onClick={this._deleteSelection} text='Delete' />
                        <DefaultButton onClick={() => {this.setState({deleteSelectionDialogVisible: false})}} text='Cancel' />
                    </DialogFooter>
                </Dialog>
            </>
        );
    }

    @boundMethod
    private _promptForDeleteSelection() {
        this.setState({
            deleteSelectionDialogVisible: true
        });
    }

    @boundMethod
    private _deleteSelection(): void {
        let selection = this._selection.getSelection() as IMediaFile[];

        for (let i = 0; i < selection.length; i++) {
            const element = selection[i];
            this.props.deleteMediaFile(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID),element, () => { this.setState({deleteSelectionDialogVisible: false}); });
        }
    }

    @boundMethod
    private _addMediaFile(): void {
        if (this.state.newFiles) {
            for (let i = 0; i < this.state.newFiles.length; i++) {
                this.props.createMediaFile(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID), this.state.newFiles[i], () => { this._hideCreatePanel(); });
            }
        }
    }

    @boundMethod
    private _replaceMediaFile(): void {
        this.setState({ filesSkipped: [] });
        if (this.state.newFiles) {
            for (let i = 0; i < this.state.newFiles.length; i++) {
                var file = this.props.items.find(e => e.filename === this.state.newFiles![i].name);

                if (file) {
                    this.props.editMediaFile(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID), file, this.state.newFiles[i], () => { if (this.state.filesSkipped.length === 0) { this._hideReplacePanel() } });
                } else {
                    console.error(`Could not find file with name: ${this.state.newFiles![i].name}`);
                    this.setState(state => ({
                        filesSkipped: [...state.filesSkipped, this.state.newFiles![i].name]
                    }));
                }
            }
        }
    }

    @boundMethod
    private _editMediaFile(): void {
        if (this.state.editFile && this.state.selectedMediaFile) {
            this.props.editMediaFile(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID), this.state.selectedMediaFile, this.state.editFile, () => { this._hideEditPanel(); });
        }
    }

    @boundMethod
    private _deleteMediaFile(): void {
        if (this.state.selectedMediaFile) {
            this.props.deleteMediaFile(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID), this.state.selectedMediaFile, () => { this._hideDialog(); });
        }
    }

    @boundMethod
    private _promptForDelete(file: IMediaFile) {
        this.setState({
            selectedMediaFile: file,
            deleteDialogVisible: true
        });
    }

    @boundMethod
    private _hideDialog() {
        this.setState({
            deleteDialogVisible: false
        });
    }

    @boundMethod
    private _showEditPanel(file: IMediaFile) {
        this.setState({ 
            selectedMediaFile: file,
            showEditPanel: true
        });
    }

    @boundMethod
    private _hideEditPanel() {
        this.setState({ 
            showEditPanel: false
        });
    }

    @boundMethod
    private _showCreatePanel() {
        this.setState({ showCreatePanel: true });
    }

    @boundMethod
    private _hideCreatePanel() {
        this.setState({
            showCreatePanel: false
        });
    }

    @boundMethod
    private _showReplacePanel() {
        this.setState({ showReplacePanel: true });
    }

    @boundMethod
    private _hideReplacePanel() {
        this.setState({
            showReplacePanel: false
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.mediaFiles, actionCreators)(MediaFiles);