import {
  Checkbox,
  Dropdown,
  FontIcon,
  IDropdownOption,
  mergeStyles,
  Pivot,
  PivotItem,
  PrimaryButton,
  Slider,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { FieldValidation } from '../../components/controls';
import MediaPicker from '../../components/controls/MediaPicker';
import { ApplicationState } from '../../store';
import * as Cases from '../../store/Cases';
import * as Sections from '../../store/Sections';

type CaseDetailsProps = {
  cases: ReturnType<typeof Cases.reducer>;
  sections: ReturnType<typeof Sections.reducer>;
} & typeof Cases.actionCreators & typeof Sections.actionCreators & RouteComponentProps<{ organizationID: string, caseID: string }>;

type CaseDetailsState = {
  editCase: Cases.ICaseEditDTO;
};

const codeClass = mergeStyles({
  border: '1px solid #aaa',
  backgroundColor: '#eee',
  padding: 10,
  margin: 10,
  display: 'block'
});

class CaseDetails extends React.Component<CaseDetailsProps, CaseDetailsState> {

  constructor(props: CaseDetailsProps) {
    super(props);
    this.state = {
      editCase: {
        name: props.cases.item === undefined ? '' : props.cases.item.name,
        logoID: props.cases.item === undefined ? undefined : props.cases.item.logoID,
        googleAnalyticsID: props.cases.item === undefined ? '' : props.cases.item.googleAnalyticsID,
        designID: props.cases.item === undefined ? 0 : props.cases.item.designID,
        unitFooterDetails: props.cases.item === undefined ? '' : props.cases.item.unitFooterDetails,
        fixedMobileMenu: props.cases.item === undefined ? false : props.cases.item.fixedMobileMenu,
        sectionMenuItemAmount: props.cases.item === undefined ? 4 : props.cases.item.sectionMenuItemAmount,
        viewerHeight: props.cases.item === undefined ? 4 : props.cases.item.viewerHeight,
      }
    };
  }

  public componentDidMount(): void {
    this.props.requestSections(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID));
  }

  UNSAFE_componentWillReceiveProps(nextProps: CaseDetailsProps) {
    if (nextProps.cases.item === undefined) {
      this.setState({
        editCase: {
          name: '',
          logoID: undefined,
          designID: 0,
          fixedMobileMenu: false,
          sectionMenuItemAmount: 5,
          viewerHeight: 100
        }
      });
    } else {
      if (this.props.cases.item === undefined || this.props.cases.item.id !== nextProps.cases.item.id) {
        this.setState({
          editCase: {
            name: nextProps.cases.item.name,
            logoID: nextProps.cases.item.logoID,
            googleAnalyticsID: nextProps.cases.item.googleAnalyticsID,
            designID: nextProps.cases.item.designID,
            unitFooterDetails: nextProps.cases.item.unitFooterDetails,
            fixedMobileMenu: nextProps.cases.item.fixedMobileMenu,
            sectionMenuItemAmount: nextProps.cases.item.sectionMenuItemAmount,
            viewerHeight: nextProps.cases.item.viewerHeight,
          }
        });
      }
    }
  }


  @boundMethod
  private _onDesignChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined): void {
    if (option) {
      this.setState(
        {
          editCase: { ...this.state.editCase, designID: option.key as 0 | 1 }
        })
    }
  }

  public render(): React.ReactNode {
    if (this.props.cases.item === undefined) {
      return null;
    }

    const js = `<div id="boligvaelger-container"></div>
<script src="${process.env.REACT_APP_VIEWER}/dist/main.js"></script>
<link rel="stylesheet" href="${process.env.REACT_APP_VIEWER}/dist/site.css">
<script type="text/javascript">
    BoligVaelger.init({
        containerElement: document.getElementById('boligvaelger-container'),
        caseID: ${this.props.match.params.caseID}
    });
</script>`;

    const iframe = `<iframe src="${process.env.REACT_APP_VIEWER}/cases/${this.props.match.params.caseID}" />`;
    const wordpressShortcode = `[boligvaelger caseid=${this.props.match.params.caseID}]`;

    const overviewSvg = `...
${this.props.sections.items.map(e => `<g id="section-${e.id}">
    <polygon class="zone" points="..."/>
</g>`).join('\r\n')}
...`;

    const sectionSvg = `...
<g id="unit-{unitID}">
    <polygon class="zone" points="..."/>
    <rect class="indicator" x="1346" y="1720" width="35" height="35"/>
</g>
...`;

    return (
      <div>

        <Stack tokens={{ childrenGap: 20 }}>
          <StackItem>
            <MediaPicker
              label='Logo'
              organizationID={parseInt(this.props.match.params.organizationID)}
              caseID={this.props.cases.item.id}
              value={this.state.editCase.logoID ? [this.state.editCase.logoID] : []}
              onChange={(value) => this.setState({ editCase: { ...this.state.editCase, logoID: value.length === 0 ? undefined : value[0] } })} />
            <FieldValidation error={this.props.cases.editError} fieldname='LogoID' />
          </StackItem>
          <StackItem>
            <TextField label='Google analytics ID' value={this.state.editCase.googleAnalyticsID || ''} onChange={(e, value) => this.setState({ editCase: { ...this.state.editCase, googleAnalyticsID: value || '' } })} />
            <FieldValidation error={this.props.cases.editError} fieldname='GoogleAnalyticsID' />
          </StackItem>
          <StackItem>
            <TextField label='Unit footer details' value={this.state.editCase.unitFooterDetails || ''} onChange={(e, value) => this.setState({ editCase: { ...this.state.editCase, unitFooterDetails: value || '' } })} />
            <FieldValidation error={this.props.cases.editError} fieldname='UnitFooterDetails' />
          </StackItem>
          <StackItem>
            <Dropdown
              placeholder="Select design"
              label="Select case design"
              selectedKey={this.state.editCase.designID}
              onChange={this._onDesignChange}
              options={[
                { key: 0, text: 'Original design' },
                { key: 1, text: 'New design' }
              ]} />
          </StackItem>
          <StackItem>
            <Checkbox
              label="Enabled fixed mobile menu (It will be sticky)"
              checked={this.state.editCase.fixedMobileMenu ? this.state.editCase.fixedMobileMenu : false}
              onChange={(ev, isChecked) => this.setState({ editCase: { ...this.state.editCase, fixedMobileMenu: isChecked } })}
            />
          </StackItem>
          <StackItem>
            <Slider
              label="Sections displayed in section menu on desktop"
              min={2}
              max={10}
              step={1}
              defaultValue={2}
              showValue snapToStep
              value={this.state.editCase.sectionMenuItemAmount}
              onChange={(value) => this.setState({ editCase: { ...this.state.editCase, sectionMenuItemAmount: value } })}
            />
          </StackItem>
          <StackItem>
            <Slider
              label="Window height of the viewer in % (100% means it takes the height of a fullscreen)"
              min={50}
              max={100}
              step={1}
              defaultValue={100}
              showValue snapToStep
              value={this.state.editCase.viewerHeight}
              onChange={(value) => this.setState({ editCase: { ...this.state.editCase, viewerHeight: value } })}
            />
          </StackItem>
          <StackItem>
            <PrimaryButton disabled={this.props.cases.isEditing} onClick={this._editCase} style={{ marginRight: '8px' }} text='Save' />
          </StackItem>
        </Stack>

        <hr />

        <h4>Embeding</h4>
        <a href={`${process.env.REACT_APP_VIEWER}/cases/${this.props.match.params.caseID}`} target="_blank" rel="noopener noreferrer">View <FontIcon iconName="NavigateExternalInline" /></a>
        <Pivot>
          <PivotItem headerText='JS'>
            <code className={codeClass}>
              <pre>
                {js}
              </pre>
            </code>
          </PivotItem>
          <PivotItem headerText='Iframe'>
            <code className={codeClass}>
              <pre>
                {iframe}
              </pre>
            </code>
          </PivotItem>
          <PivotItem headerText='Wordpress'>
            short code: <code className={codeClass}>{wordpressShortcode}</code>
          </PivotItem>
        </Pivot>

        <h4>svg files</h4>
        <Pivot>
          <PivotItem headerText='Overview'>
            <code className={codeClass}>
              <pre>
                {overviewSvg}
              </pre>
            </code>
          </PivotItem>
          <PivotItem headerText='Sections'>
            <code className={codeClass}>
              <pre>
                {sectionSvg}
              </pre>
            </code>
          </PivotItem>
        </Pivot>
      </div>
    );
  }

  @boundMethod
  private _editCase(): void {
    if (this.props.cases.item) {
      this.props.editCase(parseInt(this.props.match.params.organizationID), this.props.cases.item, this.state.editCase, () => { });
    }
  }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state, { ...Cases.actionCreators, ...Sections.actionCreators })(CaseDetails);