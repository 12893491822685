import { BaseService } from './base.service';
import { IAuthUser, ICredentials } from '../store/Auth';

class AuthService extends BaseService {
    private static _authService: AuthService;

    private constructor() {
        super();
    }

    public static get Instance(): AuthService {
        return this._authService || (this._authService = new this());
    }

    public async loginAsync(credentials: ICredentials): Promise<IAuthUser> {
        const { data } = await this.$http.post('account/login', credentials);
        return data as IAuthUser;
    }

    public async forgotPasswordAsync(username: string): Promise<boolean> {
        await this.$http.post('account/forgotpassword', { username: username });
        return true;
    }

    public async resetPasswordAsync(password: string, token: string): Promise<boolean> {
        await this.$http.post('account/resetpassword', { SecurityToken: token, NewPassword: password });
        return true;
    }
}

export const AuthApi = AuthService.Instance;