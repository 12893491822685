import { boundMethod } from 'autobind-decorator';
import { ActionButton, Button, PrimaryButton, Spinner, Checkbox, Stack, StackItem, TextField } from '@fluentui/react';
import qs from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { ErrorMessage, FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, AuthStatusEnum, ICredentials, reducer } from '../../store/Auth';
import logo from '../../assets/VR-Co-logo-Blaa.svg';

type LoginProps = ReturnType<typeof reducer> & typeof actionCreators & RouteComponentProps<{}>;
type LoginState = typeof initialState;

const initialState = Object.freeze({
    forgotPassword: false,
    credentials: {
        Email: '',
        password: '',
        rememberMe: false
    } as ICredentials
});


class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);
        this.state = initialState;
    }

    public render(): React.ReactNode {

        if (this.props.isAuthenticated) {

            let query = qs.parse(this.props.history.location.search);
            let path = (query['return'] as string) || '/';

            return <Redirect to={path} />;
        }

        let forgot = this.state.forgotPassword;

        return (
            <div>
                <Stack wrap horizontal verticalAlign='center' horizontalAlign='center' tokens={{ childrenGap: 50 }} styles={{ root: { width: '100%', height: '80vh' } }}>
                    <Stack horizontalAlign='end'>
                        <img src={logo} width={250} alt='logo' />
                    </Stack>


                    {forgot ?
                        <Stack horizontalAlign='start' tokens={{ childrenGap: 20 }}>
                            <ErrorMessage error={this.props.forgetError} />

                            <StackItem>
                                <TextField label='Email' value={this.state.credentials.email} onChange={this.updateUserName} />
                                <FieldValidation error={this.props.forgetError} fieldname='Email' />
                            </StackItem>

                            <Stack horizontal verticalAlign='center'>
                                <PrimaryButton text='Reset' disabled={this.props.forgetStatus === AuthStatusEnum.Process} onClick={this.handleForgotPassword} />
                                {(this.props.forgetStatus === AuthStatusEnum.Process) ? <Spinner /> : null}
                                <ActionButton onClick={() => this.setState({ forgotPassword: !this.state.forgotPassword })}>
                                    Login
                                </ActionButton>
                            </Stack>
                        </Stack> :

                        <Stack horizontalAlign='start' tokens={{ childrenGap: 20 }}>
                            <ErrorMessage error={this.props.loginError} />

                            <StackItem>
                                <TextField label='Email' value={this.state.credentials.email} onChange={this.updateUserName} />
                                <FieldValidation error={this.props.loginError} fieldname='Email' />
                            </StackItem>

                            <StackItem>
                                <TextField label='Password' type='password' value={this.state.credentials.password} onChange={this.updatePassword} />
                                <FieldValidation error={this.props.loginError} fieldname='Password' />
                            </StackItem>

                            <Checkbox label='Remember me' checked={!!this.state.credentials.rememberMe} onChange={this.updateRememberMe} />

                            <Stack horizontal verticalAlign='center'>
                                <PrimaryButton text='Login' disabled={this.props.loginStatus === AuthStatusEnum.Process} onClick={this.handleLogin} />
                                {(this.props.loginStatus === AuthStatusEnum.Process) ? <Spinner /> : null}
                                <ActionButton onClick={() => this.setState({ forgotPassword: !this.state.forgotPassword })}>
                                    Forgot password?
                                </ActionButton>
                            </Stack>
                        </Stack>
                    }
                </Stack>
            </div>
        );
    }

    @boundMethod
    private handleLogin(e: React.MouseEvent<Button>): void {
        e.preventDefault();

        // Prevent multiple login requests onClick
        if (this.props.loginStatus === AuthStatusEnum.Process) {
            return;
        }

        this.props.loginUserRequest(this.state.credentials);
    }

    @boundMethod
    private handleForgotPassword(e: React.MouseEvent<Button>): void {
        e.preventDefault();

        // Prevent multiple login requests onClick
        if (this.props.forgetStatus === AuthStatusEnum.Process) {
            return;
        }

        this.props.forgotPasswordRequest(this.state.credentials.email!);
    }

    @boundMethod
    private updateRememberMe(e: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked: boolean | undefined): void {
        this.setState({
            credentials: {
                ...this.state.credentials,
                rememberMe: checked
            }
        });
    }

    @boundMethod
    private updateUserName(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        this.setState({
            credentials: {
                ...this.state.credentials,
                email: (newValue || '').trim()
            }
        });
    }

    @boundMethod
    private updatePassword(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        this.setState({
            credentials: {
                ...this.state.credentials,
                password: (newValue || '').trim()
            }
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.auth, actionCreators)(Login);