import { IUnit, IUnitCreateDTO, IUnitEditDTO } from '../store/Units';
import { BaseService } from './base.service';

class UnitService extends BaseService {
    private static _angleService: UnitService;

    private constructor() {
        super();
    }

    public static get Instance(): UnitService {
        return this._angleService || (this._angleService = new this());
    }

    public async getUnitsAsync(organizationID: number, caseID: number): Promise<IUnit[]> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/units`);
        return data as IUnit[];
    }

    public async getUnitAsync(organizationID: number, caseID: number, id: number): Promise<IUnit> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/units/${id}`);
        return data as IUnit;
    }

    public async createUnitAsync(organizationID: number, caseID: number, angle: IUnitCreateDTO): Promise<IUnit> {
        const { data } = await this.$http.put(`organizations/${organizationID}/cases/${caseID}/units`, angle);
        return data;
    }

    public async editUnitAsync(organizationID: number, caseID: number, id: number, angle: IUnitEditDTO): Promise<IUnit> {
        const { data } = await this.$http.post(`organizations/${organizationID}/cases/${caseID}/units/${id}`, angle);
        return data;
    }

    public async deleteUnitAsync(organizationID: number, caseID: number, id: number): Promise<number> {
        await this.$http.delete(`organizations/${organizationID}/cases/${caseID}/units/${id}`);
        return id;
    }
}

export const UnitApi = UnitService.Instance;