import { boundMethod } from 'autobind-decorator';
import { PrimaryButton, Spinner, Stack, TextField } from 'office-ui-fabric-react';
import qs from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { ErrorMessage, FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, AuthStatusEnum, reducer } from '../../store/Auth';

type LoginProps = ReturnType<typeof reducer> & typeof actionCreators & RouteComponentProps<{ token: string }>;
type LoginState = typeof initialState;

const initialState = Object.freeze({
    password: '',
    passwordConfirmation: ''
});

class ResetPassword extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps) {
        super(props);
        this.state = initialState;
    }

    public render(): React.ReactNode {

        if (this.props.isAuthenticated) {
            return <Redirect to={'/'} />;
        }

        let loading = this.props.resetStatus === AuthStatusEnum.Process;

        return (
            <div>
                <Stack wrap horizontal verticalAlign='center' horizontalAlign='center' tokens={{ childrenGap: 50 }} styles={{ root: { width: '100%', height: '80vh' } }}>
                    <Stack horizontalAlign='end'>
                    </Stack>

                    <Stack horizontalAlign='start' tokens={{ childrenGap: 20 }}>
                        <h2>Vælg en sikker adgangskode.</h2>

                        {this.props.resetStatus === AuthStatusEnum.Success ?
                            <span>Password changed, <Link to={'/account/login'}>proceed to login with your new password</Link></span> :

                            <>
                                <ErrorMessage error={this.props.resetError} />
                                <FieldValidation error={this.props.resetError} fieldname='SecurityToken' />
                                <FieldValidation error={this.props.resetError} fieldname='NewPassword' />

                                <TextField label='Adgangskode' type='password' value={this.state.password} onChange={this.updatePassword} />

                                <TextField label='Gentag adgangskode' type='password' value={this.state.passwordConfirmation} onChange={this.updatePasswordConfirmation} />

                                <Stack horizontal verticalAlign='center'>
                                    <PrimaryButton text='Godkend adgangskode' disabled={loading} onClick={this.handleResetPassword} />
                                    {loading ? <Spinner /> : null}
                                </Stack>
                            </>
                        }
                    </Stack>
                </Stack>
            </div>
        );
    }

    @boundMethod
    private handleResetPassword(e: React.MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();

        // Prevent multiple login requests onClick
        if (this.props.resetStatus === AuthStatusEnum.Process) {
            return;
        }

        let query = qs.parse(this.props.history.location.search);
        this.props.resetPasswordRequest(this.state.password, query['token'] as string);
    }

    @boundMethod
    private updatePassword(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        this.setState({
            password: (newValue || '').trim()
        });
    }

    @boundMethod
    private updatePasswordConfirmation(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void {
        this.setState({
            passwordConfirmation: (newValue || '').trim()
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.auth, actionCreators)(ResetPassword);