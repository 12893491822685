import { IField, IFieldCreateDTO, IFieldEditDTO } from '../store/Fields';
import { BaseService } from './base.service';

class FieldService extends BaseService {
    private static _angleService: FieldService;

    private constructor() {
        super();
    }

    public static get Instance(): FieldService {
        return this._angleService || (this._angleService = new this());
    }

    public async getFieldsAsync(organizationID: number, caseID: number): Promise<IField[]> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/fields`);
        return data as IField[];
    }

    public async getFieldAsync(organizationID: number, caseID: number, id: number): Promise<IField> {
        const { data } = await this.$http.get(`organizations/${organizationID}/cases/${caseID}/fields/${id}`);
        return data as IField;
    }

    public async createFieldAsync(organizationID: number, caseID: number, angle: IFieldCreateDTO): Promise<IField> {
        const { data } = await this.$http.put(`organizations/${organizationID}/cases/${caseID}/fields`, angle);
        return data;
    }

    public async editFieldAsync(organizationID: number, caseID: number, id: number, angle: IFieldEditDTO): Promise<IField> {
        const { data } = await this.$http.post(`organizations/${organizationID}/cases/${caseID}/fields/${id}`, angle);
        return data;
    }

    public async deleteFieldAsync(organizationID: number, caseID: number, id: number): Promise<number> {
        await this.$http.delete(`organizations/${organizationID}/cases/${caseID}/fields/${id}`);
        return id;
    }
}

export const FieldApi = FieldService.Instance;