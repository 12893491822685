import { Reducer } from 'redux';
import { ApiError } from '../types/ApiError';

export interface IActionType {
    NAMESPACE: string;
    REQUEST: string;
    RECEIVE_FAIL: string;
    RECEIVE_SUCCESS: string;
    REQUEST_DETAILS: string;
    RECEIVE_DETAILS_FAIL: string;
    RECEIVE_DETAILS_SUCCESS: string;
    ADD: string;
    ADD_FAIL: string;
    ADD_SUCCESS: string;
    EDIT: string;
    EDIT_FAIL: string;
    EDIT_SUCCESS: string;
    DELETE: string;
    DELETE_FAIL: string;
    DELETE_SUCCESS: string;
    RESET_STATE: string;
}

export interface IBaseEntity {
    id: number;
}

export interface IBaseState<T> {
    readonly isLoading: boolean;
    readonly items: T[];
    readonly loadError?: ApiError;

    readonly isLoadingDetails: boolean;
    readonly item?: T;
    readonly loadDetailsError?: ApiError;

    readonly isAdding: boolean;
    readonly addError?: ApiError;

    readonly isEditing: boolean;
    readonly editError?: ApiError;

    readonly isDeleting: boolean;
    readonly deleteError?: ApiError;
}

export interface IBaseAction<T> {
    readonly type: string;
    readonly items?: T[];
    readonly item?: T;
    readonly error?: ApiError;
}

export const createReducer = <TEntity extends IBaseEntity>(actionTypes: IActionType) => {
    const unloadedState: IBaseState<TEntity> = {
        isAdding: false,
        isDeleting: false,
        isEditing: false,
        isLoading: false,
        isLoadingDetails: false,
        items: [],
        addError: undefined,
        deleteError: undefined,
        editError: undefined,
        item: undefined,
        loadDetailsError: undefined,
        loadError: undefined
    };

    const reducer: Reducer<IBaseState<TEntity>> = (state: IBaseState<TEntity> | undefined, incomingAction: IBaseAction<TEntity>): IBaseState<TEntity> => {
        if (state === undefined) {
            return unloadedState;
        }

        // If current action is not pertinent to this reducer, skip remainder of checks
        if (!incomingAction.type.startsWith(actionTypes.NAMESPACE)) {
            return state;
        }

        switch (incomingAction.type) {
            case actionTypes.REQUEST:
                return {
                    ...state,
                    items: [],
                    isLoading: true,
                    loadError: undefined
                };
            case actionTypes.RECEIVE_FAIL:
                return {
                    ...state,
                    isLoading: false,
                    loadError: incomingAction.error
                };
            case actionTypes.RECEIVE_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    loadError: undefined,
                    items: incomingAction.items || []
                };

            case actionTypes.REQUEST_DETAILS:
                return {
                    ...state,
                    item: undefined,
                    isLoadingDetails: true,
                    loadDetailsError: undefined
                };
            case actionTypes.RECEIVE_DETAILS_FAIL:
                return {
                    ...state,
                    isLoadingDetails: false,
                    loadDetailsError: incomingAction.error
                };
            case actionTypes.RECEIVE_DETAILS_SUCCESS:
                return {
                    ...state,
                    isLoadingDetails: false,
                    loadDetailsError: undefined,
                    item: incomingAction.item
                };

            case actionTypes.ADD:
                return {
                    ...state,
                    isAdding: true,
                    addError: undefined
                };
            case actionTypes.ADD_FAIL:
                return {
                    ...state,
                    isAdding: false,
                    addError: incomingAction.error
                };
            case actionTypes.ADD_SUCCESS:

                let items = state.items;
                if (incomingAction.item !== undefined) {
                    items = [...items, incomingAction.item];
                }

                return {
                    ...state,
                    items: items,
                    isAdding: false,
                    addError: undefined
                };

            case actionTypes.EDIT:
                return {
                    ...state,
                    isEditing: true,
                    editError: undefined
                };
            case actionTypes.EDIT_FAIL:
                return {
                    ...state,
                    isEditing: false,
                    editError: incomingAction.error
                };
            case actionTypes.EDIT_SUCCESS:
                return {
                    ...state,
                    items: [...state.items.map(c => c.id === incomingAction.item!.id ? incomingAction.item! : c)],
                    isEditing: false,
                    editError: undefined
                };

            case actionTypes.DELETE:
                return {
                    ...state,
                    isDeleting: true,
                    deleteError: undefined
                };
            case actionTypes.DELETE_FAIL:
                return {
                    ...state,
                    isDeleting: false,
                    deleteError: incomingAction.error
                };
            case actionTypes.DELETE_SUCCESS:
                return {
                    ...state,
                    items: state.items.filter(c => c.id !== incomingAction.item!.id),
                    isDeleting: false,
                    deleteError: undefined
                };

            default:
                break;
        }
        return state;
    }

    return reducer;
}
