import { DefaultButton, Dropdown, IDropdownOption, Panel, PanelType, PrimaryButton, Stack, StackItem, TextField } from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { ErrorMessage, FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import { ICase } from '../../store/Cases';
import { actionCreators, reducer } from '../../store/OrganizationUsers';
import { IUser, IUserEditDTO } from '../../store/Users';

type EditUserProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onSaved: () => void;
    onDismissed: () => void;
    organizationID: number;
    cases: ICase[];
    user?: IUser;
};

type EditUserState = {
    editUser: IUserEditDTO;
};

class EditUser extends React.Component<EditUserProps, EditUserState> {

    constructor(props: EditUserProps) {
        super(props);

        this.state = {
            editUser: {
                email: '',
                userType: 'Editor',
                caseAccess: {}
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: EditUserProps) {
        if(nextProps.user === undefined){
            this.setState({
                editUser: {
                    email: '',
                    userType: 'Editor',
                    caseAccess: {}
                }
            });
        }else{

            if (this.props.user === undefined || this.props.user.id !== nextProps.user.id) {
                this.setState({
                    editUser: {
                        email: nextProps.user.email,
                        userType: nextProps.user.userType,
                        caseAccess: nextProps.user.caseAccess
                    }
                });
            }
        }
    }

    public render(): React.ReactNode {
        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='Edit user' onRenderFooterContent={() => <StackItem>
                <PrimaryButton disabled={this.props.isAdding} onClick={this._save} text='Save' style={{ marginRight: '8px' }} />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </StackItem>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <ErrorMessage error={this.props.addError} />

                    <StackItem>
                        <TextField label='Email' type='email' value={this.state.editUser.email} onChange={(e, value) => this.setState({ editUser: { ...this.state.editUser, email: value || '' } })} />
                        <FieldValidation error={this.props.addError} fieldname='email' />
                    </StackItem>
                    
                    <StackItem>
                        <Dropdown 
                            label='Type' 
                            selectedKey={this.state.editUser.userType} 
                            onChange={this._onTypeChange}
                            options={[
                                { key: 'Administrator', text: 'Administrator' },
                                { key: 'Editor', text: 'Editor' },
                                { key: 'Maintainer', text: 'Maintainer' }
                            ]}/>
                        <FieldValidation error={this.props.addError} fieldname='UserType' />
                    </StackItem>
                    
                    {(this.state.editUser.userType === 'Editor' || this.state.editUser.userType === 'Maintainer') ?
                        <>
                            {this.props.cases.map(e => <StackItem key={e.id}>
                                    <Dropdown label={e.name} 
                                        selectedKey={this.state.editUser.caseAccess[e.id] || ''}
                                        onChange={(_e, option) => this._onAccessChange(e, option!.key.toString())}
                                        options={[
                                            {key: '', text: 'No access'},
                                            {key: 'ReadOnly', text: 'Read only'},
                                            {key: 'ReadWrite', text: 'Read write'}
                                        ]} />
                                </StackItem>)}
                        <FieldValidation error={this.props.addError} fieldname='UserType' />
                    </> : null}
                </Stack>
            </Panel>
        );
    }

    @boundMethod
    private _onAccessChange(_case: ICase, key: string): void {
        if(key === ''){
            let access = {...this.state.editUser.caseAccess};
            delete access[_case.id];
            this.setState({ editUser: { ...this.state.editUser, caseAccess: access}});
        }else{
            this.setState({ editUser: { ...this.state.editUser, caseAccess: {...this.state.editUser.caseAccess, [_case.id]: key as  'ReadOnly' | 'ReadWrite'} }});
        }
    }

    @boundMethod
    private _onTypeChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined): void {
        if(option){
            this.setState({ editUser: { ...this.state.editUser, userType: option.key.toString() as 'Administrator' | 'Editor' | 'Maintainer' } })
        }
    }

    @boundMethod
    private _save(): void {
        if(this.props.user){
            this.props.editUser(this.props.organizationID, this.props.user, this.state.editUser, () => { this.props.onSaved(); });
        }
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.users, actionCreators)(EditUser);