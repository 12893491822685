import { AppThunkAction } from '.';
import { SectionApi } from '../api';
import { ApiError } from '../types/ApiError';
import { createReducer, IActionType, IBaseAction } from './BaseStore';

export const ActionType: IActionType = {
    NAMESPACE: 'sections',
    REQUEST: 'sections/fetch',
    RECEIVE_FAIL: 'sections/fetchFail',
    RECEIVE_SUCCESS: 'sections/fetchSuccess',
    REQUEST_DETAILS: 'sections/fetchSingle',
    RECEIVE_DETAILS_FAIL: 'sections/fetchSingleFail',
    RECEIVE_DETAILS_SUCCESS: 'sections/fetchSingleSuccess',
    ADD: 'sections/add',
    ADD_FAIL: 'sections/add_fail',
    ADD_SUCCESS: 'sections/add_success',
    EDIT: 'sections/edit',
    EDIT_FAIL: 'sections/edit_fail',
    EDIT_SUCCESS: 'sections/edit_success',
    DELETE: 'sections/delete',
    DELETE_FAIL: 'sections/deleteFail',
    DELETE_SUCCESS: 'sections/deleteSuccess',
    RESET_STATE: 'sections/resetState'
};

export interface ISection {
    id: number;
    name: string;
    animationID?: number;
}

export interface ISectionCreateDTO {
    name: string;
}

export interface ISectionEditDTO {
    name: string;
    animationID?: number;
}

export const reducer = createReducer<ISection>(ActionType);

export const actionCreators = {
    requestSections: (organizationID: number, caseID: number): AppThunkAction<IBaseAction<ISection>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST });

        SectionApi.getSectionsAsync(organizationID, caseID).then(data => {
            dispatch({ type: ActionType.RECEIVE_SUCCESS, items: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_FAIL, error: reason });
        });
    },

    requestSection: (organizationID: number, caseID: number, id: number): AppThunkAction<IBaseAction<ISection>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST_DETAILS });

        SectionApi.getSectionAsync(organizationID, caseID, id).then(data => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_SUCCESS, item: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_FAIL, error: reason });
        });
    },

    addSection: (organizationID: number, caseID: number, item: ISectionCreateDTO, callback: (c: ISection) => void = () => { }): AppThunkAction<IBaseAction<ISection>> => (dispatch, getState) => {
        dispatch({ type: ActionType.ADD });

        SectionApi.createSectionAsync(organizationID, caseID, item).then(data => {
            dispatch({ type: ActionType.ADD_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.ADD_FAIL, error: reason });
        });
    },

    editSection: (organizationID: number, caseID: number, section: ISection, item: ISectionEditDTO, callback: (c: ISection) => void = () => { }): AppThunkAction<IBaseAction<ISection>> => (dispatch, getState) => {
        dispatch({ type: ActionType.EDIT });

        SectionApi.editSectionAsync(organizationID, caseID, section.id, item).then(data => {
            dispatch({ type: ActionType.EDIT_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.EDIT_FAIL, error: reason });
        });
    },

    deleteSection: (organizationID: number, caseID: number, section: ISection, callback: () => void = () => { }): AppThunkAction<IBaseAction<ISection>> => (dispatch, getState) => {
        dispatch({ type: ActionType.DELETE });

        SectionApi.deleteSectionAsync(organizationID, caseID, section.id).then(data => {
            dispatch({ type: ActionType.DELETE_SUCCESS, item: section });
            callback();
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.DELETE_FAIL, error: reason });
        });
    }
};