import { AppThunkAction } from '.';
import { FieldApi } from '../api';
import { ApiError } from '../types/ApiError';
import { createReducer, IActionType, IBaseAction } from './BaseStore';

export const ActionType: IActionType = {
    NAMESPACE: 'fields',
    REQUEST: 'fields/fetch',
    RECEIVE_FAIL: 'fields/fetchFail',
    RECEIVE_SUCCESS: 'fields/fetchSuccess',
    REQUEST_DETAILS: 'fields/fetchSingle',
    RECEIVE_DETAILS_FAIL: 'fields/fetchSingleFail',
    RECEIVE_DETAILS_SUCCESS: 'fields/fetchSingleSuccess',
    ADD: 'fields/add',
    ADD_FAIL: 'fields/add_fail',
    ADD_SUCCESS: 'fields/add_success',
    EDIT: 'fields/edit',
    EDIT_FAIL: 'fields/edit_fail',
    EDIT_SUCCESS: 'fields/edit_success',
    DELETE: 'fields/delete',
    DELETE_FAIL: 'fields/deleteFail',
    DELETE_SUCCESS: 'fields/deleteSuccess',
    RESET_STATE: 'fields/resetState'
};

export interface IField {
    id: number;
    name: string;
    type: 'text' | 'number' | 'area' | 'net_area' | 'currency' | 'rent' | 'media' ;
    justification: 'right' | 'left' | 'center';
}

export interface IFieldCreateDTO {
    name: string;
    type: 'text' | 'number' | 'area' | 'net_area' | 'currency' | 'rent' | 'media' ;
    justification: 'right' | 'left' | 'center';
}

export interface IFieldEditDTO {
    name: string;
    type: 'text' | 'number' | 'area' | 'net_area' | 'currency' | 'rent' | 'media' ;
    justification: 'right' | 'left' | 'center';
}

export const reducer = createReducer<IField>(ActionType);

export const actionCreators = {
    requestFields: (organizationID: number, caseID: number): AppThunkAction<IBaseAction<IField>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST });

        FieldApi.getFieldsAsync(organizationID, caseID).then(data => {
            dispatch({ type: ActionType.RECEIVE_SUCCESS, items: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_FAIL, error: reason });
        });
    },

    requestField: (organizationID: number, caseID: number, id: number): AppThunkAction<IBaseAction<IField>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST_DETAILS });

        FieldApi.getFieldAsync(organizationID, caseID, id).then(data => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_SUCCESS, item: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_FAIL, error: reason });
        });
    },

    addField: (organizationID: number, caseID: number, item: IFieldCreateDTO, callback: (c: IField) => void = () => { }): AppThunkAction<IBaseAction<IField>> => (dispatch, getState) => {
        dispatch({ type: ActionType.ADD });

        FieldApi.createFieldAsync(organizationID, caseID, item).then(data => {
            dispatch({ type: ActionType.ADD_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.ADD_FAIL, error: reason });
        });
    },

    editField: (organizationID: number, caseID: number, angle: IField, item: IFieldEditDTO, callback: (c: IField) => void = () => { }): AppThunkAction<IBaseAction<IField>> => (dispatch, getState) => {
        dispatch({ type: ActionType.EDIT });

        FieldApi.editFieldAsync(organizationID, caseID, angle.id, item).then(data => {
            dispatch({ type: ActionType.EDIT_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.EDIT_FAIL, error: reason });
        });
    },

    deleteField: (organizationID: number, caseID: number, angle: IField, callback: () => void = () => { }): AppThunkAction<IBaseAction<IField>> => (dispatch, getState) => {
        dispatch({ type: ActionType.DELETE });

        FieldApi.deleteFieldAsync(organizationID, caseID, angle.id).then(data => {
            dispatch({ type: ActionType.DELETE_SUCCESS, item: angle });
            callback();
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.DELETE_FAIL, error: reason });
        });
    }
};