import { boundMethod } from 'autobind-decorator';
import { DefaultButton, Panel, PanelType, PrimaryButton, Stack, StackItem, TextField } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { ErrorMessage, FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, IUserCreateDTO, reducer } from '../../store/Users';

type NewUserProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onUserAdded: () => void;
    onDismissed: () => void;
};

type NewUserState = {
    newUser: IUserCreateDTO;
};

class NewUser extends React.Component<NewUserProps, NewUserState> {

    constructor(props: NewUserProps) {
        super(props);

        this.state = {
            newUser: {
                email: '',
                userType: 'SuperAdministrator',
                caseAccess: {}
            }
        };
    }

    public render(): React.ReactNode {
        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='New user' onRenderFooterContent={() => <StackItem>
                <PrimaryButton disabled={this.props.isAdding} onClick={this._addUser} text='Create' style={{ marginRight: '8px' }} />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </StackItem>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <ErrorMessage error={this.props.addError} />

                    <StackItem>
                        <TextField label='Email' type='email' value={this.state.newUser.email} onChange={(e, value) => this.setState({ newUser: { ...this.state.newUser, email: value || '' } })} />
                        <FieldValidation error={this.props.addError} fieldname='email' />
                    </StackItem>
                </Stack>
            </Panel>
        );
    }

    @boundMethod
    private _addUser(): void {
        this.props.addUser(this.state.newUser, () => { this.props.onUserAdded(); });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.users, actionCreators)(NewUser);