import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import { ApplicationState } from '../../store';

class PrivateRoute extends React.Component<{ isAuthenticated: boolean, exact?: boolean, path: string, component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> }> {
    render() {
        let { path, exact, isAuthenticated } = this.props;

        return (
            <Route path={path} exact={exact} render={(props) => (
                isAuthenticated === true
                    ? <this.props.component  {...props} />
                    : <Redirect to={{ pathname: '/account/login', search: `return=${props.location.pathname}` }} />
            )} />
        );
    }
}

// Map only necessary IApplicationState to NavBar props
const mapStateToProps = (state: ApplicationState) => {
    return {
        isAuthenticated: state.auth!.isAuthenticated
    };
};

// Wire up the React component to the Redux store
export default connect(mapStateToProps)(PrivateRoute);