import {
  Checkbox,
  DefaultButton,
  Dropdown,
  IDropdownOption,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';

import { ErrorMessage, FieldValidation } from '../../components/controls';
import MediaPicker from '../../components/controls/MediaPicker';
import { ApplicationState } from '../../store';
import { IField } from '../../store/Fields';
import { ISection } from '../../store/Sections';
import {
  actionCreators,
  IUnit,
  IUnitEditDTO,
  reducer,
} from '../../store/Units';

type EditUnitProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onSaved: () => void;
    onDismissed: () => void;
    organizationID: number;
    caseID: number;
    fields: IField[];
    sections: ISection[];
    unit?: IUnit;
};

type EditUnitState = {
    editUnit: IUnitEditDTO;
};

class EditUnit extends React.Component<EditUnitProps, EditUnitState> {

    constructor(props: EditUnitProps) {
        super(props);

        this.state = {
            editUnit: {
                maskID: undefined,
                fieldValues: {},
                floorplans: [],
                images: [],
                sections: [],
                state: 'ForSale',
                url: '',
                dataSheetID: undefined,
                materialListID: undefined,
                previewID: undefined
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: EditUnitProps) {
        if(nextProps.unit === undefined){
            this.setState({
                editUnit: {
                    maskID: undefined,
                    fieldValues: {},
                    floorplans: [],
                    images: [],
                    sections: [],
                    state: 'ForSale',
                    url: '',
                    dataSheetID: undefined,
                    materialListID: undefined,
                    previewID: undefined
                }
            });
        }else{

            if (this.props.unit === undefined || this.props.unit.id !== nextProps.unit.id) {
                this.setState({
                    editUnit: {
                        maskID: nextProps.unit.maskID,
                        fieldValues: nextProps.unit.fieldValues,
                        floorplans: nextProps.unit.floorplans,
                        images: nextProps.unit.images,
                        sections: nextProps.unit.sections,
                        state: nextProps.unit.state,
                        url: nextProps.unit.url,
                        dataSheetID: nextProps.unit.dataSheetID,
                        materialListID: nextProps.unit.materialListID,
                        previewID: nextProps.unit.previewID
                    }
                });
            }
        }
    }

    public render(): React.ReactNode {
        if(this.props.fields === undefined){
            return null;
        }

        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='Rediger bolig' onRenderFooterContent={() => <StackItem>
                <PrimaryButton disabled={this.props.isAdding} onClick={this._save} text='Save' style={{ marginRight: '8px' }} />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </StackItem>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <ErrorMessage error={this.props.editError} />

                    <StackItem>
                        <Dropdown
                            label='Boligens status'
                            selectedKey={this.state.editUnit.state}
                            onChange={this._onStateChange}
                            options={[
                                { key: 'ConditionallySold', text: 'Betinget solgt' },
                                { key: 'Reserved', text: 'Reserveret' },
                                { key: 'CommonArea', text: 'Fællesareal' },
                                { key: 'PurchaseAgreement', text: 'Købsaftale' },
                                { key: 'ForSale', text: 'Til salg' },
                                { key: 'ConditionallySold', text: 'Betinget solgt' },
                                { key: 'Sold', text: 'Solgt' },
                                { key: 'Vacant', text: 'Ledig' },
                                { key: 'RentedOut', text: 'Udlejet' },
                                { key: 'Deactivated', text: 'Deaktiveret' }
                            ]} />
                        <FieldValidation error={this.props.editError} fieldname='State' />
                    </StackItem>

                    <StackItem>
                        <TextField label='Mask ID (unit-[MaskID])' value={this.state.editUnit.maskID || ''} onChange={(e, value) => this.setState({ editUnit: { ...this.state.editUnit, maskID: value || '' } })} />
                        <FieldValidation error={this.props.editError} fieldname='MaskID' />
                    </StackItem>



                    <StackItem>
                        <TextField label='Direkte maske-link (URL)' value={this.state.editUnit.url || ''} onChange={(e, value) => this.setState({ editUnit: { ...this.state.editUnit, url: value || '' } })} />
                        <FieldValidation error={this.props.editError} fieldname='Url' />
                    </StackItem>

                    <StackItem>
                        <Label>Boligen tilfører følgende Section(s)</Label>
                        {this.props.sections.map(e => <Checkbox key={e.id} label={e.name} checked={this.state.editUnit.sections.indexOf(e.id) !== -1} onChange={(_e, checked) => this._onSectionChange(e, checked!)} />)}
                    </StackItem>
        
                    <StackItem>
                        <MediaPicker 
                            label='Billeder'
                            multiple
                            organizationID={this.props.organizationID} 
                            caseID={this.props.caseID} 
                            value={this.state.editUnit.images} 
                            onChange={(value) => this.setState({ editUnit: { ...this.state.editUnit, images: value } })} />
                        <FieldValidation error={this.props.editError} fieldname='Images' />
                    </StackItem>
        
                    <StackItem>
                        <MediaPicker 
                            label='Plantegning (PDF)' 
                            multiple
                            organizationID={this.props.organizationID} 
                            caseID={this.props.caseID} 
                            value={this.state.editUnit.floorplans} 
                            onChange={(value) => this.setState({ editUnit: { ...this.state.editUnit, floorplans: value } })} />
                        <FieldValidation error={this.props.editError} fieldname='Floorplans' />
                    </StackItem>
        
                    <StackItem>
                        <MediaPicker 
                            label='Preview (billede i popup)' 
                            organizationID={this.props.organizationID} 
                            caseID={this.props.caseID} 
                            value={this.state.editUnit.previewID ? [this.state.editUnit.previewID] : []} 
                            onChange={(value) => this.setState({ editUnit: { ...this.state.editUnit, previewID: value.length === 0 ? undefined : value[0] } })} />
                        <FieldValidation error={this.props.editError} fieldname='RenderingID' />
                    </StackItem>
                    
                    <StackItem>
                        <MediaPicker 
                            label='Materialeliste (PDF)' 
                            organizationID={this.props.organizationID} 
                            caseID={this.props.caseID} 
                            value={this.state.editUnit.materialListID ? [this.state.editUnit.materialListID] : []} 
                            onChange={(value) => this.setState({ editUnit: { ...this.state.editUnit, materialListID: value.length === 0 ? undefined : value[0] } })} />
                        <FieldValidation error={this.props.editError} fieldname='MaterialListID' />
                    </StackItem>
        
                    <StackItem>
                        <MediaPicker 
                            label='Øvrige data (PDF)' 
                            organizationID={this.props.organizationID} 
                            caseID={this.props.caseID} 
                            value={this.state.editUnit.dataSheetID ? [this.state.editUnit.dataSheetID] : []} 
                            onChange={(value) => this.setState({ editUnit: { ...this.state.editUnit, dataSheetID: value.length === 0 ? undefined : value[0] } })} />
                        <FieldValidation error={this.props.editError} fieldname='DataSheetID' />
                    </StackItem>

                    {this.props.fields.map(e => {
                        if(e.type !== 'media'){
                            return <StackItem key={e.id}>
                                <TextField label={e.name} value={this.state.editUnit.fieldValues[e.id] || ''} onChange={(_e, value) => this.setState({ editUnit: { ...this.state.editUnit, fieldValues: {...this.state.editUnit.fieldValues,  [e.id]: (value || '') }} })} />
                            </StackItem>;
                        }

                        return null;
                    })}
                    <FieldValidation error={this.props.addError} fieldname='FieldValues' />
                </Stack>
            </Panel>
        );
    }


    @boundMethod
    private _onSectionChange(section: ISection, selected: boolean): void {
        if(selected){
            this.setState({editUnit: {...this.state.editUnit, sections: [...this.state.editUnit.sections.filter(e => e !== section.id), section.id]}})
        }else{
            this.setState({editUnit: {...this.state.editUnit, sections: this.state.editUnit.sections.filter(e => e !== section.id)}})
        }
    }

    @boundMethod
    private _onStateChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined): void {
        if(option){
            this.setState({ editUnit: { ...this.state.editUnit, state: option.key.toString() as 'ForSale' | 'Reserved' | 'Sold' | 'Vacant' | 'RentedOut' | 'ConditionallySold' | 'Deactivated'} })
        }
    }

    @boundMethod
    private _save(): void {
        if(this.props.unit){
            this.props.editUnit(this.props.organizationID, this.props.caseID, this.props.unit, this.state.editUnit, () => { this.props.onSaved(); });
        }
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.units, actionCreators)(EditUnit);