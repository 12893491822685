import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';

import { FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import {
  actionCreators,
  IField,
  IFieldEditDTO,
  reducer,
} from '../../store/Fields';

type EditFieldProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onEdited: () => void;
    onDismissed: () => void;
    field?: IField;
    organizationID: number;
    caseID: number;
};

type EditFieldState = {
    editField: IFieldEditDTO;
};

class EditField extends React.Component<EditFieldProps, EditFieldState> {

    constructor(props: EditFieldProps) {
        super(props);

        this.state = {
            editField: {
                name: '',
                type: 'text',
                justification: 'right'
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: EditFieldProps) {
        if(nextProps.field === undefined){
            this.setState({
                editField: {
                    name: '',
                    type: 'text',
                    justification: 'right'
                }
            });
        }else{

            if (this.props.field === undefined || this.props.field.id !== nextProps.field.id) {
                this.setState({
                    editField: {
                        name: nextProps.field.name,
                        type: nextProps.field.type,
                        justification: nextProps.field.justification
                    }
                });
            }
        }

    }

    public render(): React.ReactNode {
        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='Edit field' onRenderFooterContent={() => <div>
                <PrimaryButton disabled={this.props.isEditing} onClick={this._edit} style={{ marginRight: '8px' }} text='Save' />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </div>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                        <TextField label='Name' value={this.state.editField.name} onChange={(e, value) => this.setState({ editField: { ...this.state.editField, name: value || '' } })} />
                        <FieldValidation error={this.props.addError} fieldname='Name' />
                    </StackItem>
                    
                    <StackItem>
                        <Dropdown 
                            label='Type' 
                            selectedKey={this.state.editField.type} 
                            onChange={this._onTypeChange}
                            options={[
                                { key: 'text', text: 'Text' },
                                { key: 'number', text: 'Number' },
                                { key: 'area', text: 'Area' },
                                { key: 'net_area', text: 'Net Area' },
                                { key: 'currency', text: 'Currency' },
                                { key: 'rent', text: 'Rent' },
                                { key: 'media', text: 'Media' }
                            ]}/>
                        <FieldValidation error={this.props.addError} fieldname='Type' />
                    </StackItem>

                    <StackItem>
                        <Dropdown
                            label='Justification'
                            selectedKey={this.state.editField.justification}
                            onChange={this._onJustificationChange}
                            options={[
                                { key: 'right', text: 'Right' },
                                { key: 'left', text: 'Left' },
                                { key: 'center', text: 'Center' }
                            ]} />
                        <FieldValidation error={this.props.addError} fieldname='Justification' />
                    </StackItem>
                </Stack>
            </Panel>
        );
    }

    @boundMethod
    private _onTypeChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined): void {
        if(option){
            this.setState({ editField: { ...this.state.editField, type: option.key.toString() as 'text' | 'number' | 'area' | 'net_area' | 'currency' | 'rent' | 'media' } })
        }
    }

    @boundMethod
    private _onJustificationChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined): void {
        if (option) {
            this.setState({ editField: { ...this.state.editField, justification: option.key.toString() as 'left' | 'right' | 'center' } })
        }
    }

    @boundMethod
    private _edit(): void {
        if (this.props.editField && this.props.field) {
            this.props.editField(this.props.organizationID, this.props.caseID, this.props.field, this.state.editField, () => { this.props.onEdited(); });
        }
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.fields, actionCreators)(EditField);