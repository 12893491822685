import { Dropdown, DefaultButton, Panel, PanelType, PrimaryButton, Stack, StackItem, IDropdownOption } from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';
import { FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, IMaintainer, IMaintainerEditDTO, reducer } from '../../store/Maintainers';

type EditMaintainerProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onEdited: () => void;
    onDismissed: () => void;
    maintainer?: IMaintainer;
    organizationID: number;
    caseID: number;
};

type EditmaintainerState = {
    editMaintainer: IMaintainerEditDTO;
};

class EditMaintainer extends React.Component<EditMaintainerProps, EditmaintainerState> {

    constructor(props: EditMaintainerProps) {
        super(props);

        this.state = {
            editMaintainer: {
                access: 'ReadOnly'
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: EditMaintainerProps) {
        if(nextProps.maintainer === undefined){
            this.setState({
                editMaintainer: {
                    access: 'ReadOnly'
                }
            });
        }else{
            if (this.props.maintainer === undefined || this.props.maintainer.id !== nextProps.maintainer.id) {
                console.log(nextProps.maintainer);
                this.setState({
                    editMaintainer: {
                        access: nextProps.maintainer.access
                    }
                });
            }
        }
    }

    public render(): React.ReactNode {
        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='Edit maintainer' onRenderFooterContent={() => <div>
                <PrimaryButton disabled={this.props.isEditing} onClick={this._edit} style={{ marginRight: '8px' }} text='Save' />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </div>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                        <Dropdown 
                            label='Access' 
                            selectedKey={this.state.editMaintainer.access} 
                            onChange={this._onAccessChange}
                            options={[
                                {key: 'ReadOnly', text: 'Read only'},
                                {key: 'ReadWrite', text: 'Read write'}
                            ]}/>
                        <FieldValidation error={this.props.editError} fieldname='Access' />
                    </StackItem>
                </Stack>
            </Panel>
        );
    }

    @boundMethod
    private _onAccessChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined): void {
        if(option){
            this.setState({ editMaintainer: { ...this.state.editMaintainer, access: option.key.toString() as 'ReadOnly' | 'ReadWrite' } })
        }
    }

    @boundMethod
    private _edit(): void {
        if (this.props.editMaintainer && this.props.maintainer) {
            this.props.editMaintainer(this.props.organizationID, this.props.caseID, this.props.maintainer, this.state.editMaintainer, () => { this.props.onEdited(); });
        }
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.maintainers, actionCreators)(EditMaintainer);