import { IconButton, Stack, StackItem } from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import { Checkbox, DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, PrimaryButton, TextField } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { actionCreators, reducer } from '../../store/Fields';

type FilterPickerProps = ReturnType<typeof reducer> & typeof actionCreators & {
    organizationID: number;
    caseID: number;
    fields: string;
    onChange: (value:string) => void
};

type FieldID = number | 'status';

type Field = {
    fieldID: FieldID;
    autoGenerate: boolean;
    values: {label: string, value: string}[]
}

type FilterPickerState = {
    editorOpen: boolean;
    fields: Field[];
    selectedField: FieldID;
};

class FilterPicker extends React.Component<FilterPickerProps, FilterPickerState> {

    constructor(props: FilterPickerProps) {
        super(props);

        this.state = {
            editorOpen: false,
            fields: JSON.parse(props.fields),
            selectedField: 0
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: FilterPickerProps) {
        if(nextProps.fields === this.props.fields){
            this.setState({
                fields: JSON.parse(nextProps.fields)
            });
        }
    }

    public componentDidMount(): void {
        if(!this.props.isLoading){
            this.props.requestFields(this.props.organizationID, this.props.caseID);
        }
    }

    @boundMethod
    private _togglePickerOpen() {
        this.setState({
            editorOpen: !this.state.editorOpen
        });
    }

    public render(): React.ReactNode {
        return (
            <>
                <DefaultButton text={`${this.state.fields.length} fields`} onClick={() => this._togglePickerOpen()} />

                <Dialog
                    hidden={!this.state.editorOpen}
                    onDismiss={this._togglePickerOpen}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Pick filters'
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 600 } }
                    }}
                >
                    
                    <Stack tokens={{ childrenGap: 20 }}>
                    {this.state.fields.map(field => {
                        let fieldItem = this.props.items.find(e => e.id === field.fieldID);
                        return <StackItem key={field.fieldID}>
                            {fieldItem ? <h3>{fieldItem.name}</h3> : <h3>{field.fieldID}</h3>}
                            <Checkbox checked={field.autoGenerate} label='Autogenerate options' onChange={(e, checked) => this._toggleAutoGenerate(field.fieldID, !!checked)} />
                            {field.autoGenerate ? null : <div>
                                {field.values.map((value, index) => {
                                    return <Stack key={field.values.length * 100 + index} horizontal tokens={{ childrenGap: 20 }}>
                                        <StackItem grow={1}>
                                            <TextField placeholder='label' value={value.label} onChange={(e, val) => this._editFieldValue(field.fieldID, index, val || '', value.value) } />
                                        </StackItem>
                                        <StackItem grow={1}>
                                            <TextField placeholder='value' value={value.value} onChange={(e, val) => this._editFieldValue(field.fieldID, index, value.label, val || '') } />
                                        </StackItem>
                                        <StackItem grow={0}>
                                            <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => this._removeFieldValue(field.fieldID, index)} />
                                        </StackItem>
                                    </Stack>
                                })}
                                <DefaultButton onClick={() => this._addFieldValue(field.fieldID)}>Add filter option</DefaultButton>
                            </div> }
                            <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => this._removeField(field.fieldID)} />
                            <hr />
                        </StackItem>;
                    })}
                    </Stack>

                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <StackItem grow={2}>
                            <Dropdown options={[{key: 'status', text: 'Status'}, ...this.props.items.map(e => {return {key: e.id, text: e.name}})]} selectedKey={this.state.selectedField} onChange={(e, option) => {
                                if(option){
                                    this.setState({
                                        selectedField: option.key as FieldID
                                    });
                                }
                            }} />
                        </StackItem>
                        <StackItem>
                            <DefaultButton onClick={() => this._addField()}>Add</DefaultButton>
                        </StackItem>
                    </Stack>

                    <DialogFooter>
                        <PrimaryButton onClick={this._save} text='OK' />
                    </DialogFooter>
                </Dialog>
            </>
        );
    }

    @boundMethod
    private _toggleAutoGenerate(field: FieldID, autogenerate: boolean): void {
        this.setState({
            fields: this.state.fields.map(e => e.fieldID === field ? {...e, autoGenerate: autogenerate} : e)
        }, () => this.props.onChange(JSON.stringify(this.state.fields)));
    }

    @boundMethod
    private _editFieldValue(field: FieldID, index: number, label: string, value: string): void {
        this.setState({
            fields: this.state.fields.map(e => e.fieldID === field ? {...e, values: e.values.map((val, i) => i === index ? {label: label, value: value} : val)} : e)
        }, () => this.props.onChange(JSON.stringify(this.state.fields)));
    }

    @boundMethod
    private _addFieldValue(field: FieldID): void {
        this.setState({
            fields: this.state.fields.map(e => e.fieldID === field ? {...e, values: [...e.values, {label: '', value: ''}]} : e)
        }, () => this.props.onChange(JSON.stringify(this.state.fields)));
    }

    @boundMethod
    private _removeFieldValue(field: FieldID, index: number): void {
        this.setState({
            fields: this.state.fields.map(e => e.fieldID === field ? {...e, values: e.values.filter((val, i) => i !== index)} : e)
        }, () => this.props.onChange(JSON.stringify(this.state.fields)));
    }

    @boundMethod
    private _removeField(field: FieldID): void {
        this.setState({
            fields: this.state.fields.filter(e => e.fieldID !== field)
        }, () => this.props.onChange(JSON.stringify(this.state.fields)));
    }

    @boundMethod
    private _addField(): void {
        if(!this.state.fields.some(e => e.fieldID === this.state.selectedField)){
            this.setState({
                fields: [...this.state.fields, {fieldID: this.state.selectedField, autoGenerate: true, values: []}]
            }, () => this.props.onChange(JSON.stringify(this.state.fields)));
        }
    }

    @boundMethod
    private _save(): void {
        this.setState({
            editorOpen: false
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.fields, actionCreators)(FilterPicker);