import { boundMethod } from 'autobind-decorator';
import { CommandBar, Stack, StackItem } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { DataTable, ErrorMessage } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, IApiToken, reducer } from '../../store/ApiTokens';
import NewApiKey from './NewApiKey';

type ApiKeysProps = ReturnType<typeof reducer> & typeof actionCreators & RouteComponentProps<{ organizationID: string }>;

type ApiKeysState = {
    showCreatePanel: boolean;
};

class ApiKeys extends React.Component<ApiKeysProps, ApiKeysState> {

    constructor(props: ApiKeysProps) {
        super(props);

        this.state = {
            showCreatePanel: false
        };
    }

    public componentDidMount(): void {
        this.props.requestApiTokens(parseInt(this.props.match.params.organizationID));
    }

    public render(): React.ReactNode {
        return (
            <>
                <NewApiKey organizationID={parseInt(this.props.match.params.organizationID)} isOpen={this.state.showCreatePanel} onDismissed={this._hideCreatePanel} onAdded={this._hideCreatePanel} />

                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                        <CommandBar
                            items={[
                                {
                                    key: 'Add',
                                    name: 'Create new api key',
                                    iconProps: {
                                        iconName: 'Add'
                                    },
                                    onClick: () => this._showCreatePanel()
                                },
                            ]}
                        />
                    </StackItem>

                    <StackItem>
                        <ErrorMessage error={this.props.loadError} />

                        <DataTable<IApiToken> paginated items={this.props.items} isLoading={this.props.isLoading} coloumns={[
                            {
                                key: 'name',
                                name: 'Name',
                                fieldName: 'name',
                                minWidth: 210,
                                maxWidth: 1000,
                                isResizable: true,
                                isSortable: true,
                            }
                        ]} />
                    </StackItem>
                </Stack>
            </>
        );
    }

    @boundMethod
    private _showCreatePanel() {
        this.setState({ showCreatePanel: true });
    }

    @boundMethod
    private _hideCreatePanel() {
        this.setState({
            showCreatePanel: false,
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.apiTokens, actionCreators)(ApiKeys);