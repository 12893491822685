import { boundMethod } from 'autobind-decorator';
import { DefaultButton, Panel, PanelType, PrimaryButton, Stack, StackItem, TextField } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, IOrganization, IOrganizationEditDTO, reducer } from '../../store/Organizations';

type EditOrganizationProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onEdited: () => void;
    onDismissed: () => void;
    organization?: IOrganization;
};

type EditOrganizationState = {
    editOrganization: IOrganizationEditDTO;
};

class EditOrganization extends React.Component<EditOrganizationProps, EditOrganizationState> {

    constructor(props: EditOrganizationProps) {
        super(props);

        this.state = {
            editOrganization: {
                name: ''
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: EditOrganizationProps) {
        if(nextProps.organization === undefined){
            this.setState({
                editOrganization: {
                    name: ''
                }
            });
        }else{

            if (this.props.organization === undefined || this.props.organization.id !== nextProps.organization.id) {
                this.setState({
                    editOrganization: {
                        name: nextProps.organization.name
                    }
                });
            }
        }

    }

    public render(): React.ReactNode {
        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='Edit organization' onRenderFooterContent={() => <div>
                <PrimaryButton disabled={this.props.isEditing} onClick={this._editOrganization} style={{ marginRight: '8px' }} text='Save' />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </div>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                        <TextField label='Name' value={this.state.editOrganization.name} onChange={(e, value) => this.setState({ editOrganization: { name: value || '' } })} />
                        <FieldValidation error={this.props.addError} fieldname='Name' />
                    </StackItem>
                </Stack>
            </Panel>
        );
    }

    @boundMethod
    private _editOrganization(): void {
        if (this.props.organization) {
            this.props.editOrganization(this.props.organization, this.state.editOrganization, () => { this.props.onEdited(); });
        }
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.organizations, actionCreators)(EditOrganization);