import { IOrganization, IOrganizationCreateDTO, IOrganizationEditDTO } from '../store/Organizations';
import { BaseService } from './base.service';

class OrganizationService extends BaseService {
    private static _angleService: OrganizationService;

    private constructor() {
        super();
    }

    public static get Instance(): OrganizationService {
        return this._angleService || (this._angleService = new this());
    }

    public async getOrganizationsAsync(): Promise<IOrganization[]> {
        const { data } = await this.$http.get(`organizations`);
        return data as IOrganization[];
    }

    public async getOrganizationAsync(id: number): Promise<IOrganization> {
        const { data } = await this.$http.get(`organizations/${id}`);
        return data as IOrganization;
    }

    public async createOrganizationAsync(angle: IOrganizationCreateDTO): Promise<IOrganization> {
        const { data } = await this.$http.put(`organizations`, angle);
        return data;
    }

    public async editOrganizationAsync(id: number, angle: IOrganizationEditDTO): Promise<IOrganization> {
        const { data } = await this.$http.post(`organizations/${id}`, angle);
        return data;
    }

    public async deleteOrganizationAsync(id: number): Promise<number> {
        await this.$http.delete(`organizations/${id}`);
        return id;
    }
}

export const OrganizationApi = OrganizationService.Instance;