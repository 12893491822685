import { Stack, StackItem } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { CaseApi } from '../api';
import ContentWithoutSideMenu from '../components/ContentWithoutSideMenu';
import { DataTable } from '../components/controls';
import { ApplicationState } from '../store';
import * as Auth from '../store/Auth';
import { ICase } from '../store/Cases';

type CasesProps = ReturnType<typeof Auth.reducer> & RouteComponentProps<{ organizationID: string }>;

type CasesState = {
    cases: ICase[]
};

class maintainer extends React.Component<CasesProps, CasesState> {

    constructor(props: CasesProps) {
        super(props);

        this.state = {
            cases: []
        };
    }

    public componentDidMount(): void {
        let keys = Object.keys(this.props.caseAccess).map(e => parseInt(e));

        for (let index = 0; index < keys.length; index++) {
            CaseApi.getCaseAsync(0, keys[index]).then(data => {
                this.setState({
                    cases: [...this.state.cases, data]
                });
            });
        }
    }

    public render(): React.ReactNode {
        return (
            <ContentWithoutSideMenu>
                <div>
                    <h1>Cases</h1>
                    <p>Du er logget ind som <strong>maintainer</strong>. Du har adgang til at ændre i data for enheder i nedenstående cases.</p>
                    <p>Klik på projektets navn i listen for at tilgå enhederne.</p>
                </div>

                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>

                        <DataTable<ICase> isLoading={false} paginated items={this.state.cases} coloumns={[
                            {
                                key: 'name',
                                name: 'Projektnavn',
                                fieldName: 'Name',
                                minWidth: 210,
                                maxWidth: 1000,
                                isResizable: true,
                                isSortable: true,
                                onRender: (item: ICase) => {
                                    if(this.props.usertype === 'Maintainer'){
                                        return <NavLink to={`/organizations/${item.organizationID}/cases/${item.id}/units`}>{item.name}</NavLink>;
                                    }
                                    return <NavLink to={`/organizations/${item.organizationID}/cases/${item.id}`}>{item.name}</NavLink>;
                                }
                            }
                        ]} />
                    </StackItem>
                </Stack>
            </ContentWithoutSideMenu>
        );
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.auth)(maintainer);