import { IconButton, Stack, StackItem } from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import { DefaultButton, Dialog, DialogFooter, DialogType, Dropdown, Label, PrimaryButton } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import { actionCreators, reducer } from '../../store/Fields';

type FieldsPickerProps = ReturnType<typeof reducer> & typeof actionCreators & {
    organizationID: number;
    caseID: number;
    fields: string;
    onChange: (value:string) => void
};

type FieldsPickerState = {
    editorOpen: boolean;
    fields: (number| string)[];
    selectedField: string | number;
};

class FieldsPicker extends React.Component<FieldsPickerProps, FieldsPickerState> {

    constructor(props: FieldsPickerProps) {
        super(props);

        this.state = {
            editorOpen: false,
            fields: JSON.parse(props.fields),
            selectedField: ''
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps: FieldsPickerProps) {
        if(nextProps.fields === this.props.fields){
            this.setState({
                fields: JSON.parse(nextProps.fields)
            });
        }
    }

    public componentDidMount(): void {
        if(!this.props.isLoading){
            this.props.requestFields(this.props.organizationID, this.props.caseID);
        }
    }

    @boundMethod
    private _togglePickerOpen() {
        this.setState({
            editorOpen: !this.state.editorOpen
        });
    }

    public render(): React.ReactNode {
        return (
            <>
                <DefaultButton text={`${this.state.fields.length} fields`} onClick={() => this._togglePickerOpen()} />

                <Dialog
                    hidden={!this.state.editorOpen}
                    onDismiss={this._togglePickerOpen}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Pick fields'
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 600 } }
                    }}
                >
                    {this.state.fields.map(fieldID => {
                        let field = this.props.items.find(e => e.id === fieldID);
                        if(field){
                            return <Label key={fieldID}>{field.name} <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => this._removeField(fieldID)} /></Label>
                        }
                        return <Label key={fieldID}>{fieldID} <IconButton iconProps={{ iconName: 'Delete' }} onClick={() => this._removeField(fieldID)} /></Label>;
                    })}

                    <Stack horizontal tokens={{ childrenGap: 20 }}>
                        <StackItem grow={2}>
                            <Dropdown options={[{key: 'status', text: 'Status'}, ...this.props.items.map(e => {return {key: e.id, text: e.name}})]} selectedKey={this.state.selectedField} onChange={(e, option) => {
                                if(option){
                                    this.setState({
                                        selectedField: option.key
                                    });
                                }
                            }} />
                        </StackItem>
                        <StackItem>
                            <DefaultButton onClick={() => this._addField()}>Add</DefaultButton>
                        </StackItem>
                    </Stack>

                    <DialogFooter>
                        <PrimaryButton onClick={this._save} text='OK' />
                    </DialogFooter>
                </Dialog>
            </>
        );
    }

    @boundMethod
    private _removeField(field: number | string): void {
        this.setState({
            fields: this.state.fields.filter(e => e !== field)
        }, () => this.props.onChange(JSON.stringify(this.state.fields)));
    }

    @boundMethod
    private _addField(): void {
        if(!this.state.fields.some(e => e === this.state.selectedField)){
            this.setState({
                fields: [...this.state.fields, this.state.selectedField]
            }, () => this.props.onChange(JSON.stringify(this.state.fields)));
        }
    }

    @boundMethod
    private _save(): void {
        this.setState({
            editorOpen: false
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.fields, actionCreators)(FieldsPicker);