import { AppThunkAction } from '.';
import { MediaFileApi } from '../api';
import { ApiError } from '../types/ApiError';
import { createReducer, IActionType, IBaseAction } from './BaseStore';

export const ActionType: IActionType = {
    NAMESPACE: 'mediaFiles',
    REQUEST: 'mediaFiles/fetch',
    RECEIVE_FAIL: 'mediaFiles/fetchFail',
    RECEIVE_SUCCESS: 'mediaFiles/fetchSuccess',
    REQUEST_DETAILS: 'mediaFiles/fetchSingle',
    RECEIVE_DETAILS_FAIL: 'mediaFiles/fetchSingleFail',
    RECEIVE_DETAILS_SUCCESS: 'mediaFiles/fetchSingleSuccess',
    ADD: 'mediaFiles/add',
    ADD_FAIL: 'mediaFiles/add_fail',
    ADD_SUCCESS: 'mediaFiles/add_success',
    EDIT: 'mediaFiles/edit',
    EDIT_FAIL: 'mediaFiles/edit_fail',
    EDIT_SUCCESS: 'mediaFiles/edit_success',
    DELETE: 'mediaFiles/delete',
    DELETE_FAIL: 'mediaFiles/deleteFail',
    DELETE_SUCCESS: 'mediaFiles/deleteSuccess',
    RESET_STATE: 'mediaFiles/resetState'
};

export interface IMediaFile {
    id: number;
    filename: string;
    mimeType: string;
    mediaType: 'Image' | 'Pdf' | 'Video';
    createdAt: string;
    width?: number;
    height?: number;
    fileSize: number;
    hasThumbnail: boolean;
}

export const reducer = createReducer<IMediaFile>(ActionType);

export const actionCreators = {
    refreshMediaFiles: (organizationID: number, caseID: number): AppThunkAction<IBaseAction<IMediaFile>> => (dispatch, getState) => {
        MediaFileApi.getMediaFilesAsync(organizationID, caseID).then(data => {
            dispatch({ type: ActionType.RECEIVE_SUCCESS, items: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_FAIL, error: reason });
        });
    },

    requestMediaFiles: (organizationID: number, caseID: number): AppThunkAction<IBaseAction<IMediaFile>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST });

        MediaFileApi.getMediaFilesAsync(organizationID, caseID).then(data => {
            dispatch({ type: ActionType.RECEIVE_SUCCESS, items: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_FAIL, error: reason });
        });
    },

    requestMediaFile: (organizationID: number, caseID: number, id: number): AppThunkAction<IBaseAction<IMediaFile>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST_DETAILS });

        MediaFileApi.getMediaFileAsync(organizationID, caseID, id).then(data => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_SUCCESS, item: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_FAIL, error: reason });
        });
    },

    createMediaFile: (organizationID: number, caseID: number, file: File, callback: (c: IMediaFile) => void = () => { }): AppThunkAction<IBaseAction<IMediaFile>> => (dispatch, getState) => {
        dispatch({ type: ActionType.ADD });

        MediaFileApi.createMediaFileAsync(organizationID, caseID, file).then(data => {
            dispatch({ type: ActionType.ADD_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.ADD_FAIL, error: reason });
        });
    },

    editMediaFile: (organizationID: number, caseID: number, mediafile: IMediaFile, file: File, callback: (c: IMediaFile) => void = () => { }): AppThunkAction<IBaseAction<IMediaFile>> => (dispatch, getState) => {
        dispatch({ type: ActionType.EDIT });

        MediaFileApi.updateMediaFileAsync(organizationID, caseID, mediafile, file).then(data => {
            dispatch({ type: ActionType.EDIT_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.ADD_FAIL, error: reason });
        });
    },

    deleteMediaFile: (organizationID: number, caseID: number, mediaFile: IMediaFile, callback: () => void = () => { }): AppThunkAction<IBaseAction<IMediaFile>> => (dispatch, getState) => {
        dispatch({ type: ActionType.DELETE });

        MediaFileApi.deleteMediaFileAsync(organizationID, caseID, mediaFile.id).then(data => {
            dispatch({ type: ActionType.DELETE_SUCCESS, item: mediaFile });
            callback();
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.DELETE_FAIL, error: reason });
        });
    }
};