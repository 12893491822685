import { ApiError } from '../types/ApiError';
import { AuthApi, TokenApi } from '../api';
import { AppThunkAction, FunctionReturnTypes } from '.';

export interface IActionType {
    NAMESPACE: string;
    LOGIN: string;
    LOGIN_SUCCESS: string;
    LOGIN_FAIL: string;
    LOGOUT: string;
    RESET_STATE: string;
    FORGOT_PASSWORD: string;
    FORGOT_PASSWORD_SUCCESS: string;
    FORGOT_PASSWORD_FAIL: string;
    RESET_PASSWORD: string;
    RESET_PASSWORD_SUCCESS: string;
    RESET_PASSWORD_FAIL: string;
}

export const ActionType: IActionType = {
    NAMESPACE: 'auth',
    LOGIN: 'auth/login',
    LOGIN_SUCCESS: 'auth/loginSuccess',
    LOGIN_FAIL: 'auth/loginFail',
    LOGOUT: 'auth/logout',
    RESET_STATE: 'auth/resetState',
    FORGOT_PASSWORD: 'auth/forgotPassword',
    FORGOT_PASSWORD_SUCCESS: 'auth/forgotPasswordSuccess',
    FORGOT_PASSWORD_FAIL: 'auth/forgotPasswordFail',
    RESET_PASSWORD: 'auth/resetPassword',
    RESET_PASSWORD_SUCCESS: 'auth/resetPasswordSuccess',
    RESET_PASSWORD_FAIL: 'auth/resetPasswordFail'
};

export enum AuthStatusEnum {
    None = 'none',
    Process = 'process',
    Success = 'success',
    Fail = 'fail'
}

export interface ICredentials {
    email?: string;
    password?: string;
    rememberMe?: boolean;
}

export interface IAuthUser {
    token?: string;
}

export interface IAuthState {
    readonly isAuthenticated: boolean;
    readonly usertype: 'SuperAdministrator' | 'Administrator' | 'Editor' | 'API' | 'Maintainer';
    readonly organizationID?: number;
    readonly username: string;
    readonly caseAccess: {
        [id: number]: 'ReadOnly' | 'ReadWrite'
    }
    readonly loginStatus: AuthStatusEnum;
    readonly loginError?: ApiError;
    readonly forgetStatus: AuthStatusEnum;
    readonly forgetError?: ApiError;
    readonly resetStatus: AuthStatusEnum;
    readonly resetError?: ApiError;
}

export interface IAuthAction {
    readonly type: string;
    readonly authUser?: IAuthUser;
    readonly rememberMe?: boolean;
    readonly error?: ApiError;
}

const initialState = () => {
    let token = TokenApi.getToken();

    if (token) {
        return {
            forgetStatus: AuthStatusEnum.None,
            loginStatus: AuthStatusEnum.None,
            resetStatus: AuthStatusEnum.None,
            isAuthenticated: true,
            token: token,
            username: TokenApi.getUsername(),
            usertype: TokenApi.getUsertype(),
            caseAccess: TokenApi.getCaseAccess(),
            organizationID: TokenApi.getOrganizationID()
        } as IAuthState;
    }

    return {
        forgetStatus: AuthStatusEnum.None,
        loginStatus: AuthStatusEnum.None,
        resetStatus: AuthStatusEnum.None,
        isAuthenticated: false,
        token: '',
        username: '',
        caseAccess: {},
        usertype: 'Editor'
    } as IAuthState;
};

export const actionCreators = {
    loginUserRequest: (credentials: ICredentials): AppThunkAction<IAuthAction> => (dispatch, getState) => {
        dispatch({ type: ActionType.LOGIN });

        AuthApi.loginAsync(credentials).then(data => {
            dispatch({ type: ActionType.LOGIN_SUCCESS, authUser: data, rememberMe: credentials.rememberMe });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.LOGIN_FAIL, error: reason });
        });
    },
    logoutUserRequest: (): AppThunkAction<IAuthAction> => (dispatch, getState) => {
        dispatch({ type: ActionType.LOGOUT });
    },
    forgotPasswordRequest: (username: string, successCallback: () => void = () => { }): AppThunkAction<IAuthAction> => (dispatch, getState) => {
        dispatch({ type: ActionType.FORGOT_PASSWORD });

        AuthApi.forgotPasswordAsync(username).then(data => {
            dispatch({ type: ActionType.FORGOT_PASSWORD_SUCCESS });
            successCallback();
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.FORGOT_PASSWORD_FAIL, error: reason });
        });
    },
    resetPasswordRequest: (password: string, token: string, successCallback: () => void = () => { }): AppThunkAction<IAuthAction> => (dispatch, getState) => {
        dispatch({ type: ActionType.RESET_PASSWORD });

        AuthApi.resetPasswordAsync(password, token).then(data => {
            dispatch({ type: ActionType.RESET_PASSWORD_SUCCESS });
            successCallback();
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RESET_PASSWORD_FAIL, error: reason });
        });
    },
    resetState: (): IAuthAction => ({ type: ActionType.RESET_STATE })
};

export const reducer = (state: IAuthState = initialState(), incomingAction: FunctionReturnTypes<typeof actionCreators>): IAuthState => {
    const action = incomingAction as IAuthAction;

    // If current action is not pertinent to this reducer, skip remainder of checks
    if (!action.type.startsWith(ActionType.NAMESPACE)) {
        return state;
    }

    switch (action.type) {
        case ActionType.LOGIN:
            return {
                ...state,
                loginStatus: AuthStatusEnum.Process,
                loginError: undefined
            };
        case ActionType.LOGIN_FAIL:
            return {
                ...state,
                loginStatus: AuthStatusEnum.Fail,
                loginError: action.error
            };
        case ActionType.LOGIN_SUCCESS:
            TokenApi.setToken(action.authUser!.token!, action.rememberMe!);
            return {
                ...state,
                loginStatus: AuthStatusEnum.Success,
                loginError: undefined,
                isAuthenticated: true,
                username: TokenApi.getUsername(),
                caseAccess: TokenApi.getCaseAccess(),
                organizationID: TokenApi.getOrganizationID(),
                usertype: TokenApi.getUsertype()
            };

        case ActionType.FORGOT_PASSWORD:
            return {
                ...state,
                forgetStatus: AuthStatusEnum.Process,
                forgetError: undefined
            };
        case ActionType.FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                forgetStatus: AuthStatusEnum.Fail,
                forgetError: action.error
            };
        case ActionType.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgetStatus: AuthStatusEnum.Success,
                forgetError: undefined
            };

        case ActionType.RESET_PASSWORD:
            return {
                ...state,
                resetStatus: AuthStatusEnum.Process,
                resetError: undefined
            };
        case ActionType.RESET_PASSWORD_FAIL:
            return {
                ...state,
                resetStatus: AuthStatusEnum.Fail,
                resetError: action.error
            };
        case ActionType.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetStatus: AuthStatusEnum.Success,
                resetError: undefined
            };

        case ActionType.LOGOUT:
            TokenApi.setToken('', true);
            return {
                ...state,
                isAuthenticated: false,
                username: TokenApi.getUsername(),
                caseAccess: TokenApi.getCaseAccess(),
                organizationID: TokenApi.getOrganizationID(),
                usertype: TokenApi.getUsertype()
            };

        case ActionType.RESET_STATE:
            return initialState();
        default:
            return state;
    }
};