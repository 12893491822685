import { boundMethod } from 'autobind-decorator';
import {
  DefaultButton,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';

import { ErrorMessage, FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import {
  actionCreators,
  ISectionCreateDTO,
  reducer,
} from '../../store/Sections';

type NewSectionProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onAdded: () => void;
    onDismissed: () => void;
    organizationID: number;
    caseID: number;
};

type NewSectionState = {
    newSection: ISectionCreateDTO;
};

class NewSection extends React.Component<NewSectionProps, NewSectionState> {

    constructor(props: NewSectionProps) {
        super(props);

        this.state = {
            newSection: {
                name: ''
            }
        };
    }

    public render(): React.ReactNode {
        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='New section' onRenderFooterContent={() => <StackItem>
                <PrimaryButton disabled={this.props.isAdding} onClick={this._add} text='Create' style={{ marginRight: '8px' }} />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </StackItem>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <ErrorMessage error={this.props.addError} />

                    <StackItem>
                        <TextField label='Name' value={this.state.newSection.name} onChange={(e, value) => this.setState({ newSection: { ...this.state.newSection, name: value || '' } })} />
                        <FieldValidation error={this.props.addError} fieldname='Name' />
                    </StackItem>
                </Stack>
            </Panel>
        );
    }

    @boundMethod
    private _add(): void {
        this.props.addSection(this.props.organizationID, this.props.caseID, this.state.newSection, () => { this.props.onAdded(); });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.fields, actionCreators)(NewSection);