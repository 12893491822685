import { AppThunkAction } from '.';
import { MaintainerApi } from '../api';
import { ApiError } from '../types/ApiError';
import { createReducer, IActionType, IBaseAction } from './BaseStore';

export const ActionType: IActionType = {
    NAMESPACE: 'maintainers',
    REQUEST: 'maintainers/fetch',
    RECEIVE_FAIL: 'maintainers/fetchFail',
    RECEIVE_SUCCESS: 'maintainers/fetchSuccess',
    REQUEST_DETAILS: 'maintainers/fetchSingle',
    RECEIVE_DETAILS_FAIL: 'maintainers/fetchSingleFail',
    RECEIVE_DETAILS_SUCCESS: 'maintainers/fetchSingleSuccess',
    ADD: 'maintainers/add',
    ADD_FAIL: 'maintainers/add_fail',
    ADD_SUCCESS: 'maintainers/add_success',
    EDIT: 'maintainers/edit',
    EDIT_FAIL: 'maintainers/edit_fail',
    EDIT_SUCCESS: 'maintainers/edit_success',
    DELETE: 'maintainers/delete',
    DELETE_FAIL: 'maintainers/deleteFail',
    DELETE_SUCCESS: 'maintainers/deleteSuccess',
    RESET_STATE: 'maintainers/resetState'
};

export interface IMaintainer {
    id: number;
    email: string;
    access: 'ReadOnly' | 'ReadWrite';
}

export interface IMaintainerCreateDTO {
    email: string;
    access: 'ReadOnly' | 'ReadWrite';
}

export interface IMaintainerEditDTO {
    access: 'ReadOnly' | 'ReadWrite';
}

export const reducer = createReducer<IMaintainer>(ActionType);

export const actionCreators = {
    requestMaintainers: (organizationID: number, caseID: number): AppThunkAction<IBaseAction<IMaintainer>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST });

        MaintainerApi.getMaintainersAsync(organizationID, caseID).then(data => {
            dispatch({ type: ActionType.RECEIVE_SUCCESS, items: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_FAIL, error: reason });
        });
    },

    requestMaintainer: (organizationID: number, caseID: number, id: number): AppThunkAction<IBaseAction<IMaintainer>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST_DETAILS });

        MaintainerApi.getMaintainerAsync(organizationID, caseID, id).then(data => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_SUCCESS, item: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_FAIL, error: reason });
        });
    },

    addMaintainer: (organizationID: number, caseID: number, item: IMaintainerCreateDTO, callback: (c: IMaintainer) => void = () => { }): AppThunkAction<IBaseAction<IMaintainer>> => (dispatch, getState) => {
        dispatch({ type: ActionType.ADD });

        MaintainerApi.createMaintainerAsync(organizationID, caseID, item).then(data => {
            dispatch({ type: ActionType.ADD_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.ADD_FAIL, error: reason });
        });
    },

    editMaintainer: (organizationID: number, caseID: number, maintainer: IMaintainer, item: IMaintainerEditDTO, callback: (c: IMaintainer) => void = () => { }): AppThunkAction<IBaseAction<IMaintainer>> => (dispatch, getState) => {
        dispatch({ type: ActionType.EDIT });

        MaintainerApi.editMaintainerAsync(organizationID, caseID, maintainer.id, item).then(data => {
            dispatch({ type: ActionType.EDIT_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.EDIT_FAIL, error: reason });
        });
    },

    deleteMaintainer: (organizationID: number, caseID: number, maintainer: IMaintainer, callback: () => void = () => { }): AppThunkAction<IBaseAction<IMaintainer>> => (dispatch, getState) => {
        dispatch({ type: ActionType.DELETE });

        MaintainerApi.deleteMaintainerAsync(organizationID, caseID, maintainer.id).then(data => {
            dispatch({ type: ActionType.DELETE_SUCCESS, item: maintainer });
            callback();
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.DELETE_FAIL, error: reason });
        });
    }
};