import { AppThunkAction } from '.';
import { UserApi } from '../api';
import { ApiError } from '../types/ApiError';
import { createReducer, IActionType, IBaseAction } from './BaseStore';

export const ActionType: IActionType = {
    NAMESPACE: 'users',
    REQUEST: 'users/fetch',
    RECEIVE_FAIL: 'users/fetchFail',
    RECEIVE_SUCCESS: 'users/fetchSuccess',
    REQUEST_DETAILS: 'users/fetchSingle',
    RECEIVE_DETAILS_FAIL: 'users/fetchSingleFail',
    RECEIVE_DETAILS_SUCCESS: 'users/fetchSingleSuccess',
    ADD: 'users/add',
    ADD_FAIL: 'users/add_fail',
    ADD_SUCCESS: 'users/add_success',
    EDIT: 'users/edit',
    EDIT_FAIL: 'users/edit_fail',
    EDIT_SUCCESS: 'users/edit_success',
    DELETE: 'users/delete',
    DELETE_FAIL: 'users/deleteFail',
    DELETE_SUCCESS: 'users/deleteSuccess',
    RESET_STATE: 'users/resetState'
};

export interface IUser {
    id: number;
    email: string;
    userType: 'SuperAdministrator' | 'Administrator' | 'Editor' | 'API' | 'Maintainer';
    organizationID?: number;
    caseAccess: {
        [id: number]: 'ReadOnly' | 'ReadWrite'
    }
}

export interface IUserCreateDTO {
    email: string;
    userType: 'SuperAdministrator' | 'Administrator' | 'Editor' | 'API' | 'Maintainer';
    organizationID?: number;
    caseAccess: {
        [id: number]: 'ReadOnly' | 'ReadWrite'
    }
}

export interface IUserEditDTO {
    email: string;
    userType: 'SuperAdministrator' | 'Administrator' | 'Editor' | 'API' | 'Maintainer';
    caseAccess: {
        [id: number]: 'ReadOnly' | 'ReadWrite'
    }
}

export const reducer = createReducer<IUser>(ActionType);

export const actionCreators = {
    requestUsers: (): AppThunkAction<IBaseAction<IUser>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST });

        UserApi.getUsersAsync().then(data => {
            dispatch({ type: ActionType.RECEIVE_SUCCESS, items: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_FAIL, error: reason });
        });
    },

    requestUser: (id: number): AppThunkAction<IBaseAction<IUser>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST_DETAILS });

        UserApi.getUserAsync(id).then(data => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_SUCCESS, item: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_FAIL, error: reason });
        });
    },

    addUser: (item: IUserCreateDTO, callback: (c: IUser) => void = () => { }): AppThunkAction<IBaseAction<IUser>> => (dispatch, getState) => {
        dispatch({ type: ActionType.ADD });

        UserApi.createUserAsync(item).then(data => {
            dispatch({ type: ActionType.ADD_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.ADD_FAIL, error: reason });
        });
    },

    editUser: (user: IUser, item: IUserEditDTO, callback: (c: IUser) => void = () => { }): AppThunkAction<IBaseAction<IUser>> => (dispatch, getState) => {
        dispatch({ type: ActionType.EDIT });

        UserApi.editUserAsync(user.id, item).then(data => {
            dispatch({ type: ActionType.EDIT_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.EDIT_FAIL, error: reason });
        });
    },

    deleteUser: (user: IUser, callback: () => void = () => { }): AppThunkAction<IBaseAction<IUser>> => (dispatch, getState) => {
        dispatch({ type: ActionType.DELETE });

        UserApi.deleteUserAsync(user.id).then(data => {
            dispatch({ type: ActionType.DELETE_SUCCESS, item: user });
            callback();
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.DELETE_FAIL, error: reason });
        });
    }
};