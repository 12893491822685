import { AppThunkAction } from '.';
import { OverviewApi } from '../api';
import { ApiError } from '../types/ApiError';
import { createReducer, IActionType, IBaseAction } from './BaseStore';

export const ActionType: IActionType = {
    NAMESPACE: 'overviews',
    REQUEST: 'overviews/fetch',
    RECEIVE_FAIL: 'overviews/fetchFail',
    RECEIVE_SUCCESS: 'overviews/fetchSuccess',
    REQUEST_DETAILS: 'overviews/fetchSingle',
    RECEIVE_DETAILS_FAIL: 'overviews/fetchSingleFail',
    RECEIVE_DETAILS_SUCCESS: 'overviews/fetchSingleSuccess',
    ADD: 'overviews/add',
    ADD_FAIL: 'overviews/add_fail',
    ADD_SUCCESS: 'overviews/add_success',
    EDIT: 'overviews/edit',
    EDIT_FAIL: 'overviews/edit_fail',
    EDIT_SUCCESS: 'overviews/edit_success',
    DELETE: 'overviews/delete',
    DELETE_FAIL: 'overviews/deleteFail',
    DELETE_SUCCESS: 'overviews/deleteSuccess',
    RESET_STATE: 'overviews/resetState'
};

export interface IOverview {
    id: number;
    name: string;
    renderingID?: number;
    overlayID?: number;
}

export interface IOverviewCreateDTO {
    name: string;
    renderingID?: number;
    overlayID?: number;
}

export interface IOverviewEditDTO {
    name: string;
    renderingID?: number;
    overlayID?: number;
}

export const reducer = createReducer<IOverview>(ActionType);

export const actionCreators = {
    requestOverviews: (organizationID: number, caseID: number): AppThunkAction<IBaseAction<IOverview>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST });

        OverviewApi.getOverviewsAsync(organizationID, caseID).then(data => {
            dispatch({ type: ActionType.RECEIVE_SUCCESS, items: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_FAIL, error: reason });
        });
    },

    requestOverview: (organizationID: number, caseID: number, id: number): AppThunkAction<IBaseAction<IOverview>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST_DETAILS });

        OverviewApi.getOverviewAsync(organizationID, caseID, id).then(data => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_SUCCESS, item: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_FAIL, error: reason });
        });
    },

    addOverview: (organizationID: number, caseID: number, item: IOverviewCreateDTO, callback: (c: IOverview) => void = () => { }): AppThunkAction<IBaseAction<IOverview>> => (dispatch, getState) => {
        dispatch({ type: ActionType.ADD });

        OverviewApi.createOverviewAsync(organizationID, caseID, item).then(data => {
            dispatch({ type: ActionType.ADD_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.ADD_FAIL, error: reason });
        });
    },

    editOverview: (organizationID: number, caseID: number, angle: IOverview, item: IOverviewEditDTO, callback: (c: IOverview) => void = () => { }): AppThunkAction<IBaseAction<IOverview>> => (dispatch, getState) => {
        dispatch({ type: ActionType.EDIT });

        OverviewApi.editOverviewAsync(organizationID, caseID, angle.id, item).then(data => {
            dispatch({ type: ActionType.EDIT_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.EDIT_FAIL, error: reason });
        });
    },

    deleteOverview: (organizationID: number, caseID: number, angle: IOverview, callback: () => void = () => { }): AppThunkAction<IBaseAction<IOverview>> => (dispatch, getState) => {
        dispatch({ type: ActionType.DELETE });

        OverviewApi.deleteOverviewAsync(organizationID, caseID, angle.id).then(data => {
            dispatch({ type: ActionType.DELETE_SUCCESS, item: angle });
            callback();
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.DELETE_FAIL, error: reason });
        });
    }
};