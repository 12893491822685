import { AppThunkAction } from '.';
import { CaseDesignApi } from '../api';
import { ApiError } from '../types/ApiError';
import { IActionType, IBaseAction } from './BaseStore';
import { Reducer } from 'redux';

export const ActionType: IActionType = {
    NAMESPACE: 'caseDesign',
    REQUEST: 'caseDesign/fetch',
    RECEIVE_FAIL: 'caseDesign/fetchFail',
    RECEIVE_SUCCESS: 'caseDesign/fetchSuccess',
    REQUEST_DETAILS: 'caseDesign/fetchSingle',
    RECEIVE_DETAILS_FAIL: 'caseDesign/fetchSingleFail',
    RECEIVE_DETAILS_SUCCESS: 'caseDesign/fetchSingleSuccess',
    ADD: 'caseDesign/add',
    ADD_FAIL: 'caseDesign/add_fail',
    ADD_SUCCESS: 'caseDesign/add_success',
    EDIT: 'caseDesign/edit',
    EDIT_FAIL: 'caseDesign/edit_fail',
    EDIT_SUCCESS: 'caseDesign/edit_success',
    DELETE: 'caseDesign/delete',
    DELETE_FAIL: 'caseDesign/deleteFail',
    DELETE_SUCCESS: 'caseDesign/deleteSuccess',
    RESET_STATE: 'caseDesign/resetState'
};

export interface ICaseDesign {
    mobileBreakpoint: number;
    tabletBreakpoint: number;
    largeDesktopBreakpoint: number;
    defaults: {[id:string]: string};
    mobileOverrides: {[id:string]: string};
    tabletOverrides: {[id:string]: string};
    desktopOverrides: {[id:string]: string};
    largeDesktopOverrides: {[id:string]: string};
}

export interface ICaseDesignEditDTO {
    mobileBreakpoint: number;
    tabletBreakpoint: number;
    largeDesktopBreakpoint: number;
    defaults: {[id:string]: string};
    mobileOverrides: {[id:string]: string};
    tabletOverrides: {[id:string]: string};
    desktopOverrides: {[id:string]: string};
    largeDesktopOverrides: {[id:string]: string};
}

export type State = {
    isEditing: boolean;
    isLoading: boolean;
    editError: ApiError | undefined;
    item: ICaseDesign | undefined;
    loadError: ApiError | undefined;
};

const unloadedState:State  = {
    isEditing: false,
    isLoading: false,
    editError: undefined,
    item: undefined,
    loadError: undefined
};

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: IBaseAction<ICaseDesign>): State => {
    if (state === undefined) {
        return unloadedState;
    }

    // If current action is not pertinent to this reducer, skip remainder of checks
    if (!incomingAction.type.startsWith(ActionType.NAMESPACE)) {
        return state;
    }

    switch (incomingAction.type) {

        case ActionType.REQUEST_DETAILS:
            return {
                ...state,
                item: undefined,
                isLoading: true,
                loadError: undefined
            };
        case ActionType.RECEIVE_DETAILS_FAIL:
            return {
                ...state,
                isLoading: false,
                loadError: incomingAction.error
            };
        case ActionType.RECEIVE_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                loadError: undefined,
                item: incomingAction.item
            };

        case ActionType.EDIT:
            return {
                ...state,
                isEditing: true,
                editError: undefined
            };
        case ActionType.EDIT_FAIL:
            return {
                ...state,
                isEditing: false,
                editError: incomingAction.error
            };
        case ActionType.EDIT_SUCCESS:
            return {
                ...state,
                item: incomingAction.item,
                isEditing: false,
                editError: undefined
            };

        default:
            break;
    }
    return state;
}

export const actionCreators = {
    requestCaseDesign: (organizationID: number, caseID: number): AppThunkAction<IBaseAction<ICaseDesign>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST_DETAILS });

        CaseDesignApi.getCaseDesignAsync(organizationID, caseID).then(data => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_SUCCESS, item: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_FAIL, error: reason });
        });
    },

    editCaseDesign: (organizationID: number, caseID: number, design: ICaseDesign, callback: (c: ICaseDesign) => void = () => { }): AppThunkAction<IBaseAction<ICaseDesign>> => (dispatch, getState) => {
        dispatch({ type: ActionType.EDIT });

        CaseDesignApi.editCaseDesignAsync(organizationID, caseID, design).then(data => {
            dispatch({ type: ActionType.EDIT_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.EDIT_FAIL, error: reason });
        });
    }
};