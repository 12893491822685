import {
  Checkbox,
  DefaultButton,
  Dropdown,
  IDropdownOption,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';

import { ErrorMessage, FieldValidation } from '../../components/controls';
import MediaPicker from '../../components/controls/MediaPicker';
import { ApplicationState } from '../../store';
import { IField } from '../../store/Fields';
import { ISection } from '../../store/Sections';
import { actionCreators, IUnitCreateDTO, reducer } from '../../store/Units';

type NewUnitProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onAdded: () => void;
    onDismissed: () => void;
    organizationID: number;
    caseID: number;
    fields: IField[];
    sections: ISection[];
};

type NewFUnitState = {
    newUnit: IUnitCreateDTO;
};

class NewUnit extends React.Component<NewUnitProps, NewFUnitState> {

    constructor(props: NewUnitProps) {
        super(props);

        this.state = {
            newUnit: {
                fieldValues: {},
                floorplans: [],
                images: [],
                sections: [],
                state: 'ForSale',
                url: '',
                dataSheetID: undefined,
                materialListID: undefined,
                previewID: undefined
            }
        };
    }

    public render(): React.ReactNode {
        if(this.props.fields === undefined){
            return null;
        }

        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='New unit' onRenderFooterContent={() => <StackItem>
                <PrimaryButton disabled={this.props.isAdding} onClick={this._add} text='Create' style={{ marginRight: '8px' }} />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </StackItem>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <ErrorMessage error={this.props.addError} />
                    
                    <StackItem>
                        <TextField label='Mask ID - unit-[MaskID]' value={this.state.newUnit.maskID || ''} onChange={(e, value) => this.setState({ newUnit: { ...this.state.newUnit, maskID: value || '' } })} />
                        <FieldValidation error={this.props.addError} fieldname='MaskID' />
                    </StackItem>


                    <StackItem>
                        <Dropdown 
                            label='State' 
                            selectedKey={this.state.newUnit.state} 
                            onChange={this._onStateChange}
                            options={[
                                { key: 'ForSale', text: 'For Sale' },
                                { key: 'Reserved', text: 'Reserved' },
                                { key: 'Sold', text: 'Sold' },
                                { key: 'Vacant', text: 'Vacant' },
                                { key: 'ConditionallySold', text: 'Betinget solgt' },
                                { key: 'Deactivated', text: 'Deactivated' },
                                { key: 'RentedOut', text: 'Rented Out' },
                                { key: 'PurchaseAgreement', text: 'Købsaftale' },
                                { key: 'CommonArea', text: 'Fællesareal' }
                            ]}/>
                        <FieldValidation error={this.props.addError} fieldname='State' />
                    </StackItem>

                    <StackItem>
                        <TextField label='Url' value={this.state.newUnit.url || ''} onChange={(e, value) => this.setState({ newUnit: { ...this.state.newUnit, url: value || '' } })} />
                        <FieldValidation error={this.props.addError} fieldname='Url' />
                    </StackItem>

                    <StackItem>
                        <Label>Sections</Label>
                        {this.props.sections.map(e => <Checkbox key={e.id} label={e.name} checked={this.state.newUnit.sections.indexOf(e.id) !== -1} onChange={(_e, checked) => this._onSectionChange(e, checked!)} />)}
                    </StackItem>
        
                    <StackItem>
                        <MediaPicker 
                            label='Images'
                            multiple
                            organizationID={this.props.organizationID} 
                            caseID={this.props.caseID} 
                            value={this.state.newUnit.images} 
                            onChange={(value) => this.setState({ newUnit: { ...this.state.newUnit, images: value } })} />
                        <FieldValidation error={this.props.addError} fieldname='Images' />
                    </StackItem>
        
                    <StackItem>
                        <MediaPicker 
                            label='Floorplans' 
                            multiple
                            organizationID={this.props.organizationID} 
                            caseID={this.props.caseID} 
                            value={this.state.newUnit.floorplans} 
                            onChange={(value) => this.setState({ newUnit: { ...this.state.newUnit, floorplans: value } })} />
                        <FieldValidation error={this.props.addError} fieldname='Floorplans' />
                    </StackItem>
        
                    <StackItem>
                        <MediaPicker 
                            label='Preview' 
                            organizationID={this.props.organizationID} 
                            caseID={this.props.caseID} 
                            value={this.state.newUnit.previewID ? [this.state.newUnit.previewID] : []} 
                            onChange={(value) => this.setState({ newUnit: { ...this.state.newUnit, previewID: value.length === 0 ? undefined : value[0] } })} />
                        <FieldValidation error={this.props.addError} fieldname='RenderingID' />
                    </StackItem>
                    
                    <StackItem>
                        <MediaPicker 
                            label='Material list' 
                            organizationID={this.props.organizationID} 
                            caseID={this.props.caseID} 
                            value={this.state.newUnit.materialListID ? [this.state.newUnit.materialListID] : []} 
                            onChange={(value) => this.setState({ newUnit: { ...this.state.newUnit, materialListID: value.length === 0 ? undefined : value[0] } })} />
                        <FieldValidation error={this.props.addError} fieldname='MaterialListID' />
                    </StackItem>
        
                    <StackItem>
                        <MediaPicker 
                            label='Data sheet' 
                            organizationID={this.props.organizationID} 
                            caseID={this.props.caseID} 
                            value={this.state.newUnit.dataSheetID ? [this.state.newUnit.dataSheetID] : []} 
                            onChange={(value) => this.setState({ newUnit: { ...this.state.newUnit, dataSheetID: value.length === 0 ? undefined : value[0] } })} />
                        <FieldValidation error={this.props.addError} fieldname='DataSheetID' />
                    </StackItem>

                    {this.props.fields.map(e => {
                        if(e.type !== 'media'){
                            return <StackItem key={e.id}>
                                <TextField label={e.name} value={this.state.newUnit.fieldValues[e.id] || ''} onChange={(_e, value) => this.setState({ newUnit: { ...this.state.newUnit, fieldValues: {...this.state.newUnit.fieldValues,  [e.id]: (value || '') }} })} />
                            </StackItem>;
                        }

                        return null;
                    })}
                    <FieldValidation error={this.props.addError} fieldname='FieldValues' />
                </Stack>
            </Panel>
        );
    }


    @boundMethod
    private _onSectionChange(section: ISection, selected: boolean): void {
        if(selected){
            this.setState({newUnit: {...this.state.newUnit, sections: [...this.state.newUnit.sections.filter(e => e !== section.id), section.id]}})
        }else{
            this.setState({newUnit: {...this.state.newUnit, sections: this.state.newUnit.sections.filter(e => e !== section.id)}})
        }
    }

    @boundMethod
    private _onStateChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined): void {
        if(option){
            this.setState({ newUnit: { ...this.state.newUnit, state: option.key.toString() as 'ForSale' | 'Reserved' | 'Sold' | 'Vacant' | 'RentedOut' | 'ConditionallySold' | 'Deactivated'} })
        }
    }

    @boundMethod
    private _add(): void {
        this.props.addUnit(this.props.organizationID, this.props.caseID, this.state.newUnit, () => { this.props.onAdded(); });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.units, actionCreators)(NewUnit);