import { boundMethod } from 'autobind-decorator';
import { DefaultButton, Panel, PanelType, PrimaryButton, Stack, StackItem, TextField } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { ErrorMessage, FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, ICaseCreateDTO, reducer } from '../../store/Cases';

type NewCaseProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    organizationID: number;
    onAdded: () => void;
    onDismissed: () => void;
};

type NewCaseState = {
    newCase: ICaseCreateDTO;
};

class NewCase extends React.Component<NewCaseProps, NewCaseState> {

    constructor(props: NewCaseProps) {
        super(props);

        this.state = {
            newCase: {
                name: '',
                logoID: undefined
            }
        };
    }

    public render(): React.ReactNode {
        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='New case' onRenderFooterContent={() => <StackItem>
                <PrimaryButton disabled={this.props.isAdding} onClick={this._addCase} text='Create' style={{ marginRight: '8px' }} />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </StackItem>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <ErrorMessage error={this.props.addError} />

                    <StackItem>
                        <TextField label='Name' value={this.state.newCase.name} onChange={(e, value) => this.setState({ newCase: { ...this.state.newCase, name: value || '' } })} />
                        <FieldValidation error={this.props.addError} fieldname='Name' />
                    </StackItem>
                </Stack>
            </Panel>
        );
    }

    @boundMethod
    private _addCase(): void {
        this.props.addCase(this.props.organizationID, this.state.newCase, () => { this.props.onAdded(); });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.cases, actionCreators)(NewCase);