import {
  Checkbox,
  DefaultButton,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Slider,
  Stack,
  StackItem,
  TextField,
} from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import React from 'react';
import { connect } from 'react-redux';

import compass from '../../assets/compass.svg';
import { FieldValidation } from '../../components/controls';
import MediaPicker from '../../components/controls/MediaPicker';
import { ApplicationState } from '../../store';
import {
  actionCreators,
  IAngle,
  IAngleEditDTO,
  reducer,
} from '../../store/Angles';

type EditAngleProps = ReturnType<typeof reducer> & typeof actionCreators & {
  isOpen: boolean;
  onEdited: () => void;
  onDismissed: () => void;
  angle?: IAngle;
  organizationID: number;
  caseID: number;
  sectionID: number;
  northOffset?: number;
};

type EditAngleState = {
  editAngle: IAngleEditDTO;
};

class EditAngle extends React.Component<EditAngleProps, EditAngleState> {

  constructor(props: EditAngleProps) {
    super(props);

    this.state = {
      editAngle: {
        name: '',
        overlayID: undefined,
        renderingID: undefined,
        northOffset: undefined,
        prevoiusAngleAnimationID: undefined,
        nextAngleAnimationID: undefined,
        disablePreviousButton: undefined,
        disableNextButton: undefined
      }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: EditAngleProps) {
    if (nextProps.angle === undefined) {
      this.setState({
        editAngle: {
          name: '',
          overlayID: undefined,
          renderingID: undefined,
          northOffset: undefined,
          prevoiusAngleAnimationID: undefined,
          nextAngleAnimationID: undefined,
          disablePreviousButton: undefined,
          disableNextButton: undefined
        }
      });
    } else {
      if (this.props.angle === undefined || this.props.angle.id !== nextProps.angle.id) {
        this.setState({
          editAngle: {
            name: nextProps.angle.name,
            overlayID: nextProps.angle.overlayID,
            renderingID: nextProps.angle.renderingID,
            northOffset: nextProps.angle.northOffset,
            prevoiusAngleAnimationID: nextProps.angle.prevoiusAngleAnimationID,
            nextAngleAnimationID: nextProps.angle.nextAngleAnimationID,
            disablePreviousButton: nextProps.angle.disablePreviousButton,
            disableNextButton: nextProps.angle.disableNextButton,
          }
        });
      }
    }
  }

  public render(): React.ReactNode {
    return (
      <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='Edit angle' onRenderFooterContent={() => <div>
        <PrimaryButton disabled={this.props.isEditing} onClick={this._edit} style={{ marginRight: '8px' }} text='Save' />
        <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
      </div>}>
        <Stack tokens={{ childrenGap: 20 }}>
          <StackItem>
            <TextField label='Name' value={this.state.editAngle.name} onChange={(e, value) => this.setState({ editAngle: { ...this.state.editAngle, name: value || '' } })} />
            <FieldValidation error={this.props.addError} fieldname='Name' />
          </StackItem>

          <StackItem>
            <MediaPicker
              label='Overlay'
              organizationID={this.props.organizationID}
              caseID={this.props.caseID}
              value={this.state.editAngle.overlayID ? [this.state.editAngle.overlayID] : []}
              onChange={(value) => this.setState({ editAngle: { ...this.state.editAngle, overlayID: value.length === 0 ? undefined : value[0] } })} />
            <FieldValidation error={this.props.addError} fieldname='OverlayID' />
          </StackItem>

          <StackItem>
            <MediaPicker
              label='Rendering'
              organizationID={this.props.organizationID}
              caseID={this.props.caseID}
              value={this.state.editAngle.renderingID ? [this.state.editAngle.renderingID] : []}
              onChange={(value) => this.setState({ editAngle: { ...this.state.editAngle, renderingID: value.length === 0 ? undefined : value[0] } })} />
            <FieldValidation error={this.props.addError} fieldname='RenderingID' />
          </StackItem>

          <StackItem>
            <MediaPicker
              label='Next angle animation'
              organizationID={this.props.organizationID}
              caseID={this.props.caseID}
              value={this.state.editAngle.nextAngleAnimationID ? [this.state.editAngle.nextAngleAnimationID] : []}
              onChange={(value) => this.setState({ editAngle: { ...this.state.editAngle, nextAngleAnimationID: value.length === 0 ? undefined : value[0] } })} />
            <FieldValidation error={this.props.addError} fieldname='NextAngleAnimationID' />
          </StackItem>

          <StackItem>
            <Checkbox
              label="Disable next button"
              checked={this.state.editAngle.disableNextButton ? this.state.editAngle.disableNextButton : false}
              onChange={(ev, isChecked) => this.setState({ editAngle: { ...this.state.editAngle, disableNextButton: isChecked } })}
            />
          </StackItem>

          <StackItem>
            <MediaPicker
              label='Previous angle animation'
              organizationID={this.props.organizationID}
              caseID={this.props.caseID}
              value={this.state.editAngle.prevoiusAngleAnimationID ? [this.state.editAngle.prevoiusAngleAnimationID] : []}
              onChange={(value) => this.setState({ editAngle: { ...this.state.editAngle, prevoiusAngleAnimationID: value.length === 0 ? undefined : value[0] } })} />
            <FieldValidation error={this.props.addError} fieldname='PrevoiusAngleAnimationID' />
          </StackItem>

          <StackItem>
            <Checkbox
              label="Disable previous button"
              checked={this.state.editAngle.disablePreviousButton ? this.state.editAngle.disablePreviousButton : false}
              onChange={(ev, isChecked) => this.setState({ editAngle: { ...this.state.editAngle, disablePreviousButton: isChecked } })}
            />
          </StackItem>

          <StackItem>
            <Slider
              label="North arrow offset (In degrees)"
              min={0}
              max={360}
              step={1}
              defaultValue={0}
              showValue snapToStep
              value={this.state.editAngle.northOffset}
              onChange={(value) => this.setState({ editAngle: { ...this.state.editAngle, northOffset: value } })} />
            {this.state.editAngle.northOffset === undefined &&
              <Label>Please remember to configure this value!</Label>
            }
            <img src={compass} width={50} style={{ transform: `rotate(${this.state.editAngle.northOffset || 0}deg)` }} alt='compass' />
          </StackItem>
        </Stack>
      </Panel>
    );
  }

  @boundMethod
  private _edit(): void {
    if (this.props.angle) {
      this.props.editAngle(this.props.organizationID, this.props.caseID, this.props.sectionID, this.props.angle, this.state.editAngle, () => { this.props.onEdited(); });
    }
  }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.angles, actionCreators)(EditAngle);