import { IUser, IUserCreateDTO, IUserEditDTO } from '../store/Users';
import { BaseService } from './base.service';

class OrganizationUserService extends BaseService {
    private static _angleService: OrganizationUserService;

    private constructor() {
        super();
    }

    public static get Instance(): OrganizationUserService {
        return this._angleService || (this._angleService = new this());
    }

    public async getUsersAsync(organizationID: number): Promise<IUser[]> {
        const { data } = await this.$http.get(`organizations/${organizationID}/users`);
        return data as IUser[];
    }

    public async getUserAsync(organizationID: number, id: number): Promise<IUser> {
        const { data } = await this.$http.get(`organizations/${organizationID}/users/${id}`);
        return data as IUser;
    }

    public async createUserAsync(organizationID: number, user: IUserCreateDTO): Promise<IUser> {
        const { data } = await this.$http.put(`organizations/${organizationID}/users`, user);
        return data;
    }

    public async editUserAsync(organizationID: number, id: number, user: IUserEditDTO): Promise<IUser> {
        const { data } = await this.$http.post(`organizations/${organizationID}/users/${id}`, user);
        return data;
    }

    public async deleteUserAsync(organizationID: number, id: number): Promise<number> {
        await this.$http.delete(`organizations/${organizationID}/users/${id}`);
        return id;
    }
}

export const OrganizationUserApi = OrganizationUserService.Instance;