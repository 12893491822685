import { IApiToken, IApiTokenCreateDTO } from '../store/ApiTokens';
import { BaseService } from './base.service';

class ApiTokenService extends BaseService {
    private static _angleService: ApiTokenService;

    private constructor() {
        super();
    }

    public static get Instance(): ApiTokenService {
        return this._angleService || (this._angleService = new this());
    }

    public async getApiTokensAsync(organizationID: number): Promise<IApiToken[]> {
        const { data } = await this.$http.get(`organizations/${organizationID}/apikeys`);
        return data as IApiToken[];
    }

    public async getApiTokenAsync(organizationID: number, id: number): Promise<IApiToken> {
        const { data } = await this.$http.get(`organizations/${organizationID}/apikeys/${id}`);
        return data as IApiToken;
    }

    public async createApiTokenAsync(organizationID: number, angle: IApiTokenCreateDTO): Promise<IApiToken> {
        const { data } = await this.$http.put(`organizations/${organizationID}/apikeys`, angle);
        return data;
    }
}

export const ApiTokenApi = ApiTokenService.Instance;