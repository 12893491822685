import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import { ApplicationState } from '../../store';
import *  as Organizations from '../../store/Organizations';
import *  as Auth from '../../store/Auth';
import Cases from '../Cases/Cases';
import Case from '../Cases/Case';
import ApiKeys from '../ApiKeys/ApiKeys';
import OrganizationUsers from '../OrganizationUsers/OrganizationUsers';
import ContentWithSidemenu from '../../components/ContentWithSidemenu';

type OrganizationProps = {
    organizations: ReturnType<typeof Organizations.reducer>;
    auth: ReturnType<typeof Auth.reducer>;
} & typeof Organizations.actionCreators & RouteComponentProps<{ organizationID: string }>;

type OrganizationState = {

};

class Organization extends React.Component<OrganizationProps, OrganizationState> {

    constructor(props: OrganizationProps) {
        super(props);

        this.state = {

        };
    }

    public componentDidMount(): void {
        this.props.requestOrganization(parseInt(this.props.match.params.organizationID));
    }

    public render(): React.ReactNode {
        return (
                <Switch>
                    <Route path={'/organizations/:organizationID/cases/:caseID'} component={Case} />

                    <Route exact path={['/organizations/:organizationID', '/organizations/:organizationID/users', '/organizations/:organizationID/apikeys']}>
                        <ContentWithSidemenu menu={[
                            {
                                name: this.props.organizations.item === undefined ? '' : this.props.organizations.item.name,
                                links: [
                                    {
                                        name: 'Cases',
                                        url: `/organizations/${this.props.match.params.organizationID}`,
                                        key: 'home'
                                    }].concat(this.props.auth.usertype === 'Administrator' || this.props.auth.usertype === 'SuperAdministrator' ? [
                                        {
                                            name: 'Users',
                                            url: `/organizations/${this.props.match.params.organizationID}/users`,
                                            key: 'users',
                                        },
                                        {
                                            name: 'Api keys',
                                            url: `/organizations/${this.props.match.params.organizationID}/apikeys`,
                                            key: 'api keys',
                                        }
                                    ] : [])
                            }
                        ]}>
                            
                            <Switch>
                                <Route path={'/organizations/:organizationID'} exact component={Cases} />
                                <Route path={'/organizations/:organizationID/apikeys'} component={ApiKeys} />
                                <Route path={'/organizations/:organizationID/users'} component={OrganizationUsers} />
                            </Switch>
                        </ContentWithSidemenu>
                    </Route>
                </Switch>
        );
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state, Organizations.actionCreators)(Organization);