import { AppThunkAction } from '.';
import { OrganizationUserApi } from '../api';
import { ApiError } from '../types/ApiError';
import { createReducer, IActionType, IBaseAction } from './BaseStore';
import { IUser, IUserCreateDTO, IUserEditDTO } from './Users';

export const ActionType: IActionType = {
    NAMESPACE: 'organizationUsers',
    REQUEST: 'organizationUsers/fetch',
    RECEIVE_FAIL: 'organizationUsers/fetchFail',
    RECEIVE_SUCCESS: 'organizationUsers/fetchSuccess',
    REQUEST_DETAILS: 'organizationUsers/fetchSingle',
    RECEIVE_DETAILS_FAIL: 'organizationUsers/fetchSingleFail',
    RECEIVE_DETAILS_SUCCESS: 'organizationUsers/fetchSingleSuccess',
    ADD: 'organizationUsers/add',
    ADD_FAIL: 'organizationUsers/add_fail',
    ADD_SUCCESS: 'organizationUsers/add_success',
    EDIT: 'organizationUsers/edit',
    EDIT_FAIL: 'organizationUsers/edit_fail',
    EDIT_SUCCESS: 'organizationUsers/edit_success',
    DELETE: 'organizationUsers/delete',
    DELETE_FAIL: 'organizationUsers/deleteFail',
    DELETE_SUCCESS: 'organizationUsers/deleteSuccess',
    RESET_STATE: 'organizationUsers/resetState'
};

export const reducer = createReducer<IUser>(ActionType);

export const actionCreators = {
    requestUsers: (organizationID: number): AppThunkAction<IBaseAction<IUser>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST });

        OrganizationUserApi.getUsersAsync(organizationID).then(data => {
            dispatch({ type: ActionType.RECEIVE_SUCCESS, items: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_FAIL, error: reason });
        });
    },

    requestUser: (organizationID: number, id: number): AppThunkAction<IBaseAction<IUser>> => (dispatch, getState) => {
        dispatch({ type: ActionType.REQUEST_DETAILS });

        OrganizationUserApi.getUserAsync(organizationID, id).then(data => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_SUCCESS, item: data });
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.RECEIVE_DETAILS_FAIL, error: reason });
        });
    },

    addUser: (organizationID: number, item: IUserCreateDTO, callback: (c: IUser) => void = () => { }): AppThunkAction<IBaseAction<IUser>> => (dispatch, getState) => {
        dispatch({ type: ActionType.ADD });

        OrganizationUserApi.createUserAsync(organizationID, item).then(data => {
            dispatch({ type: ActionType.ADD_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.ADD_FAIL, error: reason });
        });
    },

    editUser: (organizationID: number, user: IUser, item: IUserEditDTO, callback: (c: IUser) => void = () => { }): AppThunkAction<IBaseAction<IUser>> => (dispatch, getState) => {
        dispatch({ type: ActionType.EDIT });

        OrganizationUserApi.editUserAsync(organizationID, user.id, item).then(data => {
            dispatch({ type: ActionType.EDIT_SUCCESS, item: data });
            callback(data);
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.EDIT_FAIL, error: reason });
        });
    },

    deleteUser: (organizationID: number, user: IUser, callback: () => void = () => { }): AppThunkAction<IBaseAction<IUser>> => (dispatch, getState) => {
        dispatch({ type: ActionType.DELETE });

        OrganizationUserApi.deleteUserAsync(organizationID, user.id).then(data => {
            dispatch({ type: ActionType.DELETE_SUCCESS, item: user });
            callback();
        }).catch((reason: ApiError) => {
            dispatch({ type: ActionType.DELETE_FAIL, error: reason });
        });
    }
};