import { IDropdownOption } from '@fluentui/react';
import { boundMethod } from 'autobind-decorator';
import { DefaultButton, Dropdown, Panel, PanelType, PrimaryButton, Stack, StackItem, TextField } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { ErrorMessage, FieldValidation } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, IMaintainerCreateDTO, reducer } from '../../store/Maintainers';

type NewFieldProps = ReturnType<typeof reducer> & typeof actionCreators & {
    isOpen: boolean;
    onAdded: () => void;
    onDismissed: () => void;
    organizationID: number;
    caseID: number;
};

type NewFieldState = {
    newMaintainer: IMaintainerCreateDTO;
};

class NewMaintainer extends React.Component<NewFieldProps, NewFieldState> {

    constructor(props: NewFieldProps) {
        super(props);

        this.state = {
            newMaintainer: {
                access: 'ReadOnly',
                email: ''
            }
        };
    }

    public render(): React.ReactNode {
        return (
            <Panel isOpen={this.props.isOpen} onDismiss={this.props.onDismissed} type={PanelType.medium} headerText='New field' onRenderFooterContent={() => <StackItem>
                <PrimaryButton disabled={this.props.isAdding} onClick={this._add} text='Create' style={{ marginRight: '8px' }} />
                <DefaultButton onClick={this.props.onDismissed}>Cancel</DefaultButton>
            </StackItem>}>
                <Stack tokens={{ childrenGap: 20 }}>
                    <ErrorMessage error={this.props.addError} />

                    <StackItem>
                        <TextField label='Email' value={this.state.newMaintainer.email} onChange={(e, value) => this.setState({ newMaintainer: { ...this.state.newMaintainer, email: value || '' } })} />
                        <FieldValidation error={this.props.addError} fieldname='Email' />
                    </StackItem>
                    
                    <StackItem>
                        <Dropdown 
                            label='Access' 
                            selectedKey={this.state.newMaintainer.access} 
                            onChange={this._onAccessChange}
                            options={[
                                {key: 'ReadOnly', text: 'Read only'},
                                {key: 'ReadWrite', text: 'Read write'}
                            ]}/>
                        <FieldValidation error={this.props.addError} fieldname='Access' />
                    </StackItem>
                </Stack>
            </Panel>
        );
    }

    @boundMethod
    private _onAccessChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined): void {
        if(option){
            this.setState({ newMaintainer: { ...this.state.newMaintainer, access: option.key.toString() as 'ReadOnly' | 'ReadWrite' } })
        }
    }

    @boundMethod
    private _add(): void {
        this.props.addMaintainer(this.props.organizationID, this.props.caseID, this.state.newMaintainer, () => { this.props.onAdded(); });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.maintainers, actionCreators)(NewMaintainer);