import { boundMethod } from 'autobind-decorator';
import { CommandBar, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, Stack, StackItem } from 'office-ui-fabric-react';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { DataTable, ErrorMessage } from '../../components/controls';
import { ApplicationState } from '../../store';
import { actionCreators, IAngle, reducer } from '../../store/Angles';
import EditAngle from './EditAngle';
import NewAngle from './NewAngle';

type AnglesProps = ReturnType<typeof reducer> & typeof actionCreators & RouteComponentProps<{ organizationID: string; caseID: string, sectionID: string }>;

type AnglesState = {
    showCreatePanel: boolean;
    selectedAngle?: IAngle;
    deleteDialogVisible: boolean;
    editDialogVisible: boolean;
};

class Angles extends React.Component<AnglesProps, AnglesState> {

    constructor(props: AnglesProps) {
        super(props);

        this.state = {
            showCreatePanel: false,
            selectedAngle: undefined,
            deleteDialogVisible: false,
            editDialogVisible: false
        };
    }

    public componentDidMount(): void {
        this.props.requestAngles(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID), parseInt(this.props.match.params.sectionID));
    }

    public render(): React.ReactNode {
        return (
            <>
                <NewAngle organizationID={parseInt(this.props.match.params.organizationID)} caseID={parseInt(this.props.match.params.caseID)} sectionID={parseInt(this.props.match.params.sectionID)} isOpen={this.state.showCreatePanel} onDismissed={this._hideCreatePanel} onAdded={this._hideCreatePanel} />
                <EditAngle organizationID={parseInt(this.props.match.params.organizationID)} caseID={parseInt(this.props.match.params.caseID)} sectionID={parseInt(this.props.match.params.sectionID)} angle={this.state.selectedAngle} isOpen={this.state.editDialogVisible} onDismissed={this._hideEditPanel} onEdited={this._hideEditPanel} />

                <Stack tokens={{ childrenGap: 20 }}>
                    <StackItem>
                        <CommandBar
                            items={[
                                {
                                    key: 'Add',
                                    name: 'Create new angle',
                                    iconProps: {
                                        iconName: 'Add'
                                    },
                                    onClick: () => this._showCreatePanel()
                                },
                            ]}
                        />

                        
                    </StackItem>

                    <StackItem>
                        <ErrorMessage error={this.props.loadError} />

                        <DataTable<IAngle> paginated items={this.props.items} isLoading={this.props.isLoading} onEdit={this._showEditPanel} onDelete={this._promptForDelete} coloumns={[
                            {
                                key: 'name',
                                name: 'Name',
                                fieldName: 'name',
                                minWidth: 210,
                                maxWidth: 1000,
                                isResizable: true,
                                isSortable: true
                            }
                        ]} />
                    </StackItem>

                </Stack>

                <Dialog
                    hidden={!this.state.deleteDialogVisible}
                    onDismiss={this._hideDialog}
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: 'Delete angle',
                        subText: 'This will remove the angle permantly'
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <ErrorMessage error={this.props.deleteError} />
                    <DialogFooter>
                        <PrimaryButton onClick={this._delete} text='Delete' />
                        <DefaultButton onClick={this._hideDialog} text='Cancel' />
                    </DialogFooter>
                </Dialog>
            </>
        );
    }

    @boundMethod
    private _delete(): void {
        if(this.state.selectedAngle){
            this.props.deleteAngle(parseInt(this.props.match.params.organizationID), parseInt(this.props.match.params.caseID), parseInt(this.props.match.params.sectionID), this.state.selectedAngle, () => { this._hideDialog(); });
        }
    }

    @boundMethod
    private _promptForDelete(angle: IAngle) {
        this.setState({
            selectedAngle: angle,
            deleteDialogVisible: true
        });
    }

    @boundMethod
    private _hideDialog() {
        this.setState({
            deleteDialogVisible: false
        });
    }

    @boundMethod
    private _showCreatePanel() {
        this.setState({ showCreatePanel: true });
    }

    @boundMethod
    private _hideCreatePanel() {
        this.setState({
            showCreatePanel: false,
        });
    }

    @boundMethod
    private _showEditPanel(angle: IAngle) {
        this.setState({
            editDialogVisible: true,
            selectedAngle: angle
        });
    }

    @boundMethod
    private _hideEditPanel() {
        this.setState({
            editDialogVisible: false,
            selectedAngle: undefined
        });
    }
}

// Wire up the React component to the Redux store
export default connect((state: ApplicationState) => state.angles, actionCreators)(Angles);